import { Component, HostListener, OnInit } from "@angular/core";
import { UserService } from "src/app/common/utils/user/user.service";
import { NotifyService } from "../../services/notify.service";
import { SharedService } from "../../services/shared.service";
import { Feedback } from "../../shared.model";

@Component({
  selector: "feedback-modal",
  templateUrl: "./feedback-modal.component.html",
  styleUrls: ["./feedback-modal.component.scss"],
})
export class FeedbackModalComponent implements OnInit {
  constructor(
    public NOTIFY: NotifyService,
    private SHARED: SharedService,
    public USER: UserService
  ) {
    this.NOTIFY.feedBackModal.subscribe((res) => {
      this.modalStatus = res;
    });
    this.fillInitials();
  }

  ngOnInit(): void {}
  activeUser: any = null;
  disableFullname: boolean = false;
  fillInitials() {
    this.activeUser = this.USER.data;
    if (this.activeUser) {
      this.formData.fullname = `${this.activeUser?.firstname} ${this.activeUser?.lastname}`;
      this.formData.phone = this.activeUser.phone;
    }
    if (this.formData.fullname?.length > 6) {
      this.disableFullname = true;
    }
  }

  public modalStatus: boolean;

  @HostListener("document:keydown.escape", ["$event"])
  public onKeydownHandler(event: KeyboardEvent) {
    this.NOTIFY.hideFeedBackModal();
  }

  public formData: Feedback = {
    fullname: null,
    type: null,
    phone: null,
    feedback: null,
  };

  public types = [
    {
      label: "opt_suggestion",
      value: 2,
    },
    {
      label: "opt_complaint",
      value: 1,
    },
    {
      label: "opt_other",
      value: 0,
    },
  ];

  public onSelectChange(event: any) {
    this.formData.type = event.value;
  }

  public clearFormData() {
    this.formData.feedback = null;
    this.formData.fullname = null;
    this.formData.type = null;
  }

  public sendFeedback() {
    if (
      this.formData.fullname &&
      (this.formData.type == 0 || 1 || 2) &&
      this.formData.feedback &&
      this.formData.phone
    ) {
      this.NOTIFY.startSpinner();
      this.SHARED.sendFeedback(this.formData).subscribe(
        (RESPONSE) => {
          this.NOTIFY.success(RESPONSE);
          this.NOTIFY.hideFeedBackModal();
          this.NOTIFY.stopSpinner();
          this.clearFormData();
        },
        (err) => {
          this.NOTIFY.error(err);
          this.NOTIFY.stopSpinner();
        }
      );
    } else {
      this.NOTIFY.set({
        text: "Please fill all inputs",
        status: "warning",
        seconds: 3,
      });
    }
  }
}
