<app-profile-headline
  [title]="'Address information'"
  [description]="
    'Here are your delivery addresses. You can add a new delivery address and change existing ones.'
  "
  [page]="'locations-add'"
>
</app-profile-headline>
<div class="profile-data">
  <div class="pic-body">
    <!-- if clicked "open-map" button show map-->
    <div class="show-map">
      <agm-map
        [latitude]="selectedCoord.lat"
        [longitude]="selectedCoord.lng"
        [styles]="mapStyle"
        [streetViewControl]="false"
        [mapTypeControl]="true"
        (mapClick)="onChooseLocation($event)"
      >
        <agm-marker
          [latitude]="selectedCoord.lat"
          [longitude]="selectedCoord.lng"
          [animation]="'Drop'"
          [iconUrl]="'../../../../../../assets/icons/map_marker.svg'"
        ></agm-marker>
      </agm-map>
    </div>
    <!-- ./if clicked "open-map" button show map-->
    <div class="pic-body-grid">
      <div class="form-group">
        <div class="label-blk">
          <label class="body_s_med">{{ "Fill address*" | translate }}</label>
          <!-- <a class="open-map body_xs_med">xəritədən seç</a> -->
        </div>
        <input
          type="text"
          name="address"
          #placesRef="ngx-places"
          [value]="info.address"
          class="address"
          [placeholder]="'Address' | translate"
          ngx-google-places-autocomplete
          [options]="google_autocomplete_options"
          (keyup.enter)="onAddressEnter($event)"
          (blur)="onAddressBlur($event)"
        />
        <!-- <div class="custom_datalist">
          <div
            (value)="(address)"
            class="body_s_reg datalist_opt"
            *ngFor="let address of address_suggestions"
          >
            {{ address }}
          </div>
        </div> -->

        <input type="hidden" [(ngModel)]="info.latitude" name="latitude" />
        <input type="hidden" [(ngModel)]="info.longitude" name="longitude" />
      </div>
      <div class="form-group">
        <label for="" class="body_s_med">{{
          "More detailed (Building, block, floor, house)*" | translate
        }}</label>
        <input
          type="text"
          [(ngModel)]="info.additional"
          name="additional"
          [placeholder]="'Building ... Apartment ... Floor' | translate"
        />
      </div>
      <div class="form-group">
        <div class="label-blk">
          <label for="" class="body_s_med">{{
            "Additional note" | translate
          }}</label>
        </div>
        <input
          type="text"
          [(ngModel)]="info.note"
          name="note"
          [placeholder]="'next to Bravo market' | translate"
        />
      </div>

      <div class="form-group">
        <label for="" class="body_s_med">{{
          "address_title" | translate
        }}</label>
        <input
          type="text"
          [(ngModel)]="info.name"
          name="name"
          [placeholder]="'work address' | translate"
        />
      </div>
    </div>
    <div class="errors-box">
      <ul>
        <li *ngFor="let error of errors">{{ error[0] }}</li>
      </ul>
    </div>
  </div>
</div>

<div class="submit-block">
  <!-- <button class="btn btn-border btn-med cancel-btn" (click)="removeAddress(id)">
    Ləğv etmək
  </button> -->
  <button class="btn btn-fill hover btn-med" (click)="createLocation()">
    {{ "Save" | translate }}
  </button>
</div>
