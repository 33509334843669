import { Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-categories-slider",
  templateUrl: "./categories-slider.component.html",
  styleUrls: ["./categories-slider.component.scss"],
})
export class CategoriesSliderComponent implements OnInit {
  public customOptions: OwlOptions = {};

  @Input() public slides: [];

  constructor(private ROUTER: Router) {
    this.detectDevice();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.customOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      navText: ["", ""],
      nav: false,
      margin: 16,
      items: 1,
    };
  }

  public hide_slider: boolean = true;

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
