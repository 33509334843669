import { ActivatedRoute, Router } from "@angular/router";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "list-tag",
  templateUrl: "./list-tag.component.html",
  styleUrls: ["./list-tag.component.scss"],
})
export class ListTagComponent implements OnInit {
  constructor(
    private router: Router,
    private ACTIVATED_ROUTE: ActivatedRoute
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  @Input("tag") tag: {
    label: "string";
    value: "string";
    code: "string";
  };

  public removeTag() {
    const url = decodeURI(this.router.url);

    if (url.indexOf(`${this.tag.code}=${this.tag.value}`) > -1) {
      const sliceVal = `${this.tag.code}=${this.tag.value}`;

      const removed = url.replace(sliceVal, "").toString();

      this.router.navigateByUrl(removed);
    }
  }
}
