import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { BlogsService } from "./../../blogs.service";
import { Component, OnInit } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { NotifyService } from "src/app/modules/shared/services/notify.service";

@Component({
  selector: "app-blog-list",
  templateUrl: "./blog-list.component.html",
  styleUrls: ["./blog-list.component.scss"],
})
export class BlogListComponent implements OnInit {
  constructor(
    private ROUTER: Router,
    private ACTIVATED_ROUTE: ActivatedRoute,
    private BLOGS: BlogsService,
    private NOTIFY: NotifyService
  ) {
    this.subscription = ROUTER.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.fetchBlogs();
      }
    });
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public blogsList = [];
  public links: any = null;
  public pageId: any = 1;

  public fetchBlogs() {
    this.NOTIFY.startSpinner();
    this.pageId = this.ACTIVATED_ROUTE.snapshot.queryParamMap.get("page");
    this.pageId = this.pageId ? this.pageId : 1;
    this.BLOGS.getBlogsList(this.pageId).subscribe(
      (res) => {
        this.blogsList = res.body.data;
        this.links = res.body.meta.links;
        this.NOTIFY.stopSpinner();
      },
      (err) => {
        this.NOTIFY.error(err);
        this.NOTIFY.stopSpinner();
      }
    );
  }
}
