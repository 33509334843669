<div class="image_carousel_container" *ngIf="items.length">
  <owl-carousel-o [options]="customOptions" #carousel>
    <ng-template carouselSlide *ngFor="let image of items; let i = index">
      <div class="carousel_item">
        <lib-ngx-image-zoom
          [thumbImage]=image
          [fullImage]=image
        ></lib-ngx-image-zoom>
        <!-- <img
          [src]="image"
          (load)="
            $event.target.clientHeight > $event.target.clientWidth
              ? log($event.target)
              : null
          "
        /> -->
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
