<app-profile-headline
  [title]="'Security'"
  [description]="
    'You can check the list of devices that come with your profile to ensure security and prevent outside intrusion into your profile.'
  "
  [page]="'security-details'"
>
</app-profile-headline>

<section class="security_details">
  <div class="current_security" *ngIf="session">
    <div class="current_security_head">
      <div class="device_block icon_block fcc">
        <i class="fal fa-desktop" *ngIf="session.isDesktop"></i>
        <i
          class="fal fa-mobile"
          *ngIf="session.isPhone || session.isMobile"
        ></i>

        <i class="fal fa-tablet" *ngIf="session.isTablet"></i>
      </div>
      <div class="session_content">
        <strong class="body_m_med">{{
          session.platform !== null ? session.platform : session.browser
        }}</strong>
        <p class="body_xs_med">
          {{ session.location !== "" ? session.location : session.platform }}
          <!-- {{
            session.last_activity.replace(" ", "T") | date: "dd LLLL yyyy HH:mm"
          }} -->
          {{ session.date }}
        </p>
      </div>
    </div>
    <div class="current_security_content">
      <div class="current_security_content_content_left">
        <span class="ul_heading overline">{{
          "Last activity dates" | translate
        }}</span>
        <ul>
          <li>
            <p class="body_s_reg">
              <b>{{
                session.location !== "" ? session.location : "Unknown location"
              }}</b>
              <!-- {{ daysPassed(session.date) }} -->
              <!-- {{ "days_ago" | translate }} -->
              {{ session.activities[0].readable }}
            </p>
            <span class="ip body_xs_reg">{{ session.ip }}</span>
          </li>
        </ul>
      </div>
      <div class="current_security_content_content_right">
        <span class="ul_heading overline">{{ "Browsers" | translate }}</span>
        <ul>
          <li>
            <p class="body_s_reg">
              <b>{{
                session.browser !== "" ? session.browser : "Unknown browser"
              }}</b>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
