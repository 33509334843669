import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-profile-headline",
  templateUrl: "./profile-headline.component.html",
  styleUrls: ["./profile-headline.component.scss"],
})
export class ProfileHeadlineComponent implements OnInit {
  @Input() public page: string;
  @Input() public title: string;
  @Input() public order_id: string;
  @Input() public products_count: string;
  @Input() public description: string;

  constructor() {
    this.detectDevice();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
