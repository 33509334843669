<app-profile-headline
  [title]="'Address information'"
  [description]="
    'Here are your delivery addresses. You can add a new delivery address and change existing ones.'
  "
  [page]="'locations'"
>
</app-profile-headline>
<div class="profile-data">
  <ng-container *ngFor="let address of addresses; let i = index">
    <div
      class="location-check"
      (clickOutside)="onClickedOutside(i)"
      [clickOutsideEnabled]="true"
      [ngClass]="{ default: address.default }"
    >
      <div class="check-title">
        <div class="icon"><i class="fal fa-map-marker-alt"></i></div>
        <div class="text">
          <h3 class="body_m_med">{{ address.name }}</h3>
          <p class="body_xs_med">{{ address.address }}</p>
        </div>
      </div>
      <button class="dropdown-menu" (click)="showDropdown(i)">
        <i class="far fa-ellipsis-h"></i>
      </button>
      <div class="dropdown-list" [ngClass]="{ active: address.status }">
        <ul>
          <li *ngIf="!address.default">
            <button class="body_s_reg" (click)="setDefAdd(address.id)">
              {{ "Set default" | translate }}
            </button>
          </li>
          <li>
            <a
              href=""
              class="body_s_reg"
              [routerLink]="['/profile/locations/' + address.id]"
              >{{ "Edit" | translate }}</a
            >
          </li>
          <li>
            <button
              href=""
              class="body_s_reg"
              (click)="removeAddress(address.id)"
            >
              {{ "Remove" | translate }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <div class="location-check">
    <div class="check-title">
      <a [routerLink]="['/profile/locations/add']" class="add-to body_s_med"
        ><i class="far fa-plus plus"></i>{{ "Add new address" | translate }}</a
      >
    </div>
  </div>
</div>
