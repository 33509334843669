import { Location } from "@angular/common";
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "list-filter",
  templateUrl: "./list-filter.component.html",
  styleUrls: ["./list-filter.component.scss"],
})
export class ListFilterComponent implements OnInit {
  constructor(
    private ACTIVATED_ROUTE: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // this.getUrlParams();
        // this.checkRouterChanges();
      }
    });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (this.filter) {
      this.state.filter_title = this.filter.label;
      this.state.label = this.filter.label.split(" ").join("_");
      this.state.attribute_code = this.filter.attribute_code;
      this.state.options = this.filter.options;
      this.showOptions();
      this.selectedValues[`${this.state.attribute_code}[]`] = [];
      this.checkRouterChanges();
    }
  }

  @Input("filter") private filter: FilterModel;

  public state = {
    input_value: "",
    filter_title: null,
    label: null,
    attribute_code: null,
    options: [],
  };

  // Show all Filter options ===============================
  public showOptions() {
    this.state.options.map((opt: {}) => {
      opt["visibility"] = true;
    });
  }
  // Show all Filter options ===============================

  // Filter option on Input change =========================
  public filterOptions(text: string) {
    if (text && text.length > 0) {
      this.state.options.map((opt: {}) => {
        if (opt["label"].toLowerCase().indexOf(text) > -1) {
          opt["visibility"] = true;
        } else {
          opt["visibility"] = false;
        }
      });
    } else {
      this.showOptions();
    }
  }
  // Filter option on Input change =========================

  // Emit value on Checkbox change =========================
  public selectedValues = {};
  public checkBoxEvent(event, value) {
    const selected = this.selectedValues[`${this.state.attribute_code}[]`];
    if (event == true) {
      selected.push(value);
    } else {
      selected.forEach((item, i) => {
        if (item == value) {
          selected.splice(i, 1);
        }
      });
    }

    this.router.navigate([], {
      relativeTo: this.ACTIVATED_ROUTE,
      queryParamsHandling: "merge",
      queryParams: this.selectedValues,
      replaceUrl: true,
    });
  }
  // Emit value on Checkbox change =========================

  public checkRouterChanges() {
    if (
      this.ACTIVATED_ROUTE.snapshot.queryParams[
        `${this.state.attribute_code}[]`
      ]
    ) {
      const val =
        this.ACTIVATED_ROUTE.snapshot.queryParams[
          `${this.state.attribute_code}[]`
        ];
      const selected = this.selectedValues[`${this.state.attribute_code}[]`];

      if (Array.isArray(val)) {
        val.forEach((item) => {
          this.state.options.forEach((opt) => {
            if (item.toLowerCase() == opt.value) {
              selected.push(opt.value);
              opt["check"] = true;
            }
          });
        });
      } else {
        this.state.options.map((opt) => {
          if (val == opt.value) {
            opt["check"] = true;
            selected.push(opt.value);
          }
        });
      }
    }
  }
}

interface FilterModel {
  label: string;
  attribute_code: any;
  options: [];
}
