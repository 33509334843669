import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { MallService } from "../../mall.service";
import { Title } from "@angular/platform-browser";
import { generateTitle } from "src/app/common/utils/functions/generateTitle.function";

@Component({
  selector: "app-brands",
  templateUrl: "./brands.component.html",
  styleUrls: ["./brands.component.scss"],
})
export class BrandsComponent implements OnInit {
  public state = {
    stateStatus: false,
  };
  public brands: [];

  constructor(
    private mall: MallService,
    private router: Router,
    private ACTIVATED_ROUTE: ActivatedRoute,
    private Title: Title
  ) {
    this.detectDevice();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.Title.setTitle(generateTitle("Brendlər"));
    this.mall.getBrandsList().subscribe((res) => {
      this.state.stateStatus = true;
      this.brands = res.body;
      this.filterBrands();
      this.fillInputFromUrl();
    });
  }

  public inputValue: string = "";
  public fillInputFromUrl() {
    if (this.ACTIVATED_ROUTE.snapshot.queryParams.ft_brand) {
      this.inputValue = this.ACTIVATED_ROUTE.snapshot.queryParams.ft_brand;
    }
    this.onBrandInputChange(this.inputValue);
  }

  public filterBrands() {
    this.brands.forEach((el: {}) => {
      el["visibility"] = true;
    });
  }

  public onBrandInputChange(event) {
    this.router.navigate([], {
      relativeTo: this.ACTIVATED_ROUTE,
      queryParamsHandling: "merge",
      queryParams: {
        ft_brand: event,
      },
      replaceUrl: true,
    });
    if (event.length > 0) {
      this.brands.filter((el: any) => {
        if (el?.name) {
          if (el?.name.toLowerCase().indexOf(event) > -1) {
            el["visibility"] = true;
          } else {
            el["visibility"] = false;
          }
        } else {
          el["visibility"] = false;
        }
      });
    } else {
      this.filterBrands();
    }
  }

  public mobileBrandsList: boolean = false;
  public toggleBrandsList() {
    this.mobileBrandsList = true;
  }

  public closeMobileBrands() {
    this.mobileBrandsList = false;
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
