import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ProfileService } from "../../../../../common/utils/user/profile.service";
import { Subscription } from "rxjs";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { SharedService } from "src/app/modules/shared/services/shared.service";

@Component({
  selector: "app-profile-orders",
  templateUrl: "./profile-orders.component.html",
  styleUrls: ["./profile-orders.component.scss"],
})
export class ProfileOrdersComponent implements OnInit {
  public orders: [];

  constructor(
    private profile: ProfileService,
    private ActivatedRoute: ActivatedRoute,
    private router: Router,
    private NOTIFY: NotifyService,
    private SHARED: SharedService
  ) {
    this.detectDevice();

    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.fetchOrders();
      }
    });
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public links: any = null;
  public pageId: any = null;
  public transaction_id: any = null;
  public fetchOrders() {
    this.NOTIFY.startSpinner();
    this.pageId = this.ActivatedRoute.snapshot.queryParamMap.get("page");
    this.transaction_id =
      this.ActivatedRoute.snapshot.queryParamMap.get("order_id");
    this.profile.orders(this.pageId).subscribe((res) => {
      this.orders = res.body.data;
      this.links = res.body.meta.links;
      if (this.transaction_id) {
        this.detectPayment();
      } else {
        this.NOTIFY.stopSpinner();
      }
    });
  }

  public paymentInfo = {
    modal: false,
    status: false,
    text: null,
    order_id: null,
  };

  public detectPayment() {
    this.profile.checkTransaction(this.transaction_id).subscribe(
      (res) => {
        this.paymentInfo.status = res.body.is_success;
        this.paymentInfo.text = res.message;
        this.paymentInfo.modal = true;
        this.NOTIFY.stopSpinner();
      },
      (err) => {
        this.NOTIFY.stopSpinner();
        this.NOTIFY.error(err);
        // this.paymentInfo.modal = true;
        // this.paymentInfo.status = true;
        // this.paymentInfo.text = "Here we goo";
      }
    );
  }

  public closeModal() {
    this.paymentInfo.modal = false;
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
