import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "hotline-call",
  templateUrl: "./hotline-call.component.html",
  styleUrls: ["./hotline-call.component.scss"],
})
export class HotlineCallComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  @Input() public phone: string;
}
