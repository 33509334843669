<header class="container">
  <div class="header_top">
    <div class="header_top__left">
      <div class="each_cell">
        <language-selector></language-selector>
      </div>
      <div class="each_cell">
        <geolocation></geolocation>
      </div>
    </div>
    <div class="header_top__right">
      <div class="each_cell">
        <hotline-call [phone]="state.phone"></hotline-call>
      </div>
      <!-- <div class="each_cell">
        <a
          class="simple_router_link body_xs_reg"
          [routerLink]="['/innovations']"
          >{{ "innovations" | translate }}
        </a>
      </div> -->
      <!-- <div class="each_cell">
        <a class="simple_router_link body_xs_reg" [routerLink]="['/faq']"
          >{{ "faq" | translate }}
        </a>
      </div> -->
      <div class="each_cell">
        <a
          class="simple_router_link body_xs_reg"
          [routerLink]="['static/delivery']"
          >{{ "Delivery" | translate }}
        </a>
      </div>
      <div class="each_cell">
        <a class="simple_router_link body_xs_reg" [routerLink]="['/brands']"
          >{{ "Brands" | translate }}
        </a>
      </div>
      <div class="each_cell">
        <a class="simple_router_link body_xs_reg" [routerLink]="['/stores']"
          >{{ "Stores" | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="header_bottom">
    <div class="header_logo">
      <a [routerLink]="['']">
        <img src="../../../../../assets/icons/app_logo.svg" alt="" />
      </a>
      <span class="beta">BETA</span>
    </div>

    <div class="header_category">
      <category-nav [categories_nav]="state.categories_nav"></category-nav>
    </div>

    <div class="search_container">
      <search-block [categories_nav]="state.categories_nav"></search-block>
    </div>

    <div class="iconed_blocks_container">
      <div class="each_iconed_block">
        <signin-block></signin-block>
      </div>
      <div class="each_iconed_block">
        <a [routerLink]="['/favorites']">
          <i class="fal fa-heart"></i>
          <span class="body_xs_med">{{ "Favorites" | translate }}</span>
          <div class="notification_block" *ngIf="state.favorites_count">
            <span class="caption">{{ state.favorites_count }}</span>
          </div>
        </a>
      </div>
      <div class="each_iconed_block">
        <a [routerLink]="['/basket']">
          <i class="fal fa-shopping-cart"></i>
          <span class="body_xs_med">{{ "cart" | translate }}</span>
          <div class="notification_block" *ngIf="state.cart_item_count">
            <span class="caption">{{ state.cart_item_count }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</header>

<section class="mobile_header">
  <div class="mobile_header_top container">
    <div class="mobile_header_top_left">
      <div class="categories_toggler fcc" (click)="toggleMobileCategory()">
        <i class="fal fa-bars" *ngIf="!mobileHeader.category_toggler"></i>
        <i class="fal fa-times" *ngIf="mobileHeader.category_toggler"></i>
      </div>
      <div class="mobile_header_logo">
        <a [routerLink]="['']">
          <img src="../../../../../assets/icons/app_logo.svg" alt="" />
        </a>
        <span class="beta">BETA</span>
      </div>
    </div>
    <div class="iconed_blocks_container">
      <div class="each_iconed_block">
        <signin-block></signin-block>
      </div>
      <div class="each_iconed_block">
        <a [routerLink]="['/favorites']">
          <i class="fal fa-heart"></i>
          <div class="notification_block" *ngIf="state.favorites_count">
            <span class="caption">{{ state.favorites_count }}</span>
          </div>
        </a>
      </div>
      <div class="each_iconed_block">
        <a [routerLink]="['/basket']">
          <i class="fal fa-shopping-cart"></i>
          <div class="notification_block" *ngIf="state.cart_item_count">
            <span class="caption">{{ state.cart_item_count }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div
    class="mobile_header_bottom container"
    *ngIf="!mobileHeader.category_toggler"
  >
    <search-block
      [mobile]="true"
      [categories_nav]="state.categories_nav"
    ></search-block>
  </div>
  <div class="sideNav container" *ngIf="mobileHeader.category_toggler">
    <div class="sideNav_swapper">
      <div class="categories_list" *ngIf="mobileSideNav.current_position == 0">
        <div
          class="mobile_category_link"
          *ngFor="let category of state.categories_nav; let i = index"
          (click)="showSelectedCategory(i)"
        >
          <p class="body_s_med">
            {{ category.name ? category.name : "Empty_name" }}
          </p>
          <div class="icon_block fcc">
            <i class="fal fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div
        class="categories_list top_padding"
        *ngIf="mobileSideNav.current_position == 1"
      >
        <div class="go_back_link" (click)="getNavigationBack(0)">
          <div class="icon_block fcc">
            <i class="fal fa-chevron-left"></i>
          </div>
          <span class="body_s_med">{{
            mobileSideNav.selected_category.name
          }}</span>
        </div>
        <div
          class="mobile_category_link"
          *ngFor="
            let sub_category of mobileSideNav.selected_category.children;
            let i = index
          "
          (click)="showSelectedCategory(i)"
        >
          <p class="body_s_med">
            {{ sub_category.name ? sub_category.name : "Empty_name" }}
          </p>
          <div class="icon_block fcc">
            <i class="fal fa-chevron-right"></i>
          </div>
        </div>
        <div
          class="mobile_category_link brand_color"
          (click)="navigateToCategories()"
        >
          <p class="body_s_med">
            {{ "See all" | translate }}
          </p>
          <div class="icon_block fcc">
            <i class="fal fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div
        class="categories_list top_padding"
        *ngIf="mobileSideNav.current_position == 2"
      >
        <div class="go_back_link" (click)="getNavigationBack(1)">
          <div class="icon_block fcc">
            <i class="fal fa-chevron-left"></i>
          </div>
          <span class="body_s_med">{{
            mobileSideNav.selected_sub_category.name
          }}</span>
        </div>
        <div
          class="mobile_category_link"
          *ngFor="
            let sub_category of mobileSideNav.selected_sub_category.children;
            let i = index
          "
          (click)="toCategoryList(sub_category)"
        >
          <p class="body_s_med">
            {{ sub_category.name ? sub_category.name : "Empty_name" }}
          </p>
          <div class="icon_block fcc">
            <i class="fal fa-chevron-right"></i>
          </div>
        </div>
        <div
          class="mobile_category_link brand_color"
          (click)="navigateToSubCategories()"
        >
          <p class="body_s_med">
            {{ "See all" | translate }}
          </p>
          <div class="icon_block fcc">
            <i class="fal fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
