<div class="container" *ngIf="items">
  <page-heading
    [heading]="'All products'"
    [subtitle]="items.length"
  ></page-heading>

  <div class="product_grid_container">
    <product-grid [products]="items" [hidden_list]="true"> </product-grid>
  </div>
</div>
