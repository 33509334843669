import { LanguageService } from "src/app/common/utils/language/services/language.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { MallService } from "./../../mall.service";
import { Title } from "@angular/platform-browser";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Component, OnInit } from "@angular/core";
import { SharedService } from "src/app/modules/shared/services/shared.service";

@Component({
  selector: "about-page",
  templateUrl: "./about-page.component.html",
  styleUrls: ["./about-page.component.scss"],
})
export class AboutPageComponent implements OnInit {
  constructor(
    private MALL: MallService,
    private web_title: Title,
    private SHARED: SharedService,
    private spinner: NgxUiLoaderService,
    private ACTIVATED_ROUTE: ActivatedRoute,
    private LANG: LanguageService
  ) {
    this.res = this.SHARED.staticKeys;
    this.subscription = this.SHARED.staticData.subscribe((res) => {
      if (res) {
        this.res = res;
        this.fillData();
      }
    });
    this.getIndexPageCarousels();
    this.web_title.setTitle("Caspimall : Caspi Mall Haqqında");
    this.detectDevice();
  }

  setDefaultLangFormMobileView() {
    let urlLang = this.ACTIVATED_ROUTE.snapshot.queryParamMap.get("lang");
    if (urlLang) {
      this.LANG.switchLang(urlLang);
      this.SHARED.fetchStatics();
      this.fillData();
    }
    if (
      this.ACTIVATED_ROUTE.snapshot.queryParamMap.get("in_mobile") == "true"
    ) {
      this.in_mobile = true;
    }
  }

  in_mobile: boolean = false;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.fillData();
    this.setDefaultLangFormMobileView();
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private res: any = null;
  title_up: string = null;
  title: string = null;
  text: string = null;
  crm_title: string = null;
  description: string = null;

  fillData() {
    this.title_up = this.res?.page_about_title_up;
    this.title = this.res?.page_about_title;
    this.text = this.res?.page_about_text;
    this.crm_title = this.res?.page_caspimall_crm_title;
    this.description = this.res?.page_caspimall_description;

    this.crm_cards.map((card, i) => {
      card.text = this.res?.[`page_caspimall_crm_block_${i + 1}_title`];
    });

    this.lg_cards.map((card, i) => {
      card.strong = this.res?.[`page_caspimall_block_${i + 1}_title`];
      card.small = this.res?.[`page_caspimall_block_${i + 1}_description`];
    });
  }

  public lg_cards = [
    {
      strong: "lg_about_1_strong",
      small: "lg_about_1_small",
      icon: "fal fa-th",
    },
    {
      strong: "lg_about_2_strong",
      small: "lg_about_2_small",
      icon: "fal fa-sack-dollar",
    },
    {
      strong: "lg_about_3_strong",
      small: "lg_about_3_small",
      icon: "fal fa-mouse-alt",
    },
    {
      strong: "lg_about_4_strong",
      small: "lg_about_4_small",
      icon: "fal fa-biking",
    },
  ];

  public crm_cards = [
    {
      icon: "fal fa-mobile",
      text: "Vebsayt və Mobil tətbiq",
    },

    {
      icon: "fal fa-language",
      text: "Birdən çox dil",
    },
    {
      icon: "fal fa-columns",
      text: "CRM sitemi",
    },
    {
      icon: "fal fa-chart-line",
      text: "Hesabatlar",
    },
    {
      icon: "fal fa-boxes-alt",
      text: "Anbar xidməti",
    },
    {
      icon: "fal fa-copyright",
      text: "Marka zonası",
    },
    {
      icon: "fal fa-camera",
      text: "Fotostudio",
    },
    {
      icon: "fal fa-sack-dollar",
      text: "Loyallıq proqramı (Keşbek)",
    },
    {
      icon: "fal fa-bell",
      text: "Bildirişlər",
    },
    {
      icon: "fal fa-biking",
      text: "Çatdırılma",
    },
    {
      icon: "fal fa-headset",
      text: "7/24 Yardım Mərkəzi",
    },
    {
      icon: "fal fa-shield-check",
      text: "Təhlükəsizlik",
    },
  ];

  public localState = {
    // features_items: {
    //   items: [],

    // },
    brands_items: {
      items: [],
      show_all_url: "",
      max_item_count: 8,
    },
    favorite_items: {
      items: [],
      heading: "Populyar məhsullar",
      show_all_url: {
        order_by: "view",
        order_dir: "desc",
      },
      max_item_count: 6,
    },
    category_banners: {
      items: [],
    },
  };

  getIndexPageCarousels() {
    this.MALL.getBrands().subscribe((RESPONSE) => {
      this.localState.brands_items.items = RESPONSE.body;
    });

    // banners
    if (localStorage.getItem("ind_cat_banners")) {
      this.localState.category_banners.items = JSON.parse(
        localStorage.getItem("ind_cat_banners")
      );
    }

    for (let i = 1; i <= 2; i++) {
      this.localState.category_banners.items.push({
        url: this.res?.[`main_page_bottom_banner_${i}_url`],
        image: this.res?.[`main_page_bottom_banner_${i}_image`],
      });
    }

    // favorites
    if (localStorage.getItem("ind_favorite")) {
      this.localState.favorite_items.items = JSON.parse(
        localStorage.getItem("ind_favorite")
      );
    }
    this.MALL.getProductsList({
      per_page: 20,
      order_by: "view",
      order_dir: "desc",
    }).subscribe((RESPONSE) => {
      this.localState.favorite_items.items = RESPONSE.body.data;
      localStorage.setItem("ind_favorite", JSON.stringify(RESPONSE.body.data));
    });
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
