import { Router } from "@angular/router";
import { Component, HostListener, OnInit } from "@angular/core";
import { NotifyService } from "../../services/notify.service";
import { SharedService } from "../../services/shared.service";
import { reqComment } from "../../shared.model";

@Component({
  selector: "comment-modal",
  templateUrl: "./comment-modal.component.html",
  styleUrls: ["./comment-modal.component.scss"],
})
export class CommentModalComponent implements OnInit {
  constructor(
    public NOTIFY: NotifyService,
    private SHARED: SharedService,
    private ROUTER: Router
  ) {
    this.NOTIFY.commentModal.subscribe((res) => {
      this.modalStatus = res;
      if (!this.modalStatus) {
        this.clearFormData();
      }
    });
    this.NOTIFY.commentProduct.subscribe((res) => {
      if (res) {
        this.product = res;
        this.fillProductState();
      }
    });
  }

  ngOnInit(): void {}

  public modalStatus: boolean;

  @HostListener("document:keydown.escape", ["$event"])
  public onKeydownHandler(event: KeyboardEvent) {
    this.NOTIFY.hideCommentModal();
  }

  public productState = {
    image: null,
    name: null,
    current_variation: null,
  };

  public formData: reqComment = {
    comment: null,
    stars: null,
  };

  public product: any = null;
  public productId: number = null;
  public fillProductState() {
    this.productState.name = this.product?.name;
    this.productState.current_variation = this.product?.current_variation;
    if (this.product?.image) {
      this.productState.image = this.product?.image;
    }
    if (this.product?.images) {
      this.productState.image = this.product?.images.carousel[0];
    }

    if (this.product?.order_product_id) {
      this.productId = this.product.order_product_id;
    } else {
      this.productId = this.product.id;
    }
  }

  public onSelectChange(count: any) {
    this.formData.stars = count;
  }

  public clearFormData() {
    this.formData.comment = null;
    this.formData.stars = null;
    this.productState.name = null;
    this.productState.image = null;
    this.productState.current_variation = null;
    this.productId = null;
    this.NOTIFY.commentProduct.emit(null);
  }

  public reloadCurrentRoute() {
    const currentUrl = this.ROUTER.url;
    this.ROUTER.navigateByUrl("/", { skipLocationChange: true });
  }

  public sendFeedback() {
    if (this.formData.comment && this.formData.stars) {
      this.NOTIFY.startSpinner();
      this.SHARED.sendComment(this.productId, this.formData).subscribe(
        (RESPONSE) => {
          this.NOTIFY.success(RESPONSE);
          this.NOTIFY.hideCommentModal();
          this.NOTIFY.stopSpinner();
          this.clearFormData();
          this.reloadCurrentRoute();
        },
        (err) => {
          this.NOTIFY.error(err);
          this.NOTIFY.stopSpinner();
        }
      );
    } else {
      this.NOTIFY.set({
        text: "Please fill all inputs",
        status: "warning",
        seconds: 3,
      });
    }
  }
}
