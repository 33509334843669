<div class="pop-up-block" *ngIf="isOpen">
  <div class="pop-up-box">
    <div class="pop-up-top">
      <h3 class="body_m_demi">Şərh yaz</h3>
      <span class="close-btn" (click)="closePopup()"
        ><i class="fal fa-times"></i
      ></span>
    </div>
    <div class="popup-bottom">
      <div class="popup-box">
        <img src="/assets/images/phone.png" alt="" />
        <div class="text">
          <h3 class="body_s_med">Apple iPhone 11 Purple</h3>
          <p class="body_xs_reg">Seçilmiş rəng: <b>purple</b></p>
          <p class="body_xs_reg">Yaddaş həcmi: <b>128 GB</b></p>
        </div>
      </div>
      <div class="evaluation">
        <h3 class="body_s_med">Dəyərləndirməniz *</h3>
        <div class="starts">
          <div><i class="fal fa-star"></i></div>
          <div><i class="fal fa-star"></i></div>
          <div><i class="fal fa-star"></i></div>
          <div><i class="fal fa-star"></i></div>
          <div><i class="fal fa-star"></i></div>
        </div>
      </div>

      <h3 class="form-title body_s_med">Sizin şərhiniz*</h3>
      <form action="">
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Start typing..."
        ></textarea>
        <button type="submit" class="btn btn-fill body_m_med body_s_reg">
          Şərh et
        </button>
      </form>
    </div>
  </div>
</div>
