<section class="about_top container">
  <div class="about_top_left">
    <small class="overline" [innerHTML]="title_up">{{
      "About Caspimall" | translate
    }}</small>
    <h2 class="h3_demi" [innerHTML]="title">{{ "About Title" | translate }}</h2>
    <p class="body_m_reg" [innerHTML]="text"></p>
  </div>
  <div class="about_top_right">
    <div
      class="category_card card_1"
      *ngIf="localState.category_banners.items?.length > 0"
    >
      <index-category-banner
        class="each_category_card"
        [item]="localState.category_banners.items[0]"
        [scaled]="true"
      >
      </index-category-banner>
    </div>
    <div
      class="category_card card_2"
      *ngIf="localState.category_banners.items?.length > 0"
    >
      <index-category-banner
        class="each_category_card"
        [item]="localState.category_banners.items[1]"
        [scaled]="true"
      >
      </index-category-banner>
    </div>
    <div class="card_3" *ngIf="localState?.favorite_items?.items[0]">
      <product-card
        [item]="localState?.favorite_items?.items[0]"
        [static]="true"
      ></product-card>
    </div>
  </div>
</section>

<section class="about_middle container">
  <div class="about_lg_cards_grid">
    <div class="about_lg_card" *ngFor="let card of lg_cards">
      <div class="about_lg_card_icon fcc">
        <i class="{{ card.icon }}"></i>
      </div>
      <div class="about_lg_card_text">
        <strong
          class="body_l_med"
          [ngClass]="{ body_m_med: deviceType == 'mobile' }"
          >{{ card.strong | translate }}</strong
        >
        <small class="body_s_reg">{{ card.small | translate }}</small>
      </div>
    </div>
  </div>

  <div class="about_info">
    <div class="about_info_logo">
      <img src="../../../../assets/icons/app_logo.svg" alt="" />
    </div>
    <div class="about_info_content">
      <h3 class="h3_demi">Caspi Mall</h3>
      <p class="body_s_reg" [innerHTML]="description"></p>
    </div>
  </div>
</section>

<section class="about_bottom">
  <div class="crm_block container">
    <h6 class="h6_demi" [innerHTML]="crm_title"></h6>

    <div class="crm_grid">
      <div class="crm_card" *ngFor="let item of crm_cards">
        <div class="crm_card_icon fcc">
          <i class="{{ item.icon }}"></i>
        </div>
        <div class="crm_card_text">
          <strong
            class="body_l_med"
            [ngClass]="{ body_s_med: deviceType == 'mobile' }"
            >{{ item.text }}</strong
          >
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="features_container container">
    <features-cards [items]="localState.features_items.items"></features-cards>
  </div> -->

  <div
    class="brands_container container"
    *ngIf="localState.brands_items.items?.length > 0 && !in_mobile"
  >
    <brands-carousel
      [items]="localState.brands_items.items"
      [max_item_count]="localState.brands_items.max_item_count"
    >
    </brands-carousel>
  </div>
</section>
