<div class="store_join_us">
  <div class="join_us_banner">
    <div class="join_us_banner_cntnr container">
      <div class="join_us_slogan">
        <h4 class="overline" [innerHTML]="title_up"></h4>
        <h2
          class="h2_demi"
          [ngClass]="{
            h5_demi: deviceType == 'mobile'
          }"
          [innerHTML]="title"
        ></h2>
        <p
          class="body_m_med"
          [ngClass]="{
            body_s_med: deviceType == 'mobile'
          }"
          [innerHTML]="text"
        ></p>
        <div class="btn-group">
          <a
            target="_blank"
            href="https://store.caspimall.com/auth/signup"
            class="btn btn-fill"
            >{{ "signup" | translate }}</a
          >
          <a
            target="_blank"
            href="https://store.caspimall.com"
            class="btn btn-bordered"
            >{{ "login" | translate }}</a
          >
        </div>
      </div>
      <div class="join_us_img">
        <div class="js_tag js_tag_left">
          <div>
            <p class="t_upper">{{ "Product count" | translate }}</p>
            <strong class="body_m_demi">{{
              counterState?.product ? counterState.product : ""
            }}</strong>
          </div>
        </div>
        <div class="js_tag js_tag_right">
          <div class="js_tag_img">
            <img src="assets/images/branditem3.svg" alt="" />
          </div>
          <div>
            <p>CASPIMALL.COM/LEVİS</p>
            <strong class="body_m_demi">Levi’s Azərbaycan</strong>
          </div>
        </div>
        <img class="men_img" src="assets/images/slogan-photo.png" alt="" />
      </div>
    </div>
  </div>

  <div class="container">
    <div class="join_us_info">
      <div class="js_info_item">
        <h2
          class="h2_med"
          [ngClass]="{
            h4_med: deviceType == 'mobile'
          }"
        >
          {{ counterState?.store ? counterState.store : "" }}
        </h2>
        <p class="body_l_med">{{ "Store count" | translate }}</p>
      </div>
      <div class="js_info_item">
        <h2
          class="h2_med"
          [ngClass]="{
            h4_med: deviceType == 'mobile'
          }"
        >
          {{ counterState?.category ? counterState.category : "" }}
        </h2>
        <p class="body_l_med">{{ "Category count" | translate }}</p>
      </div>
      <div class="js_info_item">
        <h2
          class="h2_med"
          [ngClass]="{
            h4_med: deviceType == 'mobile'
          }"
        >
          {{ counterState?.product ? counterState.product : "" }}
        </h2>
        <p class="body_l_med">{{ "Product count" | translate }}</p>
      </div>
      <div class="js_info_item">
        <h2
          class="h2_med"
          [ngClass]="{
            h4_med: deviceType == 'mobile'
          }"
        >
          {{ counterState?.view ? counterState.view : "" }}
        </h2>
        <p class="body_l_med">{{ "View count" | translate }}</p>
      </div>
    </div>
    <div class="section_how_do_work">
      <h3 class="h3_demi" [ngClass]="{ body_l_med: deviceType == 'mobile' }">
        {{ "How works" | translate }}?
      </h3>
      <div class="how_do_work_cntnr">
        <div class="how_do_work_item" *ngFor="let item of how_works">
          <div class="image_block">
            <img [src]="item.image" alt="" />
          </div>
          <div class="content_block">
            <h6
              class="h6_demi"
              [ngClass]="{ body_m_med: deviceType == 'mobile' }"
              [innerHTML]="item.title"
            ></h6>
            <p class="body_s_reg" [innerHTML]="item.description"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="advantages">
      <h3 class="h3_demi" [ngClass]="{ body_l_med: deviceType == 'mobile' }">
        {{ "Advantages" | translate }}
      </h3>
      <div class="advantages_grid">
        <div class="advantage_card" *ngFor="let item of advantages">
          <div class="advantage_card__left">
            <i [class]="item.icon"></i>
          </div>
          <div class="advantage_card__right">
            <strong class="body_l_med" [innerHTML]="item.title"></strong>
            <p class="body_s_reg" [innerHTML]="item.description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="brands_container container"
    *ngIf="localState.brands_items.items?.length > 0"
  >
    <brands-carousel
      [items]="localState.brands_items.items"
      [max_item_count]="localState.brands_items.max_item_count"
    >
    </brands-carousel>
  </div>
</div>
