import { Subscription } from "rxjs";
import { SharedService } from "./../../../../shared/services/shared.service";
import { Title } from "@angular/platform-browser";
import { variables } from "src/environments/variables";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MallService } from "../../../mall.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BlogsService } from "../../blogs/blogs.service";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class IndexComponent implements OnInit {
  constructor(
    private MALL: MallService,
    private BLOGS: BlogsService,
    private title: Title,
    private spinner: NgxUiLoaderService,
    private shared: SharedService
  ) {
    this.getIndexPageCarousels();
    this.title.setTitle(
      "Caspimall : Onlayn Alış-Veriş Elektronika, Paltar, Məişət Texnikası, Ev üçün hər şey və s."
    );
    this.spinner.start();

    this.subscription = this.shared.staticData.subscribe((res) => {
      if (res) {
        this.res = res;
        this.generateStatistics();
      }
    });
    if (this.shared.staticKeys) {
      this.res = this.shared.staticKeys;
    }
    this.generateStatistics();
  }

  public subscription: Subscription = null;
  private res: any = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public localState = {
    fullwidth_state: {
      items: [],
      mobile_items: []
    },
    favorite_items: {
      items: [],
      heading: "Populyar məhsullar",
      show_all_url: {
        order_by: "view",
        order_dir: "desc",
      },
      max_item_count: 6,
    },
    discount_items: {
      items: [],
      heading: "Endirimli məhsullar",
      show_all_url: {
        type: "discount",
      },
      max_item_count: 6,
    },
    birkart_items: {
      items: [],
      heading: "BirKart products",
      show_all_url: {
        type: "birkart",
      },
      max_item_count: 6,
    },
    last_created_items: {
      items: [],
      heading: "Yeni məhsullar",
      show_all_url: {
        order_by: "date",
        order_dir: "desc",
      },
      max_item_count: 6,
    },
    features_items: {
      items: [],
    },
    brands_items: {
      items: [],
      heading: "Endirimli məhsullar",
      show_all_url: "",
      max_item_count: 8,
    },
    category_banners: {
      items: [],
    },
    blogs: [],
  };

  getIndexPageCarousels() {
    // load from cache ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    if (localStorage.getItem("ind_fullwidth")) {
      this.localState.fullwidth_state.items = JSON.parse(
        localStorage.getItem("ind_fullwidth")
      );
    }
    if (localStorage.getItem("mobile_banners")) {
      this.localState.fullwidth_state.mobile_items = JSON.parse(
        localStorage.getItem("mobile_banners")
      );
    }
    if (localStorage.getItem("ind_discount")) {
      this.localState.discount_items.items = JSON.parse(
        localStorage.getItem("ind_discount")
      );
    }
    if (localStorage.getItem("ind_favorite")) {
      this.localState.favorite_items.items = JSON.parse(
        localStorage.getItem("ind_favorite")
      );
    }
    if (localStorage.getItem("ind_birkart")) {
      this.localState.birkart_items.items = JSON.parse(
        localStorage.getItem("ind_birkart")
      );
    }
    if (localStorage.getItem("ind_latest")) {
      this.localState.last_created_items.items = JSON.parse(
        localStorage.getItem("ind_latest")
      );
    }
    if (localStorage.getItem("ind_brands")) {
      this.localState.brands_items.items = JSON.parse(
        localStorage.getItem("ind_brands")
      );
    }
    if (localStorage.getItem("ind_blogs")) {
      this.localState.blogs = JSON.parse(localStorage.getItem("ind_blogs"));
    }
    // load from cache ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    this.MALL.getIndexPageSliders().subscribe((RESPONSE) => {
      this.localState.fullwidth_state.items = RESPONSE.body;
      localStorage.setItem("ind_fullwidth", JSON.stringify(RESPONSE.body));
    });

    this.MALL.getMobileSliders().subscribe((RESPONSE) => {
      this.localState.fullwidth_state.mobile_items = RESPONSE.body;
      localStorage.setItem("mobile_banners", JSON.stringify(RESPONSE.body));
    });


    this.MALL.getProductsList({
      per_page: 20,
      type: "discount",
    }).subscribe((RESPONSE) => {
      this.localState.discount_items.items = RESPONSE.body.data;
      localStorage.setItem("ind_discount", JSON.stringify(RESPONSE.body.data));
    });
    this.MALL.getProductsList({
      per_page: 20,
      order_by: "view",
      order_dir: "desc",
    }).subscribe((RESPONSE) => {
      this.localState.favorite_items.items = RESPONSE.body.data;
      localStorage.setItem("ind_favorite", JSON.stringify(RESPONSE.body.data));
    });
    this.MALL.getProductsList({
      per_page: 20,
      type: "birkart",
    }).subscribe((RESPONSE) => {
      this.localState.birkart_items.items = RESPONSE.body.data;
      localStorage.setItem("ind_birkart", JSON.stringify(RESPONSE.body.data));
    });
    this.MALL.getProductsList({
      per_page: 20,
      order_by: "date",
      order_dir: "desc",
    }).subscribe((RESPONSE) => {
      this.localState.last_created_items.items = RESPONSE.body.data;
      localStorage.setItem("ind_latest", JSON.stringify(RESPONSE.body.data));
    });
    // this.MALL.getIndexCategoryCards().subscribe((RESPONSE) => {
    //   this.localState.category_banners.items = RESPONSE.body;
    //   this.localState.category_banners.items.length = 5;
    //   localStorage.setItem("ind_cat_banners", JSON.stringify(RESPONSE.body));
    // });

    this.MALL.getBrands().subscribe((RESPONSE) => {
      this.localState.brands_items.items = RESPONSE.body;
      localStorage.setItem("ind_brands", JSON.stringify(RESPONSE.body));
    });
    // this.MALL.getMainSlidersTipe("features").subscribe((RESPONSE) => {
    //   this.localState.features_items.items = RESPONSE.data;

    // });
    this.BLOGS.getBlogsList(1).subscribe((RESPONSE) => {
      this.localState.blogs = RESPONSE.body.data;
      localStorage.setItem("ind_blogs", JSON.stringify(RESPONSE.body));
    });

    this.localState.features_items.items = this.shared.sharedFeatures;
  }

  generateStatistics() {
    this.localState.category_banners.items = [];
    for (let i = 1; i <= 5; i++) {
      this.localState.category_banners.items.push({
        url: this.res?.[`main_page_bottom_banner_${i}_url`],
        image: this.res?.[`main_page_bottom_banner_${i}_image`],
      });
    }
  }
}
