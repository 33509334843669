<mall-header
  *ngIf="!in_mobile"
  [ngClass]="{ in_mobile: in_mobile }"
></mall-header>
<section
  class="page_min_height"
  [ngClass]="{ hide: hideAll, sec_in_mobile: in_mobile }"
>
  <router-outlet></router-outlet>
</section>
<!-- test pr -->
<mall-footer
  *ngIf="!in_mobile"
  [ngClass]="{ hide: hideAll, in_mobile: in_mobile }"
></mall-footer>
<installment-modal></installment-modal>
<feedback-modal></feedback-modal>
<comment-modal></comment-modal>
<add-to-card-modal></add-to-card-modal>
