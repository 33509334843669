<div class="container">
  <page-heading
    [heading]="'Brands'"
    [subtitle]="brands.length"
    *ngIf="brands"
  ></page-heading>

  <div class="brands_cntnr" *ngIf="state.stateStatus">
    <div class="brands_filter_cntnr" *ngIf="deviceType == 'desktop'">
      <div class="filter_input_div">
        <filter-input
          [name]="'brand_filter'"
          [placeholder]="'Search' | translate"
          [value]="inputValue"
          (inputOutput)="onBrandInputChange($event)"
        >
        </filter-input>
      </div>
      <div class="brands_filters">
        <ul>
          <ng-container *ngFor="let brand of brands">
            <li *ngIf="brand['visibility'] == true">
              <a [routerLink]="['/brand', brand.id]">{{ brand.name }}</a>
              <!-- <span>{{ brand.products_count }}</span> -->
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div
      class="mobile_filter_toggler"
      *ngIf="deviceType == 'mobile'"
      (click)="toggleBrandsList()"
    >
      <div class="icon_block fcc">
        <i class="fal fa-list"></i>
      </div>
      <span class="body_s_med">{{ "Brendlər listi" | translate }}</span>
    </div>
    <div class="fixed_brands_list" *ngIf="mobileBrandsList == true">
      <div class="close_mobile_brands" (click)="closeMobileBrands()">
        <div class="icon_block fcc">
          <i class="fal fa-times"></i>
        </div>
        <span class="body_m_med">{{ "Brendlər" | translate }}</span>
      </div>
      <div class="filter_input_div">
        <filter-input
          [name]="'brand_filter'"
          [placeholder]="'Search' | translate"
          [value]="inputValue"
          (inputOutput)="onBrandInputChange($event)"
        >
        </filter-input>
      </div>
      <div class="brands_filters">
        <ul>
          <ng-container *ngFor="let brand of brands">
            <li *ngIf="brand['visibility'] == true">
              <a [routerLink]="['/brand', brand.id]"
                >{{ brand.name }} <span>{{ brand.products_count }}</span></a
              >
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div class="brands_result_cntnr">
      <div class="brand_banner">
        <div>
          <h5 class="h5_demi">Caspi Mall bütün brendləri birləşdirir</h5>
          <!-- <p class="body_s_reg">
            {{ brands.length }} dan çox brendlər bir arada
          </p> -->
        </div>
        <div>
          <img src="assets/images/caspi_logo.svg" />
        </div>
      </div>
      <div class="brands_list_cntnr">
        <ng-container *ngFor="let brand of brands">
          <div class="brand_item" *ngIf="brand['visibility'] == true">
            <a [routerLink]="['/brand', brand.id]">
              <img [src]="brand.image" [alt]="brand.id" />
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
