import { Router } from "@angular/router";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges,
} from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-photo-gallery",
  templateUrl: "./photo-gallery.component.html",
  styleUrls: ["./photo-gallery.component.scss"],
})
export class PhotoGalleryComponent implements OnInit {
  public customOptions: OwlOptions = {};

  @Input() public images: [];
  @Input() public current: number = 0;
  @Input() public isOpened: boolean;
  @Output() public modalStatus = new EventEmitter();

  constructor(private router: Router) {
    this.router;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      nav: true,
      margin: 0,
      navText: [
        "<i class='far fa-chevron-left'></i>",
        "<i class='far fa-chevron-right'></i>",
      ],
      items: 1,
    };

    setTimeout(() => {
      if (this.current > -1) {
        this.setSliderTo(this.current);
      }
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    setTimeout(() => {
      if (this.current > -1) {
        this.setSliderTo(this.current);
      }
    }, 100);
  }

  @HostListener("document:keydown.escape", ["$event"])
  public onKeydownHandler(event: KeyboardEvent) {
    this.onCloseClick();
  }

  public onCloseClick(): void {
    this.isOpened = false;
    this.modalStatus.emit(this.isOpened);
  }

  private simulateClick(elem): void {
    const evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    const canceled = !elem?.dispatchEvent(evt);
  }

  public setSliderTo(index): void {
    const position = index + 1;
    const selector = document.querySelector(
      ".owl-dots .owl-dot:nth-child(" + position + "n)"
    );
    this.simulateClick(selector);
  }
}
