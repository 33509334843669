import { OneSignalService } from "./../shared/services/one-signal.service";
import { variables } from "src/environments/variables";
import {
  Category,
  indexBanner,
  ListProduct,
  ProductDetail,
} from "./mall.models";
import { Level, RES } from "./../shared/shared.model";
import { Observable } from "rxjs/internal/Observable";
import { createParams } from "src/app/common/utils/queryParams/createParams.function";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { UserService } from "src/app/common/utils/user/user.service";
import { generateHeaderConfig } from "src/app/common/utils/functions/generateHeaderConfig.function";
import { AuthService } from "../auth/auth.service";
import { NotifyService } from "../shared/services/notify.service";

@Injectable({
  providedIn: "root",
})
export class MallService {
  constructor(
    private HTTP: HttpClient,
    private AUTH: AuthService,
    private NOTIFY: NotifyService,
    private ROUTER: Router,
    private user: UserService,
    private OS: OneSignalService
  ) {}

  public categoryTree = new EventEmitter();
  public wishListCount = new EventEmitter();
  public cartCount = new EventEmitter();

  public setCounters() {
    if (localStorage.getItem("front_access_token")) {
      this.AUTH.getUserDetails().subscribe(
        (RESPONSE) => {
          if (RESPONSE.body.tags) {
            let tags = RESPONSE.body.tags;
            this.OS.onLoad().then((OneSignal) => {
              OneSignal.push(function () {
                OneSignal.sendTags(tags).then(function (tagsSent) {});
              });
            });
          }
          this.cartCount.emit(RESPONSE.body.cart_count);
          this.wishListCount.emit(RESPONSE.body.favorite_count);
          variables.cookie.user_data.user = RESPONSE.body;
          localStorage.setItem("front_user", JSON.stringify(RESPONSE.body));
          this.user.updateValues();
        },
        (err) => {
          this.NOTIFY.error(err);
        }
      );
    } else {
      this.getStoreCounts().subscribe(
        (res) => {
          this.cartCount.emit(res.body.original.cart);
        },
        (err) => {
          this.NOTIFY.error(err);
        }
      );
    }
  }

  public getProductDetails(path: string): Observable<Level<ProductDetail>> {
    return this.HTTP.get<Level<ProductDetail>>(`product/${path}`, {
      ...generateHeaderConfig(),
    });
  }

  public getProductComments(
    slug: string,
    page: any = 1
  ): Observable<Level<any>> {
    return this.HTTP.get<Level<any>>(`product/${slug}/comments`, {
      params: {
        page: page,
      },
      ...generateHeaderConfig(),
    });
  }

  // for Category page banner, children and brands-carousel
  public getCategoryChildren(categoryName): Observable<any> {
    return this.HTTP.get<any>(
      `product/api/v1/client/category?url_key[]=${categoryName}`,
      {
        ...generateHeaderConfig(),
      }
    );
  }

  public getCategorySlides(params): Observable<any> {
    return this.HTTP.get<any>(`product/api/v1/client/category`, {
      ...generateHeaderConfig(),
      params: params,
    });
  }

  // for Category page discount, last_created, favorite product_list carousels
  public getCategorySliderProducts(params): Observable<any> {
    return this.HTTP.get<any>(
      "product/api/v1/client/catalog/category-product-slider",
      {
        ...generateHeaderConfig(),
        params: params,
      }
    );
  }

  // public getProductsList(params): Observable<any> {
  //   return this.HTTP.get<any>(`product/api/v1/client/catalog/mobile`, {
  //     ...generateHeaderConfig(),
  //     params: params,
  //   });
  // }

  public getCategoriesNav(): Observable<Level<Category[]>> {
    return this.HTTP.get<Level<Category[]>>("category", {
      ...generateHeaderConfig(),
    });
  }

  public getIndexMainSlidersType(type: string): Observable<any> {
    return this.HTTP.get<any>(`main/v1/sliders-type?type=${type}`, {
      ...generateHeaderConfig(),
    });
  }

  public getIndexSliders(type: string): Observable<any> {
    return this.HTTP.get<any>(
      `product/api/v1/client/catalog/product-slider/${type}`,
      {
        ...generateHeaderConfig(),
      }
    );
  }

  public getMainSlidersTipe(type: string): Observable<any> {
    return this.HTTP.get<any>(`main/v1/sliders-type`, {
      ...generateHeaderConfig(),
      params: { type: type },
    });
  }

  public getCategoryBanners(): Observable<any> {
    return this.HTTP.get<any>(`main/v1/banners`, {
      ...generateHeaderConfig(),
    });
  }

  public getBrands(): Observable<Level<any>> {
    return this.HTTP.get<Level<any>>(`/helpers/stores`, {
      ...generateHeaderConfig(),
    });
  }

  public getBrandSlider(brandId): Observable<any> {
    return this.HTTP.get<any>(`store/client/stores/${brandId}/banners`, {
      ...generateHeaderConfig(),
    });
  }

  public getIndexBrands(): Observable<any> {
    return this.HTTP.get<any>(`main/v1/brands`, {
      ...generateHeaderConfig(),
    });
  }

  public getSearchResults(params): Observable<any> {
    return this.HTTP.get<any>("helpers/search", {
      ...generateHeaderConfig(),
      params: createParams(params),
    });
  }

  public searchResultsBody = new EventEmitter();
  public getPhotoSearch(body): Observable<any> {
    return this.HTTP.post<any>("helpers/search/image", body, {
      ...generateHeaderConfig(),
    });
  }

  public getCartSimilars(params): Observable<any> {
    return this.HTTP.get<any>("product/api/v1/client/catalog/cart/related", {
      ...generateHeaderConfig(),
      params: params,
    });
  }

  public getRelatedProducts(productID: number, params): Observable<any> {
    return this.HTTP.get<any>(
      `product/api/v1/client/catalog/product/related/${productID}`,
      {
        ...generateHeaderConfig(),
        params: params,
      }
    );
  }

  public getBrandSlidersById(path: string, params: any): Observable<any> {
    return this.HTTP.get<any>(
      `product/api/v1/client/catalog/product-type/${path}`,
      {
        ...generateHeaderConfig(),
        params: params,
      }
    );
  }

  public getBrandCollectionsById(params: any): Observable<any> {
    return this.HTTP.get<any>(
      `product/api/v1/client/catalog/store/collections`,
      {
        ...generateHeaderConfig(),
        params: params,
      }
    );
  }

  public getStatics(type): Observable<any> {
    return this.HTTP.get<any>(`main/v1/static-page/${type}`, {
      ...generateHeaderConfig(),
    });
  }

  public getStoreCounts(): Observable<any> {
    return this.HTTP.get<any>(`helpers/counts`, {
      ...generateHeaderConfig(),
    });
  }

  // NEW APISSSSSSSSSS ~~~~~~~~~~~~~~~~~~~~~~
  public getIndexPageSliders(): Observable<Level<indexBanner[]>> {
    return this.HTTP.get<Level<indexBanner[]>>(`helpers/banners`, {
      ...generateHeaderConfig(),
    });
  }

  public getMobileSliders(): Observable<Level<indexBanner[]>> {
    return this.HTTP.get<Level<indexBanner[]>>(`helpers/mobile-banners`, {
      ...generateHeaderConfig(),
    });
  }

  public getIndexCategoryCards(): Observable<Level<any>> {
    return this.HTTP.get<Level<any>>(`helpers/category-banners`, {
      ...generateHeaderConfig(),
    });
  }

  public getProductListByType(type: string): Observable<RES<ListProduct[]>> {
    return this.HTTP.get<RES<ListProduct[]>>(`product/list/${type}`, {
      ...generateHeaderConfig(),
    });
  }

  // general API for ProductLists
  public getProductsList(params): Observable<any> {
    return this.HTTP.get<any>(`product/list`, {
      ...generateHeaderConfig(),
      params: params,
    });
  }

  // get Category Details
  public getCategoryDetails(slug: string): Observable<any> {
    return this.HTTP.get<any>(`category/details/${slug}`, {
      ...generateHeaderConfig(),
    });
  }

  // getCategorySliders
  public getCategorySliders(type: string, slug: string): Observable<any> {
    return this.HTTP.get<any>(`product/list/${type}/${slug}`, {
      ...generateHeaderConfig(),
    });
  }

  // Brands list for Brands Page
  public getBrandsList(): Observable<Level<any>> {
    return this.HTTP.get<Level<any>>(`store/list`, {
      ...generateHeaderConfig(),
    });
  }
  public getBrandById(id: number): Observable<Level<any>> {
    return this.HTTP.get<Level<any>>(`store/${id}/detailed`, {
      ...generateHeaderConfig(),
    });
  }

  public likeComment(comment_id: number): Observable<any> {
    return this.HTTP.patch<any>(`comment/${comment_id}/like`, null, {
      ...generateHeaderConfig(),
    });
  }

  public dislikeComment(comment_id: number): Observable<any> {
    return this.HTTP.patch<any>(`comment/${comment_id}/dislike`, null, {
      ...generateHeaderConfig(),
    });
  }

  public getStaticPage(key: string): Observable<any> {
    return this.HTTP.get<any>(`setting?paired=true`, {
      ...generateHeaderConfig(),
      params: {
        key: key,
      },
    });
  }

  public navigationToggled = new EventEmitter<any>();
  public installmentModal = new EventEmitter();
}
