import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "renderStatus",
})
export class RenderStatusPipe implements PipeTransform {
  transform(type: number): any {
    switch (type) {
      case 0:
      case 2:
      case 7:
      case 8:
      case 9:
        return `<i class="fas fa-clock t_warning"></i>`;
        break;
      case 1:
      case 11:
        return `<i class="fas fa-times-circle t_danger"></i>`;
        break;
      case 10:
        return `<i class="fas fa-check-circle t_success"></i>`;
        break;
    }
  }
}

// const STATUS_PENDING_PAYMENT = 0;
// const STATUS_PAYMENT_FAILED = 1;
// const STATUS_PENDING_STORE = 2;
// const STATUS_IN_WAREHOUSE = 7;
// const STATUS_SCHEDULED_TO_DELIVER = 8;
// const STATUS_OUT_TO_DELIVER = 9;
// const STATUS_DELIVERED = 10;
// const STATUS_CANCELED = 11;
