import { Inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { variables } from "../../../../../environments/variables";
import { Language } from "../typings/Language.type";
import { LanguageOption } from "../models/LanguageOption.model";
import { languageOptions, initialLanguage } from "../data/languageOptions.data";
import { DOCUMENT } from "@angular/common";
@Injectable({ providedIn: "root" })
//======================================================================
export class LanguageService {
  //======================================================================

  constructor(
    private TRANSLATE: TranslateService,
    private TITLE: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.languages = languageOptions;
    //SET INITIAL LANGUAGE
    this.setDefaultLang(initialLanguage);
  }

  //======================================================================

  //INITIAL LANGUAGE
  public current_lang: Language;
  public languages: LanguageOption[];

  //======================================================================

  //ON PAGE RELOAD GET SELECTED LANG FROM LOCAL STORAGE  ||||||||||||||||||||||||||||||||||||||
  private setDefaultLang(lang: Language): void {
    const local_lang: Language = localStorage.getItem(
      variables.local_storage.language
    ) as Language;
    if (this.checkLanguageHas(local_lang)) {
      lang = local_lang;
    } else {
      lang = lang;
    }
    localStorage.setItem(variables.local_storage.language, lang);
    localStorage.setItem(
      "lang",
      localStorage.getItem(variables.local_storage.language)
    );
    this.document.documentElement.lang = lang;
    this.current_lang = lang;
    this.TRANSLATE.use(this.current_lang);
  }

  //======================================================================

  private checkLanguageHas(lang: Language): boolean {
    for (let i = 0; i < this.languages.length; i++) {
      if (lang == this.languages[i].code) {
        return true;
      }
    }
    return false;
  }

  //======================================================================

  //SWITCH LANGUAGE
  public switchLang(language: any) {
    if (!this.checkLanguageHas(language)) {
      language = this.languages[0].code;
    }
    this.TRANSLATE.use(language);
    localStorage.setItem(variables.local_storage.language, language);
    localStorage.setItem(
      "lang",
      localStorage.getItem(variables.local_storage.language)
    );
    this.current_lang = language;
  }

  //======================================================================

  public setPageTitle(translate_key: string): void {
    let text: string = this.TRANSLATE.instant(translate_key);
    if (!text.includes("title")) {
      this.TITLE.setTitle(text);
    } else {
      setTimeout(() => {
        text = this.TRANSLATE.instant(translate_key);
        this.TITLE.setTitle(text);
      }, 400);
    }
  }
} //======================================================================
