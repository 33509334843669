import { ProfileService } from "src/app/common/utils/user/profile.service";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { NotifyService } from "src/app/modules/shared/services/notify.service";

@Component({
  selector: "app-profile-orders-follow",
  templateUrl: "./profile-orders-follow.component.html",
  styleUrls: ["./profile-orders-follow.component.scss"],
})
export class ProfileOrdersFollowComponent implements OnInit {
  constructor(
    public ACTIVATED_ROUTE: ActivatedRoute,
    public profile: ProfileService,
    private NOTIFY: NotifyService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getTrackingStatus();
  }

  public deliveryChanges = [];
  public order: any;
  public order_id: number;
  public order_date: string;

  public getTrackingStatus() {
    this.ACTIVATED_ROUTE.paramMap.subscribe((path) => {
      this.order_id = Number(path.get("id"));
      this.profile.getOrderTracking(this.order_id).subscribe(
        (res) => {
          this.order = res.body;
          this.order_date = this.order.created_at;
          this.deliveryChanges = this.order.history;

          this.trackingStates.accepted = this.order.accepted;
          this.trackingStates.canceled = this.order.canceled;
          this.trackingStates.shipped = this.order.courier_out_to_deliver;
          this.trackingStates.delivered = this.order.delivered;

          this.setTrackingStatus();
        },
        (err) => {
          this.NOTIFY.error(err);
        }
      );
    });
  }

  public trackingStates = {
    canceled: false,
    shipped: false,
    delivered: false,
    accepted: true,
  };

  public trackingStatus: number = 0;

  public setTrackingStatus() {
    if (this.trackingStates.canceled) {
      this.trackingStatus = 0;
    } else {
      this.trackingStatus = 1;
    }
    if (this.trackingStates.accepted) {
      this.trackingStatus = 2;
    }
    if (this.trackingStates.shipped) {
      this.trackingStatus = 3;
    }
    if (this.trackingStates.delivered) {
      this.trackingStatus = 4;
    }
  }
}
