import { UserService } from "src/app/common/utils/user/user.service";
import { Event, NavigationEnd, Router } from "@angular/router";
import { element } from "protractor";
import { MallService } from "./../../mall.service";
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { CartService } from "../../../../common/utils/user/cart.service";
import { FavoritesService } from "src/app/common/utils/user/favorites.service";
import { SharedService } from "src/app/modules/shared/services/shared.service";
import { Subscription } from "rxjs";

@Component({
  selector: "mall-header",
  templateUrl: "./mall-header.component.html",
  styleUrls: ["./mall-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MallHeaderComponent implements OnInit {
  constructor(
    private MALL: MallService,
    private cart: CartService,
    private user: UserService,
    private favorites: FavoritesService,
    private ROUTER: Router,
    private SHARED: SharedService
  ) {
    this.loadInitials();
    ROUTER.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.hideMobileCategory();
      }
    });

    this.subscription = this.SHARED.staticData.subscribe((res) => {
      if (res) {
        this.state.phone = res.header_left_phone_number;
      }
    });
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public state = {
    data_avaiable: false,
    categories_nav: null,
    cart_item_count: null,
    favorites_count: null,
    user_name: null,
    phone: null,
  };

  ngOnInit(): void {
    window.scroll(0, 0);
    this.cart.onChange().subscribe((res) => {
      this.state.cart_item_count = res;
    });

    this.MALL.wishListCount.subscribe((res) => {
      this.state.favorites_count = res;
    });
  }

  getCategoriesNav() {
    this.MALL.getCategoriesNav().subscribe((RESPONSE) => {
      this.state.categories_nav = RESPONSE.body;
      this.state.data_avaiable = true;
    });
  }

  public loadInitials() {
    this.MALL.categoryTree.subscribe((res) => {
      if (res) {
        this.state.categories_nav = res;
        this.state.data_avaiable = true;
      }
    });
    this.MALL.cartCount.subscribe((res) => {
      this.state.cart_item_count = res;
    });
    this.MALL.wishListCount.subscribe((res) => {
      this.state.favorites_count = res;
    });
  }

  public mobileHeader = {
    category_toggler: false,
  };

  public toggleMobileCategory() {
    this.mobileHeader.category_toggler = !this.mobileHeader.category_toggler;
    this.MALL.navigationToggled.emit(this.mobileHeader.category_toggler);
    this.mobileSideNav.current_position = 0;
    const element = document.querySelector("html");
    // if (this.mobileHeader.category_toggler) {
    //   element.style.overflow = "hidden";
    //   element.style.height = "100vh";
    // } else {
    //   element.style.overflow = "unset";
    //   element.style.height = "unset";
    // }
  }

  public hideMobileCategory() {
    this.MALL.navigationToggled.emit(false);
    this.mobileHeader.category_toggler = false;
    this.mobileSideNav.current_position = 0;
    // const element = document.querySelector("html");
    // element.style.overflow = "unset";
    // element.style.height = "unset";
  }

  public mobileSideNav = {
    current_position: 0,
    selected_category: null,
    selected_sub_category: null,
  };

  navigateToCategories() {
    this.toggleMobileCategory();
    this.ROUTER.navigate(
      [`/categories/${this.mobileSideNav.selected_category.slug}`],
      {
        queryParams: { category_id: this.mobileSideNav.selected_category.id },
      }
    );
  }

  navigateToSubCategories() {
    this.toggleMobileCategory();
    this.ROUTER.navigate([`/list`], {
      queryParams: {
        category_id: this.mobileSideNav.selected_sub_category.id,
        slug: this.mobileSideNav.selected_sub_category.slug,
      },
    });
  }

  public showSelectedCategory(id) {
    if (this.mobileSideNav.current_position == 0) {
      this.mobileSideNav.selected_category = this.state.categories_nav[id];
      this.mobileSideNav.current_position = 1;
    } else if (this.mobileSideNav.current_position == 1) {
      this.mobileSideNav.selected_sub_category =
        this.mobileSideNav.selected_category.children[id];
      if (this.mobileSideNav.selected_sub_category.children.length == 0) {
        this.toggleMobileCategory();
        this.ROUTER.navigate([
          `list${this.mobileSideNav.selected_category.url_path}`,
        ]);
      } else {
        this.mobileSideNav.current_position = 2;
      }
    }
  }

  public getNavigationBack(id: any) {
    this.mobileSideNav.current_position = id;
  }

  public toCategoryList(sub_category: any) {
    this.toggleMobileCategory();
    this.ROUTER.navigate([`/list`], {
      queryParams: {
        category_id: sub_category.id,
        slug: sub_category.slug,
      },
    });
  }
}
