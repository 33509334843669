<div class="signup_wrapper">
  <nav class="auth_navigation">
    <a
      [routerLink]="['/auth/login']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      >{{ "login" | translate }}</a
    >
    <a
      [routerLink]="['/auth']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      >{{ "signup" | translate }}</a
    >
  </nav>
  <div class="signup_reg" *ngIf="localState.signup_request_sent == false">
    <div class="inputs_container">
      <custom-input
        [labelHead]="'firstname' | translate"
        [labelFor]="'signup_firstname'"
      >
        <input
          #customInput
          inpEvnts
          type="text"
          name="signup_firstname"
          autocomplete="off"
          [(ngModel)]="localState.firstname"
          autocomplete="off"
        />
      </custom-input>
      <custom-input
        [labelHead]="'lastname' | translate"
        [labelFor]="'signup_lastname'"
      >
        <input
          #customInput
          inpEvnts
          type="text"
          name="signup_lastname"
          autocomplete="off"
          [(ngModel)]="localState.lastname"
          autocomplete="off"
        />
      </custom-input>
      <custom-input
        [labelHead]="'phone_number' | translate"
        [labelFor]="'signup_phone'"
      >
        <input
          #customInput
          inpEvnts
          type="text"
          name="signup_phone"
          inputmode="numeric"
          prefix="+994"
          mask="(00) 000 00 00"
          [(ngModel)]="localState.signup_phone"
          autocomplete="off"
        />
      </custom-input>
    </div>

    <div class="privacy_div">
      <custom-checkbox
        [checkStatus]="localState.privacy_status"
        [usability]="true"
        (checkboxValue)="privacyChecked($event)"
      >
        <div #customCheckbox>
          <ng-container>
            <p>
              {{ "signup_checkmark_text_1" | translate }}
              <button (click)="showPrivacyPolicyModal($event)">
                {{ "privacy_policy" | translate }}
              </button>
              {{ "signup_checkmark_text_2" | translate }}
            </p>
          </ng-container>
          <!-- <ng-container *ngIf="getLang() == 'ru' || getLang() == 'en'">
            <p>
              {{ "signup_checkmark_text_1" | translate }}
              {{ "signup_checkmark_text_2" | translate }}
              <button (click)="showPrivacyPolicyModal($event)">
                {{ "privacy_policy" | translate }}
              </button>
            </p>
          </ng-container> -->
        </div>
      </custom-checkbox>
    </div>

    <div class="signup_code_submit">
      <button
        class="btn btn-lg btn-fill w-100"
        [ngClass]="{ disabled: !validateGetPassword() }"
        (click)="showLocalState($event)"
      >
        {{ "accept_signup" | translate }}
      </button>
    </div>
  </div>

  <div class="signup_code" *ngIf="localState.signup_request_sent == true">
    <h6 class="h6_demi">{{ "phone_number_approval" | translate }}</h6>
    <p class="approve_number_p body_s_reg">
      {{ "approve_number_p_1" | translate }} +994 ({{
        localState.masked_signup_phone.slice(0, 2)
      }}) {{ localState.masked_signup_phone.slice(2, 5) }} ** **
      {{ "approve_number_p_2" | translate }}
    </p>
    <div class="solo_input">
      <custom-input [labelHead]="'code' | translate" [labelFor]="'code'">
        <input
          #customInput
          inpEvnts
          type="text"
          name="code"
          inputmode="numeric"
          mask="0000"
          pattern="[0-9]*"
          [(ngModel)]="localState.code"
          autocomplete="off"
        />
        <div class="countdown" #customInput>
          <span class="body_s_med">{{ localState.countDown }}</span>
        </div>
      </custom-input>
    </div>
    <button
      class="btn btn-lg btn-fill w-100"
      [ngClass]="{ disabled: !validatePassCode() }"
      (click)="finishRegistration()"
    >
      {{ "accept_signup" | translate }}
    </button>
    <div class="signup_login">
      <p class="body_m_reg">
        {{ "code_lost" | translate }}?
        <button
          class="body_m_med resend_code_btn"
          [ngClass]="{ disabled: localState.resendCodeStatus == true }"
          (click)="getOTP()"
        >
          {{ "resend_code" | translate }}
        </button>
      </p>
    </div>
  </div>
</div>

<section
  class="privacy_modal"
  [ngClass]="{ active: privacyModalToggler == true }"
>
  <div class="white_box">
    <div class="modal_heading">
      <strong class="body_m_demi">{{ "privacy_policy" | translate }}</strong>
      <div
        class="icon_block close_btn fcc"
        (click)="closePrivacyPolicyModal($event)"
      >
        <i class="fas fa-times-circle"></i>
      </div>
    </div>
    <div class="modal_content">
      <p class="body_s_reg" [innerHTML]="privacy_modal_text"></p>
    </div>
    <div class="modal_bottom">
      <div
        class="approve_privacy btn btn-fill btn-med btn-max"
        (click)="approvePrivacy()"
      >
        {{ "approve_btn" | translate }}
      </div>
    </div>
  </div>
</section>
