import { Observable } from "rxjs/internal/Observable";
import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserService } from "src/app/common/utils/user/user.service";
import { generateHeaderConfig } from "src/app/common/utils/functions/generateHeaderConfig.function";

@Injectable({
  providedIn: "root",
})
export class BasketService {
  constructor(private http: HttpClient, private user: UserService) {}

  public products = new EventEmitter<any>();

  public checkoutData = new EventEmitter<sendingState>();

  public summary = new EventEmitter<summaryType>();

  public checkout(formData): Observable<any> {
    return this.http.post<any>(`order`, formData, {
      ...generateHeaderConfig(),
      observe: "response",
    });
  }

  public installmentCheckout(product_id: any, formData): Observable<any> {
    return this.http.post<any>(`order/${product_id}/birkart`, formData, {
      ...generateHeaderConfig(),
      observe: "response",
    });
  }

  public updateSummary(
    address_id?: any,
    shipment_type?: any,
    cashback?: number,
    promocode?: string
  ): Observable<any> {
    if (address_id && shipment_type >= 0) {
      let params: any = {
        front_user_address_id: address_id,
        shipment_type: shipment_type,
        cashback: cashback,
      };
      if (promocode) {
        params["promo-code"] = promocode;
      }

      return this.http.get<any>(`order`, {
        params: params,
        ...generateHeaderConfig(),
      });
    } else {
      return this.http.get<any>(`order`, {
        ...generateHeaderConfig(),
      });
    }
  }
  public installmentSummary(
    product_id: any,
    product_count: any,
    installment_month?: any,
    address_id?: any,
    shipment_type?: any,
    promocode?: string
  ): Observable<any> {
    if (address_id && shipment_type) {
      let params: any = {
        count: product_count,
        installment_month: installment_month,
        front_user_address_id: address_id,
        shipment_type: shipment_type,
      };

      if (promocode) {
        params["promo-code"] = promocode;
      }

      return this.http.get<any>(`order/${product_id}/birkart`, {
        params: params,
        ...generateHeaderConfig(),
      });
    } else {
      let params: any = {
        count: product_count,
      };

      if (promocode) {
        params["promo-code"] = promocode;
      }

      return this.http.get<any>(`order/${product_id}/birkart`, {
        params: params,
        ...generateHeaderConfig(),
      });
    }
  }
}

export interface summaryType {
  delivery_fee?: 0;
  additional_fee?: false;
  products: {
    total: number;
    discounted: number;
    final: number;
    count?: number;
  };
  types?: {
    fast: false;
    today: false;
    tomorrow: true;
    scheduled: true;
  };
  total?: number;
}

export interface sendingState {
  front_user_address_id: number;
  payment_method?: any;
  schedule_date?: any;
  schedule_time?: any;
  shipment_type: number;
  non_contact_delivery?: null;
  count?: any;
  installment_month?: any;
  address_checked?: boolean;
  shipment_type_checked?: boolean;
  cashback?: number;
}
