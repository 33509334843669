import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "product-grid",
  templateUrl: "./product-grid.component.html",
  styleUrls: ["./product-grid.component.scss"],
})
export class ProductGridComponent implements OnInit {
  constructor(private ACTIVATEDROUTE: ActivatedRoute, public router: Router) {
    this.detectDevice();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.initialUrl = this.router.url;
    if (this.sorting_options) {
      if (this.selected_sorting != null) {
        this.sorting_options.map((sort) => {
          if (sort.value == this.selected_sorting) {
            this.selected_sorting = sort;
          }
        });
      } else {
        this.selected_sorting = this.sorting_options[0];
      }
    }

    if (this.tags && this.tags.length > 0) {
      this.showRemoveBtn = false;
    }

    if (this.hidden_list) {
      this.hide_list = this.hidden_list;
    }
  }

  @Output("onFilterChange") onFilterChange = new EventEmitter<any>();
  @Output("filterToggler") filterToggler = new EventEmitter<any>();
  @Input("sorting_options") public sorting_options;
  @Input("selected_sorting") public selected_sorting;
  @Input("products") public products;
  @Input("tags") public tags;
  @Input("page") public page;
  @Input("hidden_list") public hidden_list;
  @Output("onDeleteUpdateGrid") onDeleteUpdateGrid = new EventEmitter<any>();
  public hide_list: boolean = true;

  public onDeleteUpdate(event) {
    this.onDeleteUpdateGrid.emit(event);
  }

  public default_view: boolean = false;
  public changeView() {
    this.default_view = !this.default_view;
  }

  public onGridSortingChanged(event) {
    this.onFilterChange.emit(event);
  }

  public showRemoveBtn: boolean = false;
  public initialUrl: string = null;

  public removeAllTags() {
    this.router.navigateByUrl(this.initialUrl);
  }

  public outputFilterToggler() {
    this.filterToggler.emit(true);
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
