export const generateHeaderConfig = () => {
  if (localStorage.getItem("front_access_token")) {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("front_access_token")}`,
        locale: localStorage.getItem("lang"),
        observe: "response",
      },
      withCredentials: true,
    };
  } else {
    return {
      headers: {
        locale: localStorage.getItem("lang"),
        observe: "response",
      },
      withCredentials: true,
    };
  }
};

export const generateHeaderConfigMultipart = () => {
  if (localStorage.getItem("front_access_token")) {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("front_access_token")}`,
        locale: localStorage.getItem("lang"),
        observe: "response",
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };
  } else {
    return {
      headers: {
        locale: localStorage.getItem("lang"),
        observe: "response",
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };
  }
};
