import { EventEmitter, Output, SimpleChanges } from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";

import { MallService } from "src/app/modules/mall/mall.service";
import { NotifyService } from "src/app/modules/shared/services/notify.service";

@Component({
  selector: "product-details-comments",
  templateUrl: "./product-details-comments.component.html",
  styleUrls: ["./product-details-comments.component.scss"],
})
export class ProductDetailsCommentsComponent implements OnInit {
  constructor(public NOTIFY: NotifyService, private MALL: MallService) {}

  ngOnInit(): void {}

  @Input() public product: any;
  @Input() public comments: any;
  @Output() commentsAction = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (this.product) {
      this.rating = this.product.comment_summary;
    }
  }

  public rating: any = null;

  public addReview() {
    this.NOTIFY.commentProduct.emit(this.product);
    this.NOTIFY.showCommentModal();
  }

  public getLang() {
    return localStorage.getItem("lang");
  }

  public likeProduct(id: number) {
    if (!this.comments?.data[id].liked) {
      this.NOTIFY.startSpinner();
      this.MALL.likeComment(this.comments.data[id].comment_id).subscribe(
        (RESPONSE) => {
          this.NOTIFY.success(RESPONSE);
          this.commentsAction.emit(true);
        },
        (err) => {
          this.NOTIFY.error(err);
          this.NOTIFY.stopSpinner();
        }
      );
    }
  }

  public dislikeProduct(id: number) {
    if (!this.comments?.data[id].dislike) {
      this.NOTIFY.startSpinner();
      this.MALL.dislikeComment(this.comments.data[id].comment_id).subscribe(
        (RESPONSE) => {
          this.NOTIFY.success(RESPONSE);
          this.commentsAction.emit(true);
        },
        (err) => {
          this.NOTIFY.error(err);
          this.NOTIFY.stopSpinner();
        }
      );
    }
  }
}
