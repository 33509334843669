<section class="comment_modal" [ngClass]="{ active: modalStatus }">
  <div class="white_block">
    <div class="comment_title d-f ai-c jc-sb">
      <div class="body_m_med t_black_900">
        {{ "Write a comment" | translate }}
      </div>
      <button class="close_comment_btn fcc" (click)="NOTIFY.hideCommentModal()">
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
    <div class="pro-box">
      <div class="image fcc">
        <img src="{{ productState.image }}" alt="{{ productState.name }}" />
        <i *ngIf="productState.image?.length == 0" class="fal fa-microchip"></i>
      </div>
      <div class="data-box">
        <h3 class="body_m_med">{{ productState.name }}</h3>
        <ng-container *ngFor="let detail of productState.current_variation">
          <div class="body_xs_reg">
            {{ detail.attribute_name }}:
            <ng-container
              *ngIf="
                detail.value_name.length == 7 && detail.value_name.includes('#')
              "
            >
              <div
                class="filterable_color"
                [style.backgroundColor]="detail.value_name"
              ></div>
            </ng-container>
            <ng-container
              *ngIf="
                detail.value_name.length != 7 ||
                !detail.value_name.includes('#')
              "
            >
              <b>{{ detail.value_name }}</b>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="comment_form">
      <div class="form-group mb-24px">
        <label for="date" class="body_s_med">{{ "Rate" | translate }}</label>
        <div class="stars_block">
          <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
            <div class="star" (click)="onSelectChange(star)">
              <i
                class="fa-star"
                [ngClass]="{
                  fas: formData.stars >= star,
                  far: formData.stars < star
                }"
              ></i>
            </div>
          </ng-container>
        </div>
      </div>
      <custom-input
        [labelHead]="'Your comment' | translate"
        [labelFor]="'formData.comment'"
        class="mb-24px"
      >
        <textarea
          #customInput
          inpEvnts
          type="text"
          name="comment"
          autocomplete="off"
          [(ngModel)]="formData.comment"
        ></textarea>
      </custom-input>
      <button class="btn btn-max btn-fill btn-med" (click)="sendFeedback()">
        {{ "btn_send" | translate }}
      </button>
    </div>
  </div>
</section>
