import {
  Component,
  OnInit,
  ContentChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { EventManager } from "@angular/platform-browser";

@Component({
  selector: "custom-checkbox",
  templateUrl: "./custom-checkbox.component.html",
  styleUrls: ["./custom-checkbox.component.scss"],
})
export class CustomCheckboxComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  @Input("checkStatus") public checkStatus: boolean = false;
  @Input("usability") public usability: boolean = true;

  @Output() checkboxValue: EventEmitter<boolean> = new EventEmitter();

  @ContentChild("customCheckbox", {}) public input: ElementRef;

  public triggerCheck() {
    this.checkStatus = !this.checkStatus;
    this.checkboxValue.emit(this.checkStatus);
  }
}
