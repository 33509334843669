import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotifyService {
  constructor() {}

  public notification = new BehaviorSubject<Notify>(null);
  // fullheight spinner ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  public logoSpinner = new EventEmitter<boolean>();

  public startSpinner() {
    this.logoSpinner.emit(true);
  }

  public stopSpinner() {
    this.logoSpinner.emit(false);
  }
  // fullheight spinner ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // Notification popup ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  public set(notification: Notify) {
    this.notification.next(notification);
  }

  public success(RESPONSE: any) {
    this.set({
      text: RESPONSE.message,
      status: RESPONSE.level,
      seconds: 4,
    });
  }

  public error(err: any) {
    this.set({
      text: err.error.message,
      status: err.error.level,
      seconds: 4,
    });
  }
  // Notification popup ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // Modals ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  public modal = new EventEmitter<boolean>();

  public showModal() {
    this.modal.emit(true);
  }

  public hideModal() {
    this.modal.emit(false);
  }

  public feedBackModal = new EventEmitter<boolean>();

  public showFeedBackModal() {
    this.feedBackModal.emit(true);
  }

  public hideFeedBackModal() {
    this.feedBackModal.emit(false);
  }

  public commentModal = new EventEmitter<boolean>();
  public commentProduct = new EventEmitter<any>();

  public showCommentModal() {
    this.commentModal.emit(true);
  }

  public hideCommentModal() {
    this.commentModal.emit(false);
  }

  public ATCProduct = new EventEmitter<any>();
  // Modals ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
}

export interface Notify {
  id?: string;
  text: string;
  status: NotifyStatus;
  seconds?: number;
}

export type NotifyStatus = "success" | "danger" | "warning" | "info" | string;
