import {
  Component,
  OnInit,
  Input,
  ContentChild,
  ElementRef,
} from "@angular/core";
import { NgxMaskModule, IConfig } from "ngx-mask";

@Component({
  selector: "custom-input",
  templateUrl: "./custom-input.component.html",
  styleUrls: ["./custom-input.component.scss"],
})
export class CustomInputComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  @Input("labelFor") public for: string;
  @Input("labelHead") public label: string;
  @Input() public textarea: boolean = false;

  @ContentChild("customInput", {}) public input: ElementRef;
}
