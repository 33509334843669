import { ActivatedRoute, Router } from "@angular/router";
import { Component, HostListener, OnInit } from "@angular/core";
import { NotifyService } from "../../services/notify.service";
import { SharedService } from "../../services/shared.service";
import { CartService } from "../../../../common/utils/user/cart.service";
import { UserService } from "../../../../common/utils/user/user.service";
import { MallService } from "src/app/modules/mall/mall.service";

@Component({
  selector: "add-to-card-modal",
  templateUrl: "./add-to-card-modal.component.html",
  styleUrls: ["./add-to-card-modal.component.scss"],
})
export class AddToCardModalComponent implements OnInit {
  constructor(
    public NOTIFY: NotifyService,
    private ROUTER: Router,
    private ACTIVATED_ROUTER: ActivatedRoute,
    private MALL: MallService,
    public CART: CartService,
    public user: UserService
  ) {
    this.NOTIFY.ATCProduct.subscribe((res) => {
      if (res) {
        this.product = res;
        this.selected_slug = this.product.slug;
        this.modalStatus = true;
      }
    });
  }

  ngOnInit(): void {}

  public modalStatus: boolean;
  public product: any = null;

  @HostListener("document:keydown.escape", ["$event"])
  public onKeydownHandler(event: KeyboardEvent) {
    this.clearData();
  }

  public clearData() {
    this.product = null;
    this.modalStatus = false;
    this.product_count = 1;
    this.NOTIFY.ATCProduct.emit(null);
  }

  public reloadUrl() {
    const currentUrl = this.ROUTER.url;
    this.ROUTER.navigateByUrl(currentUrl, {
      skipLocationChange: true,
    });
  }

  public product_count: number = 1;
  public selected_slug: string = null;
  public changeSelectedCount(event: any) {
    this.product_count = Number(event);
  }

  public changeProdVariation(slug: string) {
    this.selected_slug = slug;
  }

  public addToCart() {
    this.NOTIFY.startSpinner();
    this.CART.edit(this.selected_slug).subscribe(
      (response) => {
        if (this.product_count > 1) {
          this.CART.updateCount(
            this.selected_slug,
            this.product_count
          ).subscribe(
            (res) => {
              this.NOTIFY.stopSpinner();
              this.NOTIFY.success(res);
              this.clearData();
              this.reloadUrl();
              this.MALL.setCounters();
            },
            (err) => {
              this.NOTIFY.stopSpinner();
              this.NOTIFY.error(err);
            }
          );
        } else {
          this.NOTIFY.stopSpinner();
          this.NOTIFY.success(response);
          this.clearData();
          this.MALL.setCounters();
        }
      },
      (err) => {
        this.NOTIFY.error(err);
        this.NOTIFY.stopSpinner();
      }
    );
  }

  public changeProduct(event: string) {
    let slug = event;
    this.NOTIFY.startSpinner();
    this.MALL.getProductDetails(slug).subscribe(
      (res) => {
        this.product = res.body;
        this.selected_slug = this.product.slug;
        this.product_count = 1;
        this.NOTIFY.stopSpinner();

        console.log(this.product);
      },
      (err) => {
        this.NOTIFY.stopSpinner();
        this.NOTIFY.error(err);
      }
    );
  }

  public addToReminder(id: number) {
    if (!this.user.isAuthenticated) {
      this.ROUTER.navigate(["/auth/login"]);
    }else{
      this.NOTIFY.startSpinner();
      this.user.addToReminder(id).subscribe(
        (RESPONSE) => {
          this.NOTIFY.stopSpinner();
          this.NOTIFY.success(RESPONSE);
          this.MALL.setCounters();
        },
        (err) => {
          this.NOTIFY.stopSpinner();
          this.NOTIFY.error(err);
        }
      );;
    }
  }
}
