import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-reviews-popup",
  templateUrl: "./reviews-popup.component.html",
  styleUrls: ["./reviews-popup.component.scss"],
})
export class ReviewsPopupComponent implements OnInit {
  @Input() public isOpen = true;
  @Output() public popupStatus = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public closePopup(): void {
    this.isOpen = false;
    this.popupStatus.emit(this.isOpen);
  }
}
