<section
  class="fullwidth_carousel container"
  *ngIf="carousel_state.items !== null"
>
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let item of carousel_state.items">
      <ng-template carouselSlide *ngIf="item?.image?.length">
        <div
          class="carousel_item"
          (click)="onBannerClick(item.url)"
          [style.background-image]="'url(' + item.image + ')'"
        >
          <!-- <div class="carousel_content" >
          <h3 class="h3_demi">{{ item.heading }}</h3>
          <p class="body_l_reg">{{ item.desc }}</p>
          <h4 class="h4_demi">{{ item.price }}&#8380;</h4>
        </div> -->

          <!-- <div class="carousel_image">
          <img [src]="item.image_url" alt="" />
        </div> -->
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</section>

<section
  class="carousel_for_mobile container"
  *ngIf="carousel_state.mobile_items !== null"
>
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let item of carousel_state.mobile_items">
      <ng-template carouselSlide *ngIf="item?.image?.length">
        <div
          class="carousel_item"
          (click)="onBannerClick(item.url)"
          [style.background-image]="'url(' + item.image + ')'"
        >
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</section>