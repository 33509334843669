import { BlogsRoutingModule } from "./blogs-routing.module";
import { FormsModule } from "@angular/forms";
import { BlogsComponent } from "./blogs.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BlogListComponent } from "./pages/blog-list/blog-list.component";
import { BlogDetailComponent } from "./pages/blog-detail/blog-detail.component";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { ClickOutsideModule } from "ng-click-outside";
import { HttpClientModule } from "@angular/common/http";
import { BlogCartComponent } from "./components/blog-cart/blog-cart.component";
import { BlogCarouselComponent } from "./components/blog-carousel/blog-carousel.component";
import { CarouselModule } from "ngx-owl-carousel-o";

@NgModule({
  declarations: [
    BlogsComponent,
    BlogListComponent,
    BlogDetailComponent,
    BlogCartComponent,
    BlogCarouselComponent,
  ],
  imports: [
    BlogsRoutingModule,
    CommonModule,
    TranslateModule,
    SharedModule,
    ClickOutsideModule,
    FormsModule,
    HttpClientModule,
    CarouselModule,
  ],
  exports: [BlogCarouselComponent, BlogCartComponent],
})
export class BlogsModule {}
