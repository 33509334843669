<div class="product_comments_block">
  <h5 class="h5_demi">
    {{ "Reviews" | translate }} ({{ product.comment_summary.total }})
  </h5>
  <div class="row">
    <div class="comments">
      <div
        class="comment-item"
        *ngFor="let comment of comments?.data; let i = index"
      >
        <div class="user-name">
          <h5 class="body_s_med">{{ comment.front_user.name }}</h5>
          <time>{{ comment.commented_at }}</time>
        </div>
        <div class="user-comment">
          <div class="stars_block mb-12px">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
              <div class="star">
                <i
                  class="fa-star"
                  [ngClass]="{
                    fas: comment.stars >= star,
                    fal: comment.stars < star
                  }"
                ></i>
              </div>
            </ng-container>
          </div>
          <p class="body_s_reg">{{ comment.comment_body }}</p>
          <div class="like-dislike-block">
            <div
              class="like"
              [ngClass]="{ clickable: !comment.liked }"
              (click)="likeProduct(i)"
            >
              <i
                class="fa-thumbs-up"
                [ngClass]="{ fas: comment.liked, fal: !comment.liked }"
              ></i>
              <span>{{ comment.like_count }}</span>
            </div>
            <div
              class="dislike"
              [ngClass]="{ clickable: !comment.disliked }"
              (click)="dislikeProduct(i)"
            >
              <i
                class="fa-thumbs-down"
                [ngClass]="{ fas: comment.disliked, fal: !comment.disliked }"
              ></i>
              <span>{{ comment.dislike_count }}</span>
            </div>
          </div>

          <div class="store_reply" *ngIf="comment?.reply?.reply_text">
            <div class="store_name body_s_med">
              {{ comment.reply.store_name }}
              <i class="fas fa-check-circle"></i>
            </div>
            <div class="reply_date mY-8px body_s_reg">
              {{ comment.reply.replied_at }}
            </div>
            <div class="store_text body_s_reg">
              {{ comment.reply.reply_text }}
            </div>
          </div>
        </div>
      </div>

      <pagination-component
        *ngIf="comments?.data"
        [position]="'center'"
        [links]="comments.meta.links"
      ></pagination-component>
    </div>

    <div class="ratings">
      <div class="ratings_top">
        <div class="stars_block">
          <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
            <div class="star">
              <i
                class="fa-star"
                [ngClass]="{
                  fas: rating.average >= star,
                  fal: rating.average < star
                }"
              ></i>
            </div>
          </ng-container>
        </div>
        <div class="h6_med">{{ rating.average }}/5.0</div>
      </div>
      <!-- 5 stars -->
      <div class="rating-points">
        <span class="body_s_reg">5 {{ "star" | translate }}</span>
        <div class="progress-bar">
          <div class="progress" [style.width.%]="rating.five_star"></div>
        </div>
        <span class="body_s_reg rating-percent">{{ rating.five_star }}%</span>
      </div>
      <!-- 4 stars -->
      <div class="rating-points">
        <span class="body_s_reg">4 {{ "star" | translate }}</span>
        <div class="progress-bar">
          <div class="progress" [style.width.%]="rating.four_star"></div>
        </div>
        <span class="body_s_reg rating-percent">{{ rating.four_star }}%</span>
      </div>
      <!-- 3 stars -->
      <div class="rating-points">
        <span class="body_s_reg">3 {{ "star" | translate }}</span>
        <div class="progress-bar">
          <div class="progress" [style.width.%]="rating.three_star"></div>
        </div>
        <span class="body_s_reg rating-percent">{{ rating.three_star }}%</span>
      </div>
      <!-- 2 stars -->
      <div class="rating-points">
        <span class="body_s_reg">2 {{ "star" | translate }}</span>
        <div class="progress-bar">
          <div class="progress" [style.width.%]="rating.two_star"></div>
        </div>
        <span class="body_s_reg rating-percent">{{ rating.two_star }}%</span>
      </div>
      <!-- 1 stars -->
      <div class="rating-points">
        <span class="body_s_reg">1 {{ "star" | translate }}</span>
        <div class="progress-bar">
          <div class="progress" [style.width.%]="rating.one_star"></div>
        </div>
        <span class="body_s_reg rating-percent">{{ rating.one_star }}%</span>
      </div>

      <div *ngIf="!rating?.commented">
        <button class="btn btn-fill btn-med" (click)="addReview()">
          {{ "Add review" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
