import { EventEmitter, Injectable } from "@angular/core";

import { AuthService } from "src/app/modules/auth/auth.service";
import { UserData } from "../../../modules/auth/auth.models";
import {HttpClient} from "@angular/common/http";
import {generateHeaderConfig} from "../functions/generateHeaderConfig.function";
import {MallService} from "../../../modules/mall/mall.service";
import {NotifyService} from "../../../modules/shared/services/notify.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public token: string;
  public UUID: string;
  public data: UserData;
  public id: number | boolean;
  public isAuthenticated: boolean;

  constructor(
    private HTTP: HttpClient,
    public AUTH: AuthService,
  ) {
    this.updateValues();
  }

  public updateValues() {
    const user = JSON.parse(localStorage.getItem("front_user"));
    const token = localStorage.getItem("front_access_token");

    this.data = user ? user : null;
    this.id = user ? user.id : null;
    this.token = token ? token : "";
    this.isAuthenticated = user ? true : false;
    this.userDATA.emit(this.data);
  }

  public logout(): void {
    if (localStorage.getItem("front_access_token")) {
      this.AUTH.logOutUser().subscribe(
        (RESPONSE) => {
          localStorage.removeItem("UUID");
          localStorage.removeItem("front_user");
          localStorage.removeItem("front_access_token");
          this.updateValues();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  public updateUser(data) {}

  public userDATA = new EventEmitter();

  public addToReminder(id: number) {
    return this.HTTP.post<any>('remind/' + id, null,{
      ...generateHeaderConfig(),
    });
  }
}
