<owl-carousel-o [options]="customOptions" class="category-carousel">
  <ng-template carouselSlide *ngFor="let slide of slides">
    <a
      class="category-carousel-item"
      style="background-image: url('{{ slide }}');"
    >
      <!-- <a
      [routerLink]="['/product-details', slide.id]"
      [queryParams]="{ path: slide.path }"
      class="category-carousel-item"
      style="background-image: url('{{ slide.image_url }}');"
    > -->
      <!-- <div class="cc-item-content">
        <h4
          class="h4_demi"
          [ngClass]="{
            h6_demi: deviceType == 'mobile'
          }"
          [ngStyle]="{ color: slide.title.color }"
        >
          {{ slide.title.label }}
        </h4>
        <p
          class="body_m_reg"
          [ngClass]="{
            body_xs_reg: deviceType == 'mobile'
          }"
          [ngStyle]="{ color: slide.sub_title.color }"
        >
          {{ slide.sub_title.label }}
        </p>
        <h5
          class="h5_demi"
          [ngClass]="{
            body_s_demi: deviceType == 'mobile'
          }"
          [ngStyle]="{ color: slide.price.color }"
        >
          {{ slide.price.label }}
        </h5>
      </div> -->
    </a>
  </ng-template>
</owl-carousel-o>
