import { Subscription } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { MallService } from "../../mall.service";

@Component({
  selector: "app-search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.scss"],
})
export class SearchResultsComponent implements OnInit {
  constructor(private MALL: MallService) {
    this.subscription = this.MALL.searchResultsBody.subscribe((RESPONSE) => {
      if (RESPONSE) {
        this.links = RESPONSE.meta.links;
        this.products = RESPONSE.data;
        this.total = RESPONSE.meta.total;
      }
    });
  }

  subscription: Subscription = null;
  ngOnDestroy(): void {
    this.MALL.searchResultsBody.emit(null);
    this.subscription.unsubscribe();
  }

  links: any = null;
  products: any = null;
  total: number = 0;

  ngOnInit(): void {}
}
