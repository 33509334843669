import { Component, Input, OnInit } from "@angular/core";
import { MallService } from "../../mall.service";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-brand-slider",
  templateUrl: "./brand-slider.component.html",
  styleUrls: ["./brand-slider.component.scss"],
})
export class BrandSliderComponent implements OnInit {
  public customOptions: OwlOptions = {};

  @Input() public slides: [];

  constructor(private mallService: MallService) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      navSpeed: 700,
      navText: ["", ""],
      nav: false,
      margin: 16,
      items: 1,
    };
  }
}
