import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Event, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { generateTitle } from "src/app/common/utils/functions/generateTitle.function";
import { SharedService } from "src/app/modules/shared/services/shared.service";
import { MallService } from "../../mall.service";

@Component({
  selector: "app-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.scss"],
})
export class StoreComponent implements OnInit {
  constructor(
    private MALL: MallService,
    private Title: Title,
    private router: Router,
    private SHARED: SharedService
  ) {
    this.detectDevice();
    this.res = this.SHARED.staticKeys;
    this.subs2 = this.SHARED.staticData.subscribe((res) => {
      if (res) {
        this.res = res;
        this.fillData();
      }
    });
    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.fillData();
      }
    });
  }

  private res: any = null;

  title_up: string = null;
  title: string = null;
  text: string = null;
  how_works: any[] = [];
  advantages: any[] = [];
  reg_url: string = null;
  signup_url: string = null;

  public subscription: Subscription = null;
  public subs2: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subs2.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    window.scroll(0, 0);
    this.loadBanners();
    this.Title.setTitle(generateTitle("Mağazalar"));
    this.getCounters();
  }

  public fillData() {
    this.text = this.res?.page_store_description;
    this.title = this.res?.page_store_title;
    this.title_up = this.res?.page_store_title_up;
    // how works
    this.how_works = [];
    for (let i = 1; i < 5; i++) {
      let obj = {
        title: "",
        image: "",
        description: "",
      };
      obj.title = this.res[`page_store_how_works_${i}_title`];
      obj.description = this.res[`page_store_how_works_${i}_description`];
      obj.image = this.SHARED.howWorks[i - 1].image;
      this.how_works.push(obj);
    }
    // advantages
    this.advantages = [];
    for (let i = 1; i < 7; i++) {
      let obj = {
        title: "",
        icon: "",
        description: "",
      };
      obj.title = this.res[`page_store_pros_${i}_title`];
      obj.description = this.res[`page_store_pros_${i}_description`];
      obj.icon = this.SHARED.advantages[i - 1].icon;
      this.advantages.push(obj);
    }
  }

  public localState = {
    brands_items: {
      items: [],
      max_item_count: 8,
    },
  };

  public loadBanners() {
    this.MALL.getBrands().subscribe((RESPONSE) => {
      this.localState.brands_items.items = RESPONSE.body;
    });
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }

  public counterState: {
    store?: any;
    category?: any;
    product?: any;
    view?: any;
  } = {};
  public getCounters() {
    this.MALL.getStoreCounts().subscribe((res) => {
      this.counterState.store = res.body.original.stores;
      this.counterState.category = res.body.original.categories;
      this.counterState.product = res.body.original.products;
      this.counterState.view = res.body.original.views;
    });
  }
}
