import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "brands-carousel",
  templateUrl: "./brands-carousel.component.html",
  styleUrls: ["./brands-carousel.component.scss"],
})
export class BrandsCarouselComponent implements OnInit {
  constructor(private ROUTER: Router) {
    this.detectDevice();
  }

  @Input("items") public items;
  @Input("max_item_count") public max_item_count;
  @Input("show_all_url") public show_all_url;
  @Input("small_size") public small_size: boolean;

  public fillItems() {
    this.carousel_state.items = this.items;
    if (this.max_item_count) {
      this.carousel_state.max_item_count = this.max_item_count;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.fillItems();
  }

  ngOnInit() {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      lazyLoad: true,
      dots: false,
      navSpeed: 700,
      navText: ["", ""],
      nav: false,
      margin: 16,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 3,
        },
        940: {
          items: 4,
        },
        1024: {
          items: this.carousel_state.max_item_count,
        },
      },
    };
    if (this.small_size) {
      this.customOptions.responsive = {
        0: {
          items: 1,
        },
        400: {
          items: 3,
        },
        740: {
          items: 6,
        },
        940: {
          items: 7,
        },
        1024: {
          items: this.carousel_state.max_item_count,
        },
      };
      this.customOptions.autoplay = true;
      this.customOptions.autoplayHoverPause = true;
      this.customOptions.autoplaySpeed = 300;
      this.customOptions.autoplayTimeout = 1000;
    }
  }

  public carousel_state = {
    items: null,
    max_item_count: 8,
  };

  public customOptions: OwlOptions = {};

  public toProductInner(event: any, path: any) {
    this.ROUTER.navigate(path);
  }

  public navigateToBrandsInner(id) {
    this.ROUTER.navigate(["/brand", id]);
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
