<div class="each_filter">
  <div class="each_filter_heading">
    <strong class="body_s_demi">{{ state.filter_title }}</strong>
  </div>
  <filter-input
    [name]="state.label"
    [value]="state.input_value"
    [placeholder]="'Search' | translate"
    (inputOutput)="filterOptions($event)"
  ></filter-input>
  <div class="options_div">
    <ng-container *ngFor="let opt of state.options">
      <div class="each_opt" *ngIf="opt['visibility'] == true">
        <custom-checkbox
          [checkStatus]="opt['check']"
          [usability]="true"
          (checkboxValue)="checkBoxEvent($event, opt.value)"
        >
          <div #customCheckbox>
            <div class="filter_checkbox">
              <div
                class="color_block"
                [ngStyle]="{ backgroundColor: opt.color }"
                *ngIf="opt.color != null"
              ></div>
              <p class="label body_s_reg">
                {{ opt.label }}
                <!-- <span class="grey">{{ opt.value }}</span> -->
              </p>
            </div>
          </div>
        </custom-checkbox>
      </div>
    </ng-container>
  </div>
</div>
