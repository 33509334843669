<ng-container>
  <div
    *ngIf="option"
    class="each_opt"
    [ngClass]="{
      active: option.selected == true,
      other: option.color == false,
      color: option.color == true,
      noStock: option.stock == 0
    }"
    [style.backgroundColor]="option.value"
    (click)="clickOpt()"
  >
  <div *ngIf="option.stock==0" data-tooltip="Mövcud deyildir" [ngClass]="tooltipType"></div>
    <span class="body_s_med" *ngIf="option.color == false">{{
      option.value
    }}</span>

    <div class="color_tooltip body_xs_reg" *ngIf="option.color == true">
      {{ option.title }}
    </div>
  </div>
</ng-container>

<!-- unClickable: option.has_stock == false -->

<!-- [routerLink]="['/product-details', option.product_id]" -->
