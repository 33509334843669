import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { variables } from "src/environments/variables";
import { UserService } from "../../../../../common/utils/user/user.service";

@Component({
  selector: "signin-block",
  templateUrl: "./signin-block.component.html",
  styleUrls: ["./signin-block.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SigninBlockComponent implements OnInit {
  constructor(public user: UserService, private router: Router) {
    this.detectDevice();
  }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.state.firstname =
      this.user?.data?.firstname != null ? this.user?.data?.firstname : null;
    this.state.lastname =
      this.user?.data?.lastname != null ? this.user?.data?.lastname : null;
    this.state.notification_count ? this.user?.data?.notification_count : null;
    this.subscribe = this.user.userDATA.subscribe((res) => {
      this.state.firstname = res?.firstname;
      this.state.lastname = res?.lastname;
      this.state.notification_count = res?.notification_count;
    });
  }

  private subscribe: Subscription;

  @Input("name") public name: string;

  public state = {
    toggleStatus: false,
    username: "def_username",
    notification_count: null,
    firstname: null,
    lastname: null,
  };

  toggleStatus(event) {
    this.state.toggleStatus = !this.state.toggleStatus;
  }

  onClickedOutside(event) {
    this.state.toggleStatus = false;
  }

  goProfile() {
    if (this.deviceType == "desktop") {
      this.router.navigate(["profile", "info"]);
    } else if (this.deviceType == "mobile") {
      this.router.navigate(["profile"]);
    }

    this.state.toggleStatus = false;
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }

  public redirectProfile(event: any) {
    this.user.isAuthenticated == true
      ? this.goProfile()
      : this.router.navigate(["auth/login"]);
  }
}
