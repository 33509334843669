import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-profile-payments",
  templateUrl: "./profile-payments.component.html",
  styleUrls: ["./profile-payments.component.scss"],
})
export class ProfilePaymentsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
