import { Component, OnInit } from "@angular/core";
import { ProfileService } from "../../../../../common/utils/user/profile.service";
import { ActivatedRoute } from "@angular/router";
import { NotifyService } from "src/app/modules/shared/services/notify.service";

@Component({
  selector: "app-profile-orders-products",
  templateUrl: "./profile-orders-products.component.html",
  styleUrls: ["./profile-orders-products.component.scss"],
})
export class ProfileOrdersProductsComponent implements OnInit {
  public id: number;
  public order: any;
  public state: boolean;
  public isPopupOpen = false;

  constructor(
    private profile: ProfileService,
    private activatedRoute: ActivatedRoute,
    public NOTIFY: NotifyService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.activatedRoute.paramMap.subscribe((path) => {
      this.id = Number(path.get("id"));
      this.updateOrder();
    });
  }

  private updateOrder(): void {
    this.profile.order(this.id).subscribe((res) => {
      this.state = true;
      this.order = res.body;
    });
  }

  public openPopup(): void {
    this.isPopupOpen = true;
  }

  public setPopupStatus(status): void {
    this.isPopupOpen = status;
  }

  public showModal(product: number) {
    this.NOTIFY.showCommentModal();
    this.NOTIFY.commentProduct.emit(product);
  }
}
