import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../../../common/utils/user/user.service";
import { ProfileService } from "../../../../../common/utils/user/profile.service";

@Component({
  selector: "app-profile-security",
  templateUrl: "./profile-security.component.html",
  styleUrls: ["./profile-security.component.scss"],
})
export class ProfileSecurityComponent implements OnInit {
  constructor(public user: UserService, public profile: ProfileService) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.profile.devices().subscribe((res) => {
      this.sessions = res.body;
      this.detectDeviceType();
    });
  }

  detectDeviceType() {
    this.sessions?.map((session) => {
      let platform = session.platform.toLowerCase();

      switch (platform) {
        case "windows":
        case "mac":
        case "linux":
        case "debian":
        case "ubuntu":
          session.isDesktop = true;
          break;
        case "android":
        case "ios":
        case "apple":
          session.isMobile = true;
          session.isDesktop = false;
          break;

        default:
          break;
      }
    });
  }

  public sessions;

  public triggerSession(index) {
    if (this.sessions[index].active) {
      this.sessions[index].active = false;
    } else {
      this.sessions[index].active = true;
    }
  }
}
