import { generateHeaderConfig } from "src/app/common/utils/functions/generateHeaderConfig.function";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Feedback, reqComment } from "../shared.model";
import { Router } from "@angular/router";
import { NotifyService } from "./notify.service";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(
    private http: HttpClient,
    private ROUTER: Router,
    private NOTIFY: NotifyService
  ) {
    this.setPreviousPage.subscribe((RESPONSE) => {
      this.previousPage = RESPONSE;
    });
    this.setPreviousURL.subscribe((RESPONSE) => {
      this.previousUrl = RESPONSE;
    });
  }

  public sharedFeatures = [
    {
      title: "Məxfilik",
      text: "Şəxsi məlumatlarınızın məxfi saxlanılmasına zəmanət veririk",
      icon: "fal fa-shield-check",
      title_color: "#d1679a",
      text_color: "#2b77ab",
      background_color: "#d838da",
    },
    {
      title: "Çatdırılma",
      text: "Sürətli, planlaşdırılmış və güvənli çatdırılma xidməti göstəririk",
      icon: "fal fa-car",
      title_color: "#353ce4",
      text_color: "#18fd12",
      background_color: "#47c928",
    },
    {
      title: "Çoxlu məhsul kateqoriyası",
      text: "Bir çox müxtəlif növ kateqoriya üzrə məhsul alışı və satışı təklif edirik",
      icon: "fal fa-th-large",
      title_color: "#bcb7ed",
      text_color: "#b12a29",
      background_color: "#de4b4b",
    },
    {
      title: "24 saat dəstək",
      text: "Mağazalarımıza və müştərilərimizə günün istənilən saatında dəstək göstəririk\n",
      icon: "fal fa-comment-dots",
      title_color: "#5784ac",
      text_color: "#d4fa25",
      background_color: "#057221",
    },
    {
      title: "Şəkil ilə axtarış",
      text: "Məhsulların şəkillər üzrə daha rahat və sürətli axtarılması ilə təmin olunub\n",
      icon: "fal fa-camera",
      title_color: "#23eac8",
      text_color: "#e637ee",
      background_color: "#49240c",
    },
    {
      title: "Endirim kampaniyaları",
      text: "Mütəmadi olaraq endirim kompaniyalarından yararlanmağı təklif edirik",
      icon: "fal fa-gift",
      title_color: "#917776",
      text_color: "#83f421",
      background_color: "#c2f350",
    },
    {
      title: "Alış-verişdən bonuslar",
      text: "Alış-veriş zamanı bonuslar əldə edərək məhsulları ödənişsiz əldə etmək imkanı mövcuddur",
      icon: "fal fa-smile",
      title_color: "#def361",
      text_color: "#82019b",
      background_color: "#a39b4c",
    },
    {
      title: "Güvənli ödəmə",
      text: "Ödənişlər zamanı təhlükəsiz və güvənli ödəmə əməliyyatına zəmanət veririk",
      icon: "fal fa-credit-card",
      title_color: "#47d32c",
      text_color: "#9bf3be",
      background_color: "#af004d",
    },
  ];

  public howWorks = [
    {
      image: "assets/images/Login-rafiki.svg",
    },
    {
      image: "assets/images/upload-rafiki.svg",
    },
    {
      image: "assets/images/shopping-rafiki.svg",
    },
    {
      image: "assets/images/Analysis-qrafiki.svg",
    },
  ];

  public advantages = [
    {
      icon: "fab fa-app-store",
    },
    {
      icon: "fal fa-wallet",
    },
    {
      icon: "fal fa-chart-area",
    },
    {
      icon: "fal fa-chart-pie-alt",
    },
    {
      icon: "fal fa-truck-loading",
    },
    {
      icon: "fal fa-headset",
    },
  ];

  public sendFeedback(body: Feedback): Observable<any> {
    return this.http.post<any>(`feedback`, body, {
      ...generateHeaderConfig(),
    });
  }

  public sendComment(product_id: number, body: reqComment): Observable<any> {
    return this.http.post<any>(`comment/${product_id}`, body, {
      ...generateHeaderConfig(),
    });
  }

  public setPreviousPage = new EventEmitter<string>();
  public setPreviousURL = new EventEmitter<string>();
  public previousUrl: string = null;
  public previousPage: string = null;

  public relocateBack() {
    switch (this.previousPage) {
      case "basket":
        this.ROUTER.navigate(["/basket/checkout"]);
        break;
      case "installment":
        this.ROUTER.navigateByUrl(this.previousUrl);
        break;
      case "favorite":
        this.ROUTER.navigate(["/favorites"]);
        break;

      default:
        this.ROUTER.navigate([""]);
        break;
    }
  }

  public staticData = new EventEmitter<any>();
  public staticKeys: any = null;

  public getStaticData(): Observable<any> {
    return this.http.get<any>(`setting?paired=true`, {
      ...generateHeaderConfig(),
    });
  }

  public fetchStatics() {
    this.staticData.emit(JSON.parse(localStorage.getItem("static")));
    this.staticKeys = JSON.parse(localStorage.getItem("static"));
    this.getStaticData().subscribe(
      (res) => {
        this.staticData.emit(res.body);
        this.staticKeys = res.body;
        localStorage.setItem("static", JSON.stringify(res.body));
      },
      (err) => {
        this.NOTIFY.error(err);
      }
    );
  }
}
