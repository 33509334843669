<section class="privacy_top container">
  <div class="privacy_top_content">
    <span class="overline" [innerHTML]="title_up"></span>
    <h2
      class="h2_demi"
      [ngClass]="{
        h4_demi: deviceType == 'mobile'
      }"
      [innerHTML]="title"
    ></h2>
    <!-- <p class="body_m_med">Last update: 28 noyabr 2020 ci il</p> -->
  </div>
</section>

<section class="main_content container">
  <aside class="navigation" *ngIf="deviceType == 'desktop'">
    <ng-container *ngIf="generatedLinks == 1">
      <a
        routerLinkActive="active"
        [routerLink]="['/static/payment']"
        class="body_m_med hash_link_btn"
        >{{ "Payment" | translate }}</a
      >
      <a
        routerLinkActive="active"
        [routerLink]="['/static/return']"
        class="body_m_med hash_link_btn"
        >{{ "Return" | translate }}</a
      >
      <a
        routerLinkActive="active"
        [routerLink]="['/static/warranty']"
        class="body_m_med hash_link_btn"
        >{{ "Warranty" | translate }}</a
      >
      <a
        routerLinkActive="active"
        [routerLink]="['/static/delivery']"
        class="body_m_med hash_link_btn"
        >{{ "Delivery" | translate }}</a
      >
      <a
        routerLinkActive="active"
        [routerLink]="['/static/security']"
        class="body_m_med hash_link_btn"
        >{{ "Security" | translate }}</a
      >
      <a
        routerLinkActive="active"
        [routerLink]="['/static/user-terms']"
        class="body_m_med hash_link_btn"
        >{{ "end_customer_agreement" | translate }}</a
      >
    </ng-container>

    <ng-container *ngIf="generatedLinks == 2">
      <a
        routerLinkActive="active"
        [routerLink]="['/static/privacy-policy']"
        class="body_m_med hash_link_btn"
        >{{ "Privacy policy" | translate }}</a
      >
      <a
        routerLinkActive="active"
        [routerLink]="['/static/cookies']"
        class="body_m_med hash_link_btn"
        >{{ "Cookies" | translate }}</a
      >
      <!-- <a [routerLink]="['static/site-map']" class="body_s_reg">{{
          "Site map" | translate
        }}</a> -->
    </ng-container>
  </aside>
  <div class="text_side">
    <!-- <div
      [className]="'each_content ' + 'each_content_' + i"
      *ngFor="let text of content.texts; let i = index"
    >
      <h5 class="h5_demi">{{ content.titles[i] }}</h5>
      <p class="body_s_reg">{{ text }}</p>
    </div> -->
    <article class="editor_content" [innerHTML]="text"></article>
  </div>
</section>
