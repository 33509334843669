<div class="container" *ngIf="state">
  <app-photo-gallery
    [images]="product.images.opened"
    [current]="selectedImageIndex"
    [isOpened]="isGalleryOpened"
    (modalStatus)="checkModalStatus($event)"
  >
  </app-photo-gallery>
  <app-breadcrump [category]="breadcrump"></app-breadcrump>

  <div>
<!--      <div class="stock_badge" *ngIf="product?.stock == 0">-->
<!--        <span-->
<!--          class="body_m_med"-->
<!--          [ngClass]="{ body_xs_reg: deviceType == 'mobile' }"-->
<!--          >{{ "Out of stock" | translate }}</span-->
<!--        >-->
<!--      </div>-->

    <div class="product-detail">
      <div>
        <button *ngIf="product.images.gallery.length > 3" class="scroll-left_img" (click)="scrollImgUp()">
          <img src="assets/icons/arrow-up.svg" alt="">
        </button>
        <div
          class="product-photos"
          *ngIf="product.images.gallery && deviceType == 'desktop'"
        >
        <ng-container *ngFor="let photo of product.images.gallery; let i = index">
          <div class="product-photo-item" (mouseover)="onGalleryClick(photo)">
            <img [src]="photo" />
            <!-- <ng-container *ngIf="i == 3 || i == product.images.gallery.length - 1">
              <div class="more-photo" *ngIf="product.images.gallery.length - 4 > 0">
                +
                {{
                  product.images.gallery.length - 4 < 0
                    ? ""
                    : product.images.gallery.length - 4
                }}
              </div>
            </ng-container> -->
          </div>
        </ng-container>
      </div>
      <button *ngIf="product.images.gallery.length > 3" class="scroll-left_img" (click)="scrollImgDown()">
        <img src="assets/icons/arrow-down.svg" alt="">
      </button>
    </div>
      <div class="product-detail-photo">
        <div class="product-card-carousel">
          <div class="card-carousel-item" *ngIf="mainImg">
            <!-- <main-image-carousel
              [items]="product.images.opened"
            ></main-image-carousel> -->
            <lib-ngx-image-zoom
            [thumbImage]=mainImg
            [fullImage]=mainImg
          ></lib-ngx-image-zoom>
          </div>
        </div>
      </div>
      <div class="product-detail-desc">
        <strong class="h4_med" [ngClass]="{ h6_med: deviceType == 'mobile' }">{{
          product.name
        }}</strong>
        <div class="product-features">
          <div class="product-info">
            <div class="rating-share-block">
              <div
                class="share-product"
                (clickOutside)="hideShareBlock()"
                [clickOutsideEnabled]="true"
              >
                <button
                  class="share-product_toggler body_xs_reg"
                  (click)="toggleShareBlock()"
                >
                  {{ "Share product" | translate }}
                </button>

                <div
                  class="tooltip tooltip-share"
                  [ngClass]="{ active: shareToggler }"
                >
                  <button shareButton="facebook">
                    <i class="fab fa-facebook"></i>
                  </button>
                  <button shareButton="whatsapp">
                    <i class="fab fa-whatsapp"></i>
                  </button>
                  <button shareButton="telegram">
                    <i class="fab fa-telegram"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="product-code">
              <span>{{ "Product code" | translate }}:</span>
              {{ product.code }}
            </div>
          </div>

          <div class="product-price">
            <div class="product-price-top">
              <p class="body_s_med">{{ "Price" | translate }}</p>
            </div>
            <div class="product-price-bottom">
              <div class="old-price body_l_reg" *ngIf="product.discount > 0">
                {{ product.price.original }} &#8380;
              </div>
              <div
                class="label-product label-percent"
                *ngIf="product.discount > 0"
              >
                {{ product.discount }}%
              </div>
              <strong
                class="h4_demi"
                [ngClass]="{ h6_med: deviceType == 'mobile' }"
                >{{
                  product.discount > 0
                    ? product.price.discounted
                    : product.price.original
                }}
                &#8380;</strong
              >
            </div>
          </div>

          <ng-container *ngIf="filterable">
            <div class="product-other-desc" *ngFor="let item of filterable">
              <div class="feature-title">{{ item.title }}</div>
              <div class="feature-desc">
                <ng-container *ngFor="let opt of item.values">
                  <option-link
                    *ngIf="opt.slug"
                    [option]="opt"
                    [current]="current_variation"
                    (onFilterableSelect)="changeProduct($event)"
                  >
                  </option-link>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <div class="product-other-desc" *ngIf="product.country">
            <div class="feature-title">{{ "Producing country" | translate }}</div>
            <div class="feature-desc">{{ product.country }}</div>
          </div>
          <div class="product-other-desc" *ngIf="product.store.id">
            <div class="feature-title">{{ "Stores" | translate }}</div>
            <div class="feature-desc">
              <a [routerLink]="['/brand', product.store.id]">{{
                product.store.name
              }}</a>
            </div>
          </div>
        </div>
        <div class="buy-or-add">
          <div class="buy-now-block" *ngIf="product.installment != 0">
            <img src="assets/images/birkart.svg" />
            <p class="body_m_reg">
              {{ "Pay in installments with BirKart" | translate }}
            </p>
            <button
              class="btn btn-bordered"
              [ngClass]="{ disabled: product?.stock == 0 }"
              (click)="showIntallment(product.slug)"
            >
              {{ "Buy now" | translate }}
            </button>
          </div>
          <div class="links_block">
            <div>
              <a
                *ngIf="product.stock != 0"
                class="btn btn-sm btn-fill to_cart_btn"
                (click)="addToCart(product.slug)"
                [ngClass]="{ active: product.in_cart == true }"
              >
                <ng-container *ngIf="product.in_cart == false && product.stock != 0">
                  {{ "add_to_cart" | translate }}
                </ng-container>
                <ng-container *ngIf="product.in_cart == true">
                  {{ "already_in_cart" | translate }}
                </ng-container>
              </a>

              <a
                *ngIf="product.stock == 0"
                class="btn btn-sm btn-fill to_cart_btn"
                (click)="addToReminder(product.id)"
                [ngClass]="{ active: product.in_reminder == true }"
              >
                <ng-container>
                  {{ "let_us" | translate }}
                </ng-container>
              </a>
              <!-- add product to basket -->
              <!-- <a class="btn btn-sm btn-add" href="">1 məhsulu al <span>+1</span></a> -->
              <!-- ./add product to basket -->
            </div>

            <a
              class="to_favorites"
              (click)="addToFavorites(product.slug)"
              [ngClass]="{ active: product.in_favorite == true }"
            >
              <i class="fal fa-heart"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="product-nav-list">
    <ul>
      <li
        *ngIf="product.description"
        [ngClass]="{ active: activeNavlink == 2 }"
        (click)="scrollTo('.product-description-block', 2)"
      >
        <a>{{ "Description" | translate }}</a>
      </li>
      <li
        *ngIf="specifications?.items"
        [ngClass]="{ active: activeNavlink == 3 }"
        (click)="scrollTo('.features-group', 3)"
      >
        <a>{{ "Specifications" | translate }}</a>
      </li>
      <li
        *ngIf="product?.equipment"
        [ngClass]="{ active: activeNavlink == 4 }"
        (click)="scrollTo('.equipment-group', 4)"
      >
        <a>{{ "Equipments" | translate }}</a>
      </li>
      <li
        *ngIf="top_hastags?.length > 0"
        [ngClass]="{ active: activeNavlink == 5 }"
        (click)="scrollTo('.hashtag-group', 5)"
      >
        <a>{{ "Hastags" | translate }}</a>
      </li>
      <!-- <li (click)="scrollTo('.product-comments-block')"><a>Rəylər(305)</a></li> -->
    </ul>
    <div class="product-small-view">
      <div class="fixed-basket" *ngIf="state && deviceType == 'desktop'">
        <div class="product-image product-info">
          <img src="{{ product.images.carousel[0] }}" alt="" />
          <div class="d-f ai-s" style="flex-direction: column; text-align: left; margin-right: 10px;">
            <h6 class="h6_med" style="font-size: 14px; line-height: normal;">{{ product.name }}</h6>
            <div class="fcs" *ngIf="product.discount > 0">
              <small class="line-through body_xs_reg" style="line-height: normal;"
                >{{ product.price.original }} &#8380;</small
              >
              <div class="label-product label-percent ml-8px">
                {{ product.discount }}%
              </div>
            </div>
            <strong class="h6_demi"  style="line-height: normal;"
              >{{
                product.discount > 0
                  ? product.price.discounted
                  : product.price.original
              }}
              &#8380;</strong>
          </div>
        </div>
        <div class="d-f" *ngIf="filterable">
          <div class="product-other-desc" *ngFor="let item of filterable">
            <div class="feature-desc">
              <ng-container *ngFor="let opt of item.values">
                <option-link
                  *ngIf="opt.slug && opt.selected"
                  [option]="opt"
                  [current]="current_variation"
                  (onFilterableSelect)="changeProduct($event)"
                  [tooltipType]="tooltipBottom"
                >
                </option-link>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="buy-now-block" *ngIf="product.installment != 0">
          <img src="assets/images/birkart.svg" class="small-preview_img" />
<!--          <p class="body_m_reg">-->
<!--            {{ "Pay in installments with BirKart" | translate }}-->
<!--          </p>-->
        </div>
        <div class="product-info">
          <div class="links_block">
            <button
              *ngIf="product.installment != 0"
              class="btn btn-sm btn-bordered"
              [ngClass]="{ disabled: product?.stock == 0 }"
              (click)="showIntallment(product.slug)"
            >
              {{ "Buy now" | translate }}
            </button>
            <a
              *ngIf="product.stock != 0"
              class="btn btn-sm btn-fill to_cart_btn"
              (click)="cart.addToCart(product.slug)"
              [ngClass]="{ active: product.in_cart == true }"
            >
              <ng-container *ngIf="product.in_cart == false && product.stock != 0">
                {{ "add_to_cart" | translate }}
              </ng-container>
              <ng-container *ngIf="product.in_cart == true">
                {{ "already_in_cart" | translate }}
              </ng-container>
            </a>

            <a
              *ngIf="product.stock == 0"
              class="btn btn-sm btn-fill to_cart_btn"
              (click)="addToReminder(product.id)"
              [ngClass]="{ active: product.in_reminder == true }"
            >
              <ng-container>
                {{ "let_us" | translate }}
              </ng-container>
            </a>
            <a
              class="to_favorites"
              (click)="addToFavorites(product.slug)"
              [ngClass]="{ active: product.in_favorite == true }"
            >
              <i class="fal fa-heart"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="product-description-block">
    <div class="product-description-content">
      <div
        class="description-group description_block"
        *ngIf="product.description"
      >
        <h5 class="h5_demi" [ngClass]="{ body_l_demi: deviceType == 'mobile' }">
          {{ "Description" | translate }}
        </h5>
        <p class="body_s_reg" [innerHTML]="product?.description"></p>
      </div>
      <!-- SPECIFICATIONS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
      <div
        class="description-group features-group"
        *ngIf="specifications.items"
      >
        <h5 class="h5_demi" [ngClass]="{ body_l_demi: deviceType == 'mobile' }">
          {{ "Specifications" | translate }}
        </h5>

        <div *ngFor="let specification of specifications.items; index as i">
          <div class="product-other-desc" *ngIf="i < specifications.max">
            <div class="feature-title">{{ specification.name }}</div>
            <div class="feature-desc">
              <ng-container
                *ngIf="
                  specification.value.length == 7 &&
                  specification.value.includes('#')
                "
              >
                <div
                  class="filterable_color"
                  [style.backgroundColor]="specification.value"
                ></div>
              </ng-container>
              <ng-container
                *ngIf="
                  specification.value.length != 7 ||
                  !specification.value.includes('#')
                "
              >
                <b [innerHTML]="specification.value"></b>
              </ng-container>
              <span class="pl-4px" *ngIf="specification.color">
                {{ specification.title }}
              </span>
            </div>
          </div>
        </div>
        <div class="product-other-desc" *ngIf="product.warranty">
          <div class="feature-title">{{ "Warranty" | translate }}</div>
          <div class="feature-desc">{{ product.warranty }}</div>
        </div>
        <div class="product-other-desc" *ngIf="product.youtube_url">
          <div class="feature-title">{{ "Youtube link" | translate }}</div>
          <div class="feature-desc">
            <a [href]="product.youtube_url" target="_blank">
              {{ "To youtube link" | translate }}</a
            >
          </div>
        </div>
        <!-- <div class="product-other-desc" *ngIf="product.stock">
          <div class="feature-title">{{ "Stock" | translate }}</div>
          <div class="feature-desc">{{ product.stock }}</div>
        </div> -->
        <a
          class="btn btn-show-more"
          (click)="showAllSpecs()"
          *ngIf="specifications.loadMore"
          >{{ "Show more" | translate }}</a
        >
      </div>
      <!-- SPECIFICATIONS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
      <div class="description-group equipment-group" *ngIf="product?.equipment">
        <h5 class="h5_demi" [ngClass]="{ body_l_demi: deviceType == 'mobile' }">
          {{ "Equipments" | translate }}
        </h5>
        <p class="body_s_reg">
          {{ product?.equipment }}
        </p>
      </div>

      <ng-container *ngIf="top_hastags.length > 0">
        <h5 class="h5_demi" [ngClass]="{ body_l_demi: deviceType == 'mobile' }">
          {{ "Hashtags" | translate }}
        </h5>
        <div class="description-group hashtag-group">
          <tag-key
            *ngFor="let tag of top_hastags"
            [remove]="false"
            [data]="tag"
          ></tag-key>
        </div>
      </ng-container>
    </div>

    <div class="product-small-view" *ngIf="state && deviceType == 'desktop'">
      <div>

        <div class="product-image">
          <img src="{{ product.images.carousel[0] }}" alt="" />
        </div>
        <div class="product-info">
          <h6 class="h6_med">{{ product.name }}</h6>
          <div class="fcs" *ngIf="product.discount > 0">
            <small class="line-through body_xs_reg"
              >{{ product.price.original }} &#8380;</small
            >
            <div class="label-product label-percent ml-8px">
              {{ product.discount }}%
            </div>
          </div>
          <strong class="h6_demi"
            >{{
              product.discount > 0
                ? product.price.discounted
                : product.price.original
            }}
            &#8380;</strong
          >
          <div class="links_block">
            <a
              *ngIf="product.stock != 0"
              class="btn btn-sm btn-fill to_cart_btn"
              (click)="cart.addToCart(product.slug)"
              [ngClass]="{ active: product.in_cart == true }"
            >
              <ng-container *ngIf="product.in_cart == false && product.stock != 0">
                {{ "add_to_cart" | translate }}
              </ng-container>
              <ng-container *ngIf="product.in_cart == true">
                {{ "already_in_cart" | translate }}
              </ng-container>
            </a>

            <a
              *ngIf="product.stock == 0"
              class="btn btn-sm btn-fill to_cart_btn"
              (click)="addToReminder(product.id)"
              [ngClass]="{ active: product.in_reminder == true }"
            >
              <ng-container>
                {{ "let_us" | translate }}
              </ng-container>
            </a>
            <a
              class="to_favorites"
              (click)="addToFavorites(product.slug)"
              [ngClass]="{ active: product.in_favorite == true }"
            >
              <i class="fal fa-heart"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <product-details-comments
    [product]="product"
    [comments]="comments"
    (commentsAction)="getProdComments()"
    *ngIf="comments?.data.length > 0"
  ></product-details-comments>

  <div
    class="store-other-products"
    *ngIf="store_other.loaded && store_other.items.length > 0"
  >
    <!-- <h5 class="h5_demi" [ngClass]="{ body_l_demi: deviceType == 'mobile' }">
      Mağazanın digər məhsulları
    </h5>
    <app-products-slider [items]="populars.items"></app-products-slider> -->

    <multiple-items-carousel
      [items]="store_other.items"
      [max_item_count]="6"
      [heading]="'Other from store' | translate"
    ></multiple-items-carousel>
  </div>

  <div
    class="store-other-products"
    *ngIf="product_similars.loaded && product_similars.items.length > 0"
  >
    <!-- <h5 class="h5_demi" [ngClass]="{ body_l_demi: deviceType == 'mobile' }">
      Oxşar məhsullar
    </h5>
    <app-products-slider [items]="discounts.items"></app-products-slider> -->
    <multiple-items-carousel
      [items]="product_similars.items"
      [max_item_count]="6"
      [heading]="'Similar products' | translate"
    ></multiple-items-carousel>
  </div>

  <div
    class="store-other-products"
    *ngIf="recent_viewed.loaded && recent_viewed.items?.length > 0"
  >
    <!-- <h5 class="h5_demi" [ngClass]="{ body_l_demi: deviceType == 'mobile' }">
      Oxşar məhsullar
    </h5>
    <app-products-slider [items]="discounts.items"></app-products-slider> -->
    <multiple-items-carousel
      [items]="recent_viewed.items"
      [max_item_count]="6"
      [heading]="'History products' | translate"
    ></multiple-items-carousel>
  </div>
</div>
