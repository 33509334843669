<div class="custom_checkbox" [ngClass]="{ disabled: usability == false }">
  <div class="custom_checkbox__icon" (click)="triggerCheck()">
    <div class="unchecked">
      <i class="fal fa-square"></i>
    </div>
    <div class="checked" [ngClass]="{ active: checkStatus == true }">
      <i class="fas fa-check-square"></i>
    </div>
  </div>
  <div class="custom_checkbox__text">
    <ng-content #customCheckbox></ng-content>
  </div>
</div>
