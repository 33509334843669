import { AboutPageComponent } from "./modules/mall/pages/about-page/about-page.component";
import { NotFoundComponent } from "./modules/shared/components/not-found/not-found.component";
import { ProfileSecurityDetailsComponent } from "./modules/auth/pages/profile/profile-security-details/profile-security-details.component";
import { BrandCollection } from "./modules/mall/pages/brand-collection/list.component";
import { SignupComponent } from "./modules/auth/pages/signup/signup.component";
import { LoginComponent } from "./modules/auth/pages/login/login.component";
import { AuthComponent } from "./modules/auth/pages/auth.component";
import { IndexComponent } from "./modules/mall/pages/index/index/index.component";
import { MallComponent } from "./modules/mall/pages/mall.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ListComponent } from "./modules/mall/pages/list/list.component";
import { ProductDetailsComponent } from "./modules/mall/pages/product-details/product-details.component";
import { FavoritesComponent } from "./modules/auth/pages/favorites/favorites.component";
import { AuthGuard } from "./common/utils/guards/auth.guard";
import { StoreComponent } from "./modules/mall/pages/store/store.component";
import { BrandsComponent } from "./modules/mall/pages/brands/brands.component";
import { BrandComponent } from "./modules/mall/pages/brand/brand.component";
import { LogoutComponent } from "./modules/auth/pages/logout/logout.component";
import { CategoriesComponent } from "./modules/mall/pages/categories/categories.component";
import { ProfileInfoComponent } from "./modules/auth/pages/profile/profile-info/profile-info.component";
import { ProfileComponent } from "./modules/auth/pages/profile/profile.component";
import { ProfileLocationsComponent } from "./modules/auth/pages/profile/profile-locations/profile-locations.component";
import { ProfileLocationsAddComponent } from "./modules/auth/pages/profile/profile-locations-add/profile-locations-add.component";
import { ProfileNotificationsComponent } from "./modules/auth/pages/profile/profile-notifications/profile-notifications.component";
import { ProfilePaymentsComponent } from "./modules/auth/pages/profile/profile-payments/profile-payments.component";
import { ProfileSecurityComponent } from "./modules/auth/pages/profile/profile-security/profile-security.component";
import { ProfileOrdersComponent } from "./modules/auth/pages/profile/profile-orders/profile-orders.component";
import { ProfileOrdersProductsComponent } from "./modules/auth/pages/profile/profile-orders-products/profile-orders-products.component";
import { ProfileOrdersFollowComponent } from "./modules/auth/pages/profile/profile-orders-follow/profile-orders-follow.component";
import { ProfileOrdersDetailsComponent } from "./modules/auth/pages/profile/profile-orders-details/profile-orders-details.component";
import { PrivacyPolicyComponent } from "./modules/mall/pages/static-pages/privacy-policy/privacy-policy.component";
import { FagPageComponent } from "./modules/mall/pages/static-pages/fag-page/fag-page.component";
import { ShowAllComponent } from "./modules/mall/pages/show-all/show-all.component";
import { SearchResultsComponent } from "./modules/mall/pages/search-results/search-results.component";

const routes: Routes = [
  {
    path: "",
    component: MallComponent,
    children: [
      { path: "", component: IndexComponent },
      { path: "categories/:slug", component: CategoriesComponent },
      { path: "list", component: ListComponent },
      { path: "product-details/:id", component: ProductDetailsComponent },
      { path: "stores", component: StoreComponent },
      { path: "brands", component: BrandsComponent },
      { path: "brand/:id", component: BrandComponent },
      { path: "static/:type", component: PrivacyPolicyComponent },
      { path: "faq", component: FagPageComponent },
      { path: "search", component: SearchResultsComponent },
      {
        path: "favorites",
        component: FavoritesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "about-page",
        component: AboutPageComponent,
      },
      {
        path: "",
        loadChildren: () =>
          import("src/app/modules/auth/pages/basket/basket.module").then(
            (m) => m.BasketModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("src/app/modules/mall/pages/blogs/blogs.module").then(
            (m) => m.BlogsModule
          ),
      },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "info", component: ProfileInfoComponent },
          { path: "locations", component: ProfileLocationsComponent },
          { path: "locations/add", component: ProfileLocationsAddComponent },
          { path: "locations/:id", component: ProfileLocationsAddComponent },
          { path: "notifications", component: ProfileNotificationsComponent },
          // { path: "payments", component: ProfilePaymentsComponent },
          { path: "security", component: ProfileSecurityComponent },
          { path: "security/:id", component: ProfileSecurityDetailsComponent },
          { path: "orders", component: ProfileOrdersComponent },
          {
            path: "orders/products/:id",
            component: ProfileOrdersProductsComponent,
          },
          {
            path: "orders/products/:id/follow",
            component: ProfileOrdersFollowComponent,
          },
          {
            path: "orders/products/:id/details",
            component: ProfileOrdersDetailsComponent,
          },
        ],
      },
    ],
  },
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "logout", component: LogoutComponent },
      { path: "", component: SignupComponent },
    ],
  },
  { path: "error", component: NotFoundComponent, canActivate: [] },
  { path: "**", component: NotFoundComponent, canActivate: [] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
