import {
  Notify,
  NotifyService,
} from "./../../../modules/shared/services/notify.service";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "fullviewport-loading",
  templateUrl: "./fullviewport-loading.component.html",
  styleUrls: ["./fullviewport-loading.component.scss"],
})
export class FullviewportLoadingComponent implements OnInit {
  constructor(private Notify: NotifyService) {
    this.Notify.logoSpinner.subscribe((res) => {
      this.spinnerStatus = res;
    });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public spinnerStatus: boolean;
}
