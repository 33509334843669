import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../../../common/utils/user/user.service";
import { ProfileService } from "../../../../../common/utils/user/profile.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AgmGeocoder } from "@agm/core";
import { createLocation } from "../profile.model";
import { NotifyService } from "src/app/modules/shared/services/notify.service";

@Component({
  selector: "app-profile-locations-add",
  templateUrl: "./profile-locations-add.component.html",
  styleUrls: ["./profile-locations-add.component.scss"],
})
export class ProfileLocationsAddComponent implements OnInit {
  public id: number;
  public errors = [];
  public info: createLocation = {
    latitude: null,
    longitude: null,
    name: null,
    address: null,
    additional: null,
    note: null,
  };

  constructor(
    public router: Router,
    public user: UserService,
    public NOTIFY: NotifyService,
    public profile: ProfileService,
    public activatedRoute: ActivatedRoute,
    public geocoder: AgmGeocoder
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.activatedRoute.paramMap.subscribe((path) => {
      const id = Number(path.get("id"));
      if (id) {
        this.id = id;
        this.profile.currentAddress(this.id).subscribe((res) => {
          this.info.latitude = res.body.latitude;
          this.info.longitude = res.body.longitude;
          this.info.name = res.body.name;
          this.info.address = res.body.address;
          this.info.additional = res.body.additional;
          this.info.note = res.body.note;
          this.setExistingCoords();
          // this.getCoords(this.info.);
        });
      }
    });
  }

  public onAddressEnter(event: any) {
    setTimeout(() => {
      let autocomplete_results = document.querySelector(".pac-container");
      let pac_items = autocomplete_results.querySelectorAll(".pac-item");
      if (pac_items.length > 0) {
        for (let i = 0; i < pac_items.length; i++) {
          this.info.address = pac_items[0].textContent;
        }
      }
      this.getCoords(this.info.address);
    }, 100);
  }

  public onAddressBlur(event: any) {
    setTimeout(() => {
      this.info.address = event.target.value;
      this.getCoords(this.info.address);
    }, 100);
  }

  private updateLocation(): void {
    this.profile.updateAddress(this.id, this.info).subscribe(
      (res) => {
        this.router.navigate(["/profile/locations"]);
        this.NOTIFY.success(res);
      },
      (error) => {
        // console.log(error);
        this.NOTIFY.error(error);
        this.errors = error.error.errors
          ? Object.values(error.error.errors)
          : [];
      }
    );
  }

  public createLocation(): void {
    if (this.id) {
      return this.updateLocation();
    }
    // console.log('step2');
    this.profile.createAddress(this.info).subscribe(
      (res) => {
        this.NOTIFY.success(res);
        this.router.navigate(["/profile/locations"]);
      },
      (error) => {
        this.NOTIFY.error(error);
        this.errors = Object.values(error.error.errors);
      }
    );
  }

  public defaultCoord = {
    lat: 40.3744273,
    lng: 49.8460281,
  };

  public selectedCoord = {
    lat: this.defaultCoord.lat,
    lng: this.defaultCoord.lng,
  };

  public mapStyle = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  public onChooseLocation(event) {
    this.selectedCoord.lat = event.coords.lat;
    this.selectedCoord.lng = event.coords.lng;

    this.info.latitude = this.selectedCoord.lat;
    this.info.longitude = this.selectedCoord.lng;

    this.getAdd();
  }

  public getAdd() {
    this.geocoder
      .geocode({
        location: {
          lat: this.selectedCoord.lat,
          lng: this.selectedCoord.lng,
        },
        region: "AZ",
      })
      .subscribe((res) => {
        this.info.address = res[0].formatted_address;
      });
  }

  public getCoords(address: string) {
    if (address != "") {
      this.geocoder
        .geocode({
          address: `${address}`,
          region: "AZ",
        })
        .subscribe(
          (res) => {
            this.selectedCoord.lat = res[0].geometry.location.lat();
            this.selectedCoord.lng = res[0].geometry.location.lng();
            this.info.latitude = res[0].geometry.location.lat();
            this.info.longitude = res[0].geometry.location.lng();
          },
          (err) => {
            this.NOTIFY.set({ text: "Address not found", status: "danger" });
            this.selectedCoord.lat = this.defaultCoord.lat;
            this.selectedCoord.lng = this.defaultCoord.lng;
            this.info.latitude = null;
            this.info.longitude = null;
            this.info.address = null;
          }
        );
    }
  }

  public setExistingCoords() {
    this.selectedCoord.lat = this.info.latitude;
    this.selectedCoord.lng = this.info.longitude;
  }

  public google_autocomplete_options = {
    componentRestrictions: {
      country: ["AZ"],
    },
  };
}
