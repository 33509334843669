<div class="container">
  <app-breadcrump
    [category]="category?.breadcrumb"
    [withMainCategory]="true"
    *ngIf="state && pageType == 'list'"
  ></app-breadcrump>
  <page-heading
    [heading]="category?.name"
    [subtitle]="filtered_count"
    *ngIf="category?.name?.length && filtered_count"
  ></page-heading>

  <div class="search_result_cntnr">
    <div class="search_bar_cntnr" *ngIf="deviceType == 'desktop'">
      <ng-container *ngIf="category?.children?.length > 0">
        <div class="filters_wrppr">
          <div class="filters_category">
            <ul>
              <li *ngFor="let subCategory of category.children">
                <a
                  [routerLink]="'/list'"
                  [queryParams]="{
                    category_id: subCategory?.id,
                    slug: subCategory?.slug
                  }"
                  >{{ subCategory?.name }}</a
                >
              </li>
            </ul>
            <!--                    <div><a class="btn btn-show-more" href="">Daha çox göstər</a></div>-->
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let filter of filters">
        <list-filter [filter]="filter"></list-filter>
      </ng-container>

      <div class="price_range_div" *ngIf="filters">
        <strong class="body_s_demi"
          >{{ "Price range" | translate }} &#8380;</strong
        >
        <div class="inputs_container">
          <div class="each_inp_div">
            <span class="label body_xs_med">{{ "min" | translate }}</span>
            <input
              type="text"
              inputmode="numeric"
              mask="000000000"
              [(ngModel)]="min_price"
              name="min_price"
              (input)="onPriceRangeChange($event.target.name)"
            />
          </div>
          <div class="each_inp_div right">
            <span class="label body_xs_med"
              >{{ "max" | translate }} &#8380;</span
            >
            <input
              type="text"
              inputmode="numeric"
              mask="000000000"
              [(ngModel)]="max_price"
              name="max_price"
              (input)="onPriceRangeChange($event.target.name)"
            />
          </div>
        </div>
      </div>
      <!-- pərakəndə -->
      <div class="retailer_div mt-32px" *ngIf="filters">
        <toggler
          [label]="'Retail products'"
          [name]="'retail'"
          [value]="retail_toggler"
          (outPutValue)="onRetailChange($event)"
        ></toggler>
      </div>
      <!-- pərakəndə -->
    </div>
    <div
      class="mobile_filters"
      *ngIf="deviceType == 'mobile' && mobile_filter == true"
    >
      <div class="close_mobile_filters" (click)="closeMobileFilters()">
        <div class="icon_block fcc">
          <i class="fal fa-times"></i>
        </div>
        <span class="body_m_med">{{ "Filters" | translate }}</span>
      </div>

      <ng-container *ngFor="let filter of filters">
        <list-filter [filter]="filter"></list-filter>
      </ng-container>

      <div class="price_range_div" *ngIf="filters">
        <strong class="body_s_demi"
          >{{ "Price range" | translate }} &#8380;</strong
        >
        <div class="inputs_container">
          <div class="each_inp_div">
            <span class="label body_xs_med">{{ "min" | translate }}</span>
            <input
              type="text"
              inputmode="numeric"
              mask="000000000"
              [(ngModel)]="min_price"
              name="min_price"
              (input)="onPriceRangeChange($event.target.name)"
            />
          </div>
          <div class="each_inp_div right">
            <span class="label body_xs_med"
              >{{ "max" | translate }} &#8380;</span
            >
            <input
              type="text"
              inputmode="numeric"
              mask="000000000"
              [(ngModel)]="max_price"
              name="max_price"
              (input)="onPriceRangeChange($event.target.name)"
            />
          </div>
        </div>
      </div>
      <!-- pərakəndə -->
      <div class="retailer_div mt-32px" *ngIf="filters">
        <toggler
          [label]="'Retail products'"
          [name]="'retail'"
          (outPutValue)="onRetailChange($event)"
        ></toggler>
      </div>
      <!-- pərakəndə -->
    </div>
    <div class="result_cntnr" *ngIf="state">
      <div class="product-carousel" *ngIf="category?.banners">
        <app-list-slider
          [slides]="category?.banners"
          *ngIf="category?.banners?.length > 0"
        ></app-list-slider>
      </div>
      <div class="product_grid_div">
        <product-grid
          *ngIf="state"
          [products]="products"
          [tags]="tags"
          [page]="'list_page'"
          [sorting_options]="sort"
          [selected_sorting]="selected_sorting"
          (filterToggler)="onMobileFilterToggler($event)"
          [hidden_list]="false"
          (onFilterChange)="onSortingFilterChange($event)"
        >
        </product-grid>
        <pagination-component
          *ngIf="state"
          [links]="links"
        ></pagination-component>
      </div>

      <div class="empty_list" *ngIf="products && products.length == 0">
        <div class="empty_icon fcc">
          <i class="fal fa-th-large"></i>
        </div>
        <h6 class="h6_med">{{ "Empty list" | translate }}</h6>
      </div>
    </div>
  </div>
</div>
