<div class="container" *ngIf="state">
  <app-breadcrump [category]="breadcrump"></app-breadcrump>
  <page-heading
    [heading]="brand.name"
    [subtitle]="brand.products_count"
  ></page-heading>
  <div class="search_result_cntnr">
    <div class="search_bar_cntnr">
      <div class="filters_wrppr">
        <div class="brand_item brand_stc_item">
          <a><img [src]="brand.image" alt="{{ brand.name }}" /></a>
        </div>
        <div class="filters_category">
          <ul *ngIf="brand.categories?.length > 0">
            <li *ngFor="let item of brand.categories">
              <a
                [routerLink]="'/list'"
                [queryParams]="{
                  store_id: brandId,
                  category_id: item.id,
                  slug: item.slug
                }"
                >{{ item.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="result_cntnr">
      <div class="product-carousel">
        <app-brand-slider [slides]="brand.banners"></app-brand-slider>
      </div>

      <div class="brand_card_cntnr">
        <div class="brand_card" *ngFor="let item of top_brands">
          <a
            [routerLink]="'/list'"
            [queryParams]="{
              store_id: brandId,
              category_id: item?.id,
              slug: item?.slug
            }"
          >
            <div class="brand_text">
              <h3 class="overline">{{ item?.name }}</h3>
              <span class="body_l_demi"
                >{{ item?.products_count }} {{ "products" | translate }}</span
              >
            </div>
            <div>
              <img [src]="item?.image" [alt]="item?.name" />
            </div>
          </a>
        </div>
      </div>

      <div
        class="popular_container"
        *ngIf="products.popular?.items?.length > 0"
      >
        <multiple-items-carousel
          [items]="products.popular.items"
          [heading]="products.popular.heading"
          [show_all_url]="products.popular.show_all_url"
        >
        </multiple-items-carousel>
      </div>

      <div
        class="discount_container"
        *ngIf="products.discounting?.items?.length > 0"
      >
        <multiple-items-carousel
          [items]="products.discounting.items"
          [heading]="products.discounting.heading"
          [show_all_url]="products.discounting.show_all_url"
        >
        </multiple-items-carousel>
      </div>

      <div
        class="birkart_container"
        *ngIf="products.birkart?.items?.length > 0"
      >
        <multiple-items-carousel
          [items]="products.birkart.items"
          [heading]="products.birkart.heading"
          [show_all_url]="products.birkart.show_all_url"
        >
        </multiple-items-carousel>
      </div>

      <div
        class="last_created_container"
        *ngIf="products.latest?.items?.length > 0"
      >
        <multiple-items-carousel
          [items]="products.latest.items"
          [heading]="products.latest.heading"
          [show_all_url]="products.latest.show_all_url"
        >
        </multiple-items-carousel>
      </div>
    </div>
  </div>
</div>
