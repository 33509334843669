<div class="auth_wrapper">
  <div class="auth_left">
    <a [routerLink]="['']">
      <img src="../../../../assets/icons/app_logo.svg" alt="" />
    </a>
    <div class="image_block">
      <img src="assets/images/login_img.svg" alt="" />
    </div>
  </div>
  <div class="auth_right">
    <router-outlet></router-outlet>
  </div>
</div>
