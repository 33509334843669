import { LanguageService } from "./../../../../../common/utils/language/services/language.service";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { MallService } from "./../../../mall.service";
import { element } from "protractor";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { SharedService } from "src/app/modules/shared/services/shared.service";
import { Subscription } from "rxjs";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(
    public MALL: MallService,
    private SHARED: SharedService,
    private router: Router,
    private ACTIVATED_ROUTE: ActivatedRoute,
    private LANG: LanguageService
  ) {
    this.detectDevice();
    this.res = this.SHARED.staticKeys;
    this.subs2 = this.SHARED.staticData.subscribe((res) => {
      if (res) {
        this.res = res;
        this.fillData();
      }
    });
    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.fillData();
      }
    });
  }

  setDefaultLangFormMobileView() {
    let urlLang = this.ACTIVATED_ROUTE.snapshot.queryParamMap.get("lang");
    if (urlLang) {
      this.LANG.switchLang(urlLang);
      this.SHARED.fetchStatics();
      this.fillData();
    }
  }

  private res: any = null;
  title_up: string = null;
  title: string = null;
  text: string = null;

  public subscription: Subscription = null;
  public subs2: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subs2.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.setDefaultLangFormMobileView();
  }

  public fillData() {
    let pageType = this.ACTIVATED_ROUTE.snapshot.paramMap.get("type");
    switch (pageType) {
      case "user-terms":
        this.text = this.res?.page_user_terms_and_conditions_text;
        this.title_up = this.res?.page_user_terms_and_conditions_title_up;
        this.title = this.res?.page_user_terms_and_conditions_title;
        break;
      case "payment":
        this.text = this.res?.page_payment_text;
        this.title_up = this.res?.page_payment_title_up;
        this.title = this.res?.page_payment_title;
        break;
      case "return":
        this.text = this.res?.page_return_products_text;
        this.title_up = this.res?.page_return_products_title_up;
        this.title = this.res?.page_return_products_title;
        break;
      case "warranty":
        this.text = this.res?.page_warranty_text;
        this.title_up = this.res?.page_warranty_title_up;
        this.title = this.res?.page_warranty_title;
        break;
      case "delivery":
        this.text = this.res?.page_delivery_text;
        this.title_up = this.res?.page_delivery_title_up;
        this.title = this.res?.page_delivery_title;
        break;
      case "security":
        this.text = this.res?.page_security_text;
        this.title_up = this.res?.page_security_title_up;
        this.title = this.res?.page_security_title;
        break;
      case "privacy-policy":
        this.text = this.res?.page_privacy_policy_text;
        this.title_up = this.res?.page_privacy_policy_title_up;
        this.title = this.res?.page_privacy_policy_title;
        break;
      case "cookies":
        this.text = this.res?.page_cookies_text;
        this.title_up = this.res?.page_cookies_title_up;
        this.title = this.res?.page_cookies_title;
        break;
      case "site-map":
        this.text = this.res?.page_site_map_text;
        this.title_up = this.res?.page_site_map_title_up;
        this.title = this.res?.page_site_map_title;
        break;
      default:
        break;
    }

    this.generateNavs();
  }

  generatedLinks: number = null;
  generateNavs() {
    let pageType = this.ACTIVATED_ROUTE.snapshot.paramMap.get("type");
    switch (pageType) {
      case "user-terms":
      case "payment":
      case "return":
      case "warranty":
      case "delivery":
      case "security":
        this.generatedLinks = 1;
        break;
      case "privacy-policy":
      case "cookies":
        this.generatedLinks = 2;
        break;
      case "site-map":
        break;
      default:
        break;
    }
  }

  public activeIndex = 0;
  public content = {
    titles: [],
    texts: [],
  };

  // public clickLink(i: number) {
  //   this.activeIndex = i;
  //   const element = document.querySelector(`.each_content_${i}`);
  //   element.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //     inline: "nearest",
  //   });
  // }

  public onPageScroll() {}

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
