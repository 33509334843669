import { Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "./../../auth.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { SharedService } from "src/app/modules/shared/services/shared.service";
import { variables } from "src/environments/variables";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  constructor(
    private AUTH: AuthService,
    private spinner: NgxSpinnerService,
    private ROUTER: Router,
    private NOTIFY: NotifyService,
    private SHARED: SharedService
  ) {
    this.subscription = this.SHARED.staticData.subscribe((res) => {
      if (res) {
        this.res = res;
        this.generateStatistics();
      }
    });
    if (this.SHARED.staticKeys) {
      this.res = this.SHARED.staticKeys;
    }
  }

  public subscription: Subscription = null;
  privacy_modal_text: any;
  private res: any = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  private generateStatistics() {
    this.privacy_modal_text = this.res.page_privacy_policy_text;
  }

  public localState = {
    firstname: null,
    lastname: null,
    signup_phone: null,
    masked_signup_phone: null,
    signup_request_sent: false,
    code: null,
    privacy_status: false,
    countDown: null,
    resendCodeStatus: true,
  };

  public getLang() {
    return localStorage.getItem("lang");
  }

  onInputChange(target, inputValue: any) {
    this.localState[target.name] = inputValue;
  }

  showLocalState(event: any) {
    this.localState.signup_phone = this.localState.signup_phone
      .replace("+994(", "")
      .replace(")", "")
      .split(" ")
      .join("");
    this.localState.masked_signup_phone = this.localState.signup_phone
      .split("")
      .splice(0, 5)
      .join("");
    this.spinner.show();
    this.getOTP();
  }

  getOTP() {
    this.localState.resendCodeStatus = true;
    let requestTime = new Date().getTime() + 180000;

    let timeInterval = setInterval(() => {
      let difference = requestTime - new Date().getTime();
      let mins, sec, tempmins, tempsecs;
      mins = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      sec = Math.floor((difference % (1000 * 60)) / 1000);

      if (mins < 10) {
        tempmins = `0${mins}`;
      }

      if (sec < 10) {
        tempsecs = `0${sec}`;
      } else {
        tempsecs = sec;
      }

      if (difference < 0) {
        clearInterval(timeInterval);
        tempmins = "00";
        tempsecs = "00";
        this.localState.resendCodeStatus = false;
      }

      this.localState.countDown = `${tempmins}:${tempsecs}`;
    }, 1000);

    this.AUTH.getOneTimePass({
      phone: this.localState.signup_phone,
    }).subscribe(
      (RESPONSE) => {
        this.spinner.hide();
        this.localState.signup_request_sent = true;
        // console.log(RESPONSE);
      },
      (ERR) => {
        console.log(ERR);
      }
    );
  }

  privacyChecked(data: boolean) {
    this.localState.privacy_status = data;
  }

  validateGetPassword() {
    if (
      this.localState.firstname?.length > 3 &&
      this.localState.lastname?.length > 3 &&
      this.localState.signup_phone?.length == 9 &&
      this.localState.privacy_status == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  validatePassCode() {
    return this.localState.code?.length == 4 ? true : false;
  }

  finishRegistration() {
    this.spinner.show();
    this.AUTH.registerAccount({
      code: this.localState.code,
      phone: this.localState.signup_phone,
      firstname: this.localState.firstname,
      lastname: this.localState.lastname,
    }).subscribe(
      (RESPONSE) => {
        this.spinner.hide();
        localStorage.setItem("front_access_token", RESPONSE.body.access_token);
        localStorage.setItem("expires_at", RESPONSE.body.expires_at.toString());
        this.AUTH.getUserDetails().subscribe(
          (RESPONSE) => {
            variables.cookie.user_data.user = RESPONSE.body;
            localStorage.setItem("front_user", JSON.stringify(RESPONSE.body));
            this.SHARED.relocateBack();
            this.NOTIFY.stopSpinner();
          },
          (err) => {
            this.NOTIFY.error(err);
            this.ROUTER.navigate(["auth/logout"]);
            this.NOTIFY.stopSpinner();
          }
        );
      },
      (ERR) => {
        this.spinner.hide();
        console.log(ERR);
      }
    );
  }

  public privacyModalToggler: boolean = false;
  public showPrivacyPolicyModal(event) {
    this.privacyModalToggler = true;
  }

  public closePrivacyPolicyModal(event) {
    this.privacyModalToggler = false;
  }

  public approvePrivacy() {
    this.privacyModalToggler = false;
    this.localState.privacy_status = true;
  }
}
