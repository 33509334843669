<app-profile-headline
  [title]=""
  [description]="order_date | date: 'dd LLLL yyyy HH:mm'"
  [page]="'orders-products-follow'"
  [order_id]="order?.order_id"
  [products_count]="order?.products_count"
>
</app-profile-headline>
<ng-container *ngIf="order">
  <div class="delivery_canceled_icon" *ngIf="trackingStatus == 0">
    <i class="fas fa-times-circle"></i>
    <p class="body_l_reg">{{ "Order cancelled" | translate }}</p>
  </div>
  <div class="delivery-process" *ngIf="trackingStatus != 0">
    <div
      class="each_delivery_block"
      [ngClass]="{ active: trackingStatus >= 2 }"
    >
      <span><i class="fas fa-check-circle check"></i></span>
      <p class="body_xs_med">{{ "Order accepted" | translate }}</p>
    </div>
    <div
      class="each_delivery_block"
      [ngClass]="{ active: trackingStatus >= 3 }"
    >
      <span><i class="fas fa-motorcycle"></i></span>
      <p class="body_xs_med">{{ "Shipped" | translate }}</p>
    </div>
    <div
      class="each_delivery_block"
      [ngClass]="{ active: trackingStatus == 4 }"
    >
      <span><i class="fas fa-pennant"></i></span>
      <p class="body_xs_med">{{ "Delivered" | translate }}</p>
    </div>
  </div>
  <div class="delivery-block">
    <div class="type-of-delivery-block">
      <h3 class="body_l_demi">{{ "Order updates" | translate }}</h3>
      <div class="history_row">
        <div class="each_td overline">
          {{ "Date" | translate }}
        </div>
        <div class="each_td overline desk_only">
          {{ "Address" | translate }}
        </div>
        <div class="each_td overline t_align_right">
          {{ "Process" | translate }}
        </div>
      </div>
      <ng-container *ngFor="let change of deliveryChanges">
        <div class="history_row">
          <div class="each_td body_s_reg">
            {{ change.date }}
          </div>
          <div class="each_td body_s_reg desk_only">{{ change.address }}</div>
          <div [innerHTML]="change.action" class="each_td body_s_med t_align_right">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
