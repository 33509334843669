<div class="d-f ai-c" *ngIf="label">
  <label class="body_s_demi t_black_900 mr-8px" [ngClass]="{ star: star }">{{
    label | translate
  }}</label>
  <div class="toggler" (click)="toggle()">
    <div class="toggler_rails" [ngClass]="{ active: status == true }">
      <div class="icon"></div>
    </div>
  </div>
</div>

<div *ngIf="!label" class="toggler" (click)="toggle()">
  <div class="toggler_rails" [ngClass]="{ active: status == true }">
    <div class="icon"></div>
  </div>
</div>
