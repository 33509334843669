<div class="fullwidth_container" *ngIf="localState.fullwidth_state.items?.length > 0">
  <fullwidth-carousel-banner [mobile_items]="localState.fullwidth_state.mobile_items" [items]="localState.fullwidth_state.items"></fullwidth-carousel-banner>
</div>

<div class="favorites_container" *ngIf="localState.favorite_items.items?.length > 0">
  <multiple-items-carousel [items]="localState.favorite_items.items"
    [max_item_count]="localState.favorite_items.max_item_count" [heading]="localState.favorite_items.heading"
    [show_all_url]="localState.favorite_items.show_all_url"></multiple-items-carousel>
</div>

<div class="discount_container" *ngIf="localState.discount_items.items?.length > 0">
  <multiple-items-carousel [items]="localState.discount_items.items"
    [max_item_count]="localState.discount_items.max_item_count" [heading]="localState.discount_items.heading"
    [show_all_url]="localState.discount_items.show_all_url"></multiple-items-carousel>
</div>

<div class="category_container container" *ngIf="localState.category_banners.items?.length > 0">
  <index-category-banner class="each_category_card" *ngFor="let item of localState.category_banners.items"
    [item]="item">
  </index-category-banner>
</div>

<div class="birkart_container" *ngIf="localState.birkart_items.items?.length > 0">
  <multiple-items-carousel [items]="localState.birkart_items.items"
    [max_item_count]="localState.birkart_items.max_item_count" [heading]="localState.birkart_items.heading"
    [show_all_url]="localState.birkart_items.show_all_url">
  </multiple-items-carousel>
</div>

<div class="new_products_container" *ngIf="localState.last_created_items.items?.length > 0">
  <multiple-items-carousel [items]="localState.last_created_items.items"
    [max_item_count]="localState.last_created_items.max_item_count" [heading]="localState.last_created_items.heading"
    [show_all_url]="localState.last_created_items.show_all_url">
  </multiple-items-carousel>
</div>

<div class="brands_container container" *ngIf="localState.brands_items.items?.length > 0">
  <brands-carousel [items]="localState.brands_items.items" [max_item_count]="localState.brands_items.max_item_count">
  </brands-carousel>
</div>

<div class="features_container container">
  <features-cards></features-cards>
</div>

<div class="blogs_container container" *ngIf="localState?.blogs?.length > 0">
  <blog-carousel [items]="localState.blogs" [max_item_count]="4" [heading]="'Blogs'" [show_all_url]="true">
  </blog-carousel>
</div>

<!-- <div class="bloq_container"></div> -->