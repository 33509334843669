<div
  class="lang_selector"
  [ngClass]="{ open: state.open_status == true }"
  (click)="toggleLangSelector($event)"
  (clickOutside)="onClickedOutside($event)"
  [clickOutsideEnabled]="true"
>
  <div class="lang_selector__btn">
    <i class="fas fa-globe-americas"></i>
    <span class="body_xs_reg">{{
      state.selected_lang === null ? state.languages[0] : state.selected_lang
    }}</span>
  </div>

  <div class="lang_selector__content">
    <div
      class="lang_option body_xs_reg"
      *ngFor="let lang of state.languages; let i = index"
      [attr.data-lang]="state.lang_data[i]"
      (click)="changeSelectedLanguage($event.currentTarget.dataset.lang, i)"
    >
      {{ lang }}
    </div>
  </div>
</div>
