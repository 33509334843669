import {
  Notify,
  NotifyService,
} from "./../../../modules/shared/services/notify.service";
import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { generateRandomString } from "src/app/common/utils/functions/generateRandomString.function";

@Component({
  selector: "notification-popup",
  templateUrl: "./notification-popup.component.html",
  styleUrls: ["./notification-popup.component.scss"],
})
export class NotificationPopupComponent implements OnInit {
  constructor(private notify: NotifyService) {
    this.subscribeNotifications();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public notifications: Notify[] = [];
  public subs_notifications: Subscription;
  private subscribeNotifications() {
    this.subs_notifications = this.notify.notification.subscribe((res) => {
      if (res) {
        res.id = generateRandomString(5);
        this.notifications.push(res);
        this.autoHideNotification(res);
      }
    });
  }

  public close(notification: Notify): void {
    for (let i = 0; i < this.notifications.length; i++) {
      if (this.notifications[i].id == notification.id) {
        this.notifications.splice(i, 1);
        return;
      }
    }
  }

  public autoHideNotification(notification: Notify) {
    setTimeout(
      () => {
        this.close(notification);
      },
      notification.seconds ? notification.seconds * 1000 : 4 * 1000
    );
  }
}
