import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";

@Component({
  selector: "simple-select",
  templateUrl: "./simple-select.component.html",
  styleUrls: ["./simple-select.component.scss"],
})
export class SimpleSelectComponent implements OnInit {
  constructor() {}

  @Output() selectedValue = new EventEmitter();
  @Input("selected") public selected: {
    label: string;
    value: string;
  };

  @Input("background") public background: string;
  @Input("size") public size: string;

  @Input("options") public options: [];

  ngOnInit(): void {
    window.scroll(0, 0);
    this.state.options = this.options;
    if (this.selected) {
      this.state.value = this.selected;
    } else {
      this.state.value = { label: "", value: "" };
    }
  }

  public state = {
    trigger: false,
    value: null,
    options: [],
  };

  public triggerSelect(event: any) {
    this.state.trigger = !this.state.trigger;
  }

  public setOptionValue(value: any) {
    this.state.options.map((opt) => {
      if (opt.value == value) {
        this.state.value = opt;
      }
    });
    this.selectedValue.emit(this.state.value);
    this.state.trigger = false;
  }

  public onClickedOutside(event: any) {
    this.state.trigger = false;
  }
}
