<div
  class="custom_select_container"
  (clickOutside)="onClickedOutside($event.target)"
  [clickOutsideEnabled]="true"
>
  <!-- Inputless select -->
  <div
    class="inputless_trigger"
    [ngClass]="{ active: state.trigger }"
    (click)="triggerSelect($event)"
    *ngIf="!state.input"
  >
    <div class="text_side">
      <p class="body_m_med">{{ state.value }}</p>
    </div>
    <div class="trigger_btn fcc">
      <i class="far fa-angle-down"></i>
    </div>
  </div>

  <!-- Select with Input -->
  <div
    class="input_trigger"
    *ngIf="state.input"
    [ngClass]="{ active: state.trigger }"
  >
    <input
      type="text"
      class="body_m_med"
      [value]="state.value"
      (input)="changeInputValue($event.target.value)"
      (blur)="onInputBlur($event.target.value)"
      mask="000000"
      inputmode="numeric"
    />
    <div class="trigger_btn fcc" (click)="triggerSelect($event)">
      <i class="far fa-angle-down"></i>
    </div>
  </div>

  <div class="options_container" [ngClass]="{ active: state.trigger == true }">
    <div
      class="each_option body_m_med"
      [ngClass]="{ selected: opt == state.value }"
      *ngFor="let opt of state.limit"
      (click)="setOptionValue(opt)"
    >
      {{ opt }}
    </div>
  </div>
</div>
