<app-profile-headline
  [title]="'Profile security'"
  [description]="
    'You can check the list of devices that come with your profile to ensure security and prevent outside intrusion into your profile.'
  "
  [page]="'security'"
>
</app-profile-headline>

<section class="profile_security">
  <a
    class="security_card"
    *ngFor="let session of sessions; let i = index"
    (click)="triggerSession(i)"
    [routerLink]="['/profile/security/' + session.id]"
  >
    <div class="device_block icon_block fcc">
      <i class="fal fa-desktop" *ngIf="session.isDesktop"></i>
      <i class="fal fa-mobile" *ngIf="session.isPhone || session.isMobile"></i>

      <i class="fal fa-tablet" *ngIf="session.isTablet"></i>
    </div>
    <div class="session_content">
      <strong class="body_m_med">{{
        session.platform !== null ? session.platform : session.browser
      }}</strong>
      <p class="body_xs_med">
        {{ session.location !== "" ? session.location : "" }}
        <!-- {{ session.last_activity.replace(" ", "T") | date: "HH:mm" }} -->
        {{ session.date }}
      </p>
    </div>
    <div class="arrow_block icon_block fcc">
      <i class="fal fa-angle-right"></i>
    </div>
  </a>
</section>
