<app-profile-headline
  [title]="'Kart məlumatları'"
  [description]="
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis.'
  "
  [page]="'payments'"
>
</app-profile-headline>

<div class="profile-data">
  <div class="location-check">
    <div class="check-title">
      <div class="icon"><i class="fal fa-credit-card"></i></div>
      <div class="text">
        <h3 class="body_m_med">Vilayat Muslumzada</h3>
        <p class="body_xs_med">510307******5742</p>
      </div>
    </div>
    <button class="dropdown-menu"><i class="far fa-ellipsis-h"></i></button>
    <div class="dropdown-list">
      <ul>
        <li><a href="" class="body_s_reg">seçilmiş et</a></li>
        <li><a href="" class="body_s_reg">düzəliş etmək</a></li>
        <li><a href="" class="body_s_reg">silmək</a></li>
      </ul>
    </div>
  </div>
  <div class="location-check">
    <div class="check-title">
      <div class="icon"><i class="fal fa-credit-card"></i></div>
      <div class="text">
        <h3 class="body_m_med">Vilayat Muslumzada (seçilmiş)</h3>
        <p class="body_xs_med">981238******2345</p>
      </div>
    </div>
    <button class="dropdown-menu"><i class="far fa-ellipsis-h"></i></button>
    <div class="dropdown-list">
      <ul>
        <li><a href="" class="body_s_reg">seçilmiş et</a></li>
        <li><a href="" class="body_s_reg">düzəliş etmək</a></li>
        <li><a href="" class="body_s_reg">silmək</a></li>
      </ul>
    </div>
  </div>
  <div class="location-check">
    <div class="check-title">
      <a href="" class="add-to body_s_med"
        ><i class="far fa-plus plus"></i>{{ "Add new address" | translate }}</a
      >
    </div>
  </div>
</div>
