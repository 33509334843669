import { ProfileService } from "./../../../../../common/utils/user/profile.service";
import { PusherService } from "./../../../../shared/services/pusher.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { MallService } from "src/app/modules/mall/mall.service";

@Component({
  selector: "app-profile-notifications",
  templateUrl: "./profile-notifications.component.html",
  styleUrls: ["./profile-notifications.component.scss"],
})
export class ProfileNotificationsComponent implements OnInit {
  constructor(
    public pusherService: PusherService,
    private ActivatedRoute: ActivatedRoute,
    public profile: ProfileService,
    private router: Router,
    private NOTIFY: NotifyService,
    private MALL: MallService
  ) {
    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.fetchNotifications();
      }
    });
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public resultArr: any;

  public user = JSON.parse(localStorage.getItem("front_user"));

  public notificationsList = [];
  public links: any = null;
  public pageId: any = 1;
  public fetchNotifications() {
    this.NOTIFY.startSpinner();
    this.pageId = this.ActivatedRoute.snapshot.queryParamMap.get("page");
    this.pageId = this.pageId ? this.pageId : 1;
    this.profile.notifications(this.pageId).subscribe(
      (res) => {
        this.notificationsList = res.body.data;
        this.links = res.body.meta.links;
        this.NOTIFY.stopSpinner();
      },
      (err) => {
        this.NOTIFY.error(err);
        this.NOTIFY.stopSpinner();
      }
    );
  }

  public readNotification(notify: any) {
    if (!notify.read) {
      this.NOTIFY.startSpinner();

      this.profile.readNotification(notify.id).subscribe(
        (res) => {
          this.fetchNotifications();
          this.MALL.setCounters();
        },
        (err) => {
          this.NOTIFY.stopSpinner();
          this.NOTIFY.error(err);
        }
      );
    }
  }
}
