import { Title } from "@angular/platform-browser";
import { createParams } from "src/app/common/utils/queryParams/createParams.function";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { MallService } from "../../mall.service";
import { generateTitle } from "src/app/common/utils/functions/generateTitle.function";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { SharedService } from "src/app/modules/shared/services/shared.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CategoriesComponent implements OnInit {
  public state = false;
  public slug: string;
  public name: string;
  public total: number;
  public breadcrump = [];
  public children = [];
  public slides: [];
  public category: { id: number };
  public stores;
  public category_id: number = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private mallService: MallService,
    private Title: Title,
    private router: Router,
    private NOTIFY: NotifyService,
    private shared: SharedService
  ) {
    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.category_id = Number(
          this.activatedRoute.snapshot.queryParamMap.get("category_id")
        );
        this.cState.discount_items.show_all_url.category_id = this.category_id;
        this.cState.favorite_items.show_all_url.category_id = this.category_id;
        this.cState.last_created_items.show_all_url.category_id =
          this.category_id;
        this.slug = this.activatedRoute.snapshot.paramMap.get("slug");
        this.updateData();
        this.loadCategoryProductCarousels();
        this.detectDevice();
      }
    });
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.firstLoad = false;
  }

  public firstLoad: boolean = true;

  public updateData(): void {
    if (!this.firstLoad) {
      this.NOTIFY.startSpinner();
    }
    this.mallService.getCategoryDetails(this.slug).subscribe((res) => {
      this.Title.setTitle(generateTitle(res.body.name));
      this.name = res.body.name;
      this.total = res.body.total_items;
      this.children = res.body.children;
      this.slides = res.body.banners;
      this.stores = res.body.stores;
      this.breadcrump = [{ name: this.name, path: `/categories/${this.slug}` }];
      this.state = true;
      if (!this.firstLoad) {
        this.NOTIFY.stopSpinner();
      }
    });
  }

  public cState = {
    favorite_items: {
      items: [],
      heading: "Populyar məhsullar",
      show_all_url: {
        order_by: "view",
        order_dir: "desc",
        category_id: this.category_id,
      },
      max_item_count: 6,
    },
    last_created_items: {
      items: [],
      heading: "Yeni məhsullar",
      show_all_url: {
        order_by: "date",
        order_dir: "desc",
        category_id: this.category_id,
      },
      max_item_count: 6,
    },
    birkart_items: {
      items: [],
      heading: "BirKart products",
      show_all_url: {
        type: "birkart",
        category_id: this.category_id,
      },
      max_item_count: 6,
    },
    discount_items: {
      items: [],
      heading: "Endirimli məhsullar",
      show_all_url: {
        category_id: this.category_id,
        type: "discount",
      },
      max_item_count: 6,
    },
    features_items: {
      items: [],
    },
  };
  public loadCategoryProductCarousels() {
    // load favorites ======================================
    this.mallService
      .getProductsList({
        category_id: this.category_id,
        per_page: 20,
        order_by: "view",
        order_dir: "desc",
      })
      .subscribe((res) => {
        this.cState.favorite_items.items = [];
        this.cState.favorite_items.items = res.body.data;
      });
    // load favorites ======================================

    // load lastCreated  ======================================
    this.mallService
      .getProductsList({
        category_id: this.category_id,
        per_page: 20,
        order_by: "date",
        order_dir: "desc",
      })
      .subscribe((res) => {
        this.cState.last_created_items.items = [];
        this.cState.last_created_items.items = res.body.data;
      });
    // load lastCreated  ======================================

    // load lastCreated  ======================================
    this.mallService
      .getProductsList({
        category_id: this.category_id,
        per_page: 20,
        type: "discount",
      })
      .subscribe((res) => {
        this.cState.discount_items.items = [];
        this.cState.discount_items.items = res.body.data;
      });
    // load lastCreated  ======================================
    // load birkart  ======================================
    this.mallService
      .getProductsList({
        category_id: this.category_id,
        per_page: 20,
        type: "birkart",
      })
      .subscribe((res) => {
        this.cState.birkart_items.items = [];
        this.cState.birkart_items.items = res.body.data;
      });
    // load birkart  ======================================
    this.cState.features_items.items = this.shared.sharedFeatures;
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
