import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { CartService } from "../../utils/user/cart.service";
import { FavoritesService } from "../../utils/user/favorites.service";
import { UserService } from "../../utils/user/user.service";
import { MallService } from "src/app/modules/mall/mall.service";
import { ListProduct } from "src/app/modules/mall/mall.models";
import { NotifyService } from "src/app/modules/shared/services/notify.service";

@Component({
  selector: "product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProductCardComponent implements OnInit {
  @Input() public item: ListProduct;
  @Input() public static: boolean;
  @Input() public page: boolean | string;
  @Output() public deleteEvent = new EventEmitter();

  constructor(
    private ROUTER: Router,
    public user: UserService,
    public favorites: FavoritesService,
    public cart: CartService,
    private MALL: MallService,
    private NOTIFY: NotifyService
  ) {
    this.detectDevice();
  }

  ngOnInit(): void {
    // window.scroll(0, 0);
  }

  public removeItem(slug: string) {
    this.deleteEvent.emit(slug);
  }

  public toProductInner(event: any, path: any) {
    this.ROUTER.navigate(path);
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }

  public addToCart(slug: string) {
    if (this.item.in_cart == false) {
      this.checkVariations();
    }
  }

  public checkVariations() {
    this.NOTIFY.startSpinner();
    this.MALL.getProductDetails(this.item.slug).subscribe(
      (res) => {
        let product = res.body;
        this.NOTIFY.ATCProduct.emit(product);
        this.NOTIFY.stopSpinner();
      },
      (err) => {
        this.NOTIFY.stopSpinner();
        this.NOTIFY.error(err);
      }
    );
    // this.MALL.getProductDetails(this.item.slug).subscribe(
    //   (res) => {
    //     let product = res.body;
    //     this.NOTIFY.stopSpinner();
    //     if (product.variations?.length > 1) {
    //       this.NOTIFY.ATCProduct.emit(product);
    //     } else {
    //       this.cart.addToCart(this.item.slug);
    //       this.item.in_cart = true;
    //     }
    //   },
    //   (err) => {
    //     this.NOTIFY.stopSpinner();
    //     this.NOTIFY.error(err);
    //   }
    // );
  }

  public addToFavorites(slug: string) {
    this.item.in_favorite = !this.item.in_favorite;
    this.favorites.click(slug);
  }

  public showInstallment(slug: string) {
    this.MALL.installmentModal.emit(slug);
  }
}
