import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { SharedService } from "src/app/modules/shared/services/shared.service";

@Component({
  selector: "mall-footer",
  templateUrl: "./mall-footer.component.html",
  styleUrls: ["./mall-footer.component.scss"],
})
export class MallFooterComponent implements OnInit {
  constructor(public NOTIFY: NotifyService, private SHARED: SharedService) {
    this.detectDevice();

    this.subscription = this.SHARED.staticData.subscribe((res) => {
      if (res) {
        this.socialUrls.facebook = res.social_facebook;
        this.socialUrls.instagram = res.social_instagram;
        this.socialUrls.whatsapp = res.social_whatsapp;
        this.socialUrls.telegram = res.social_telegram;
        this.socialUrls.twitter = res.social_twitter;
        this.socialUrls.youtube = res.social_youtube;
        this.socialUrls.left_text = res.footer_left_text;
        this.socialUrls.copyright = res.footer_copyright_left;
      }
    });
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  socialUrls = {
    facebook: "",
    instagram: "",
    whatsapp: "",
    telegram: "",
    twitter: "",
    youtube: "",
    left_text: "",
    copyright: "",
  };

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
