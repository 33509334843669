<section
  class="brands_carousel"
  [ngClass]="{ small: small_size == true }"
  *ngIf="carousel_state.items !== null"
>
  <owl-carousel-o
    [options]="customOptions"
    *ngIf="
      deviceType == 'desktop' &&
      carousel_state.items.length > carousel_state.max_item_count
    "
  >
    <ng-template carouselSlide *ngFor="let item of carousel_state.items">
      <div class="carousel_item__image">
        <ng-container *ngIf="item.image">
          <img
            [src]="item.image"
            alt="item.image_name"
            (click)="navigateToBrandsInner(item.id)"
          />
        </ng-container>

        <ng-container *ngIf="item.store_logo">
          <img
            [src]="item.store_logo"
            alt="item.id"
            (click)="navigateToBrandsInner(item.id)"
          />
        </ng-container>
      </div>
    </ng-template>
  </owl-carousel-o>

  <div
    class="brands_grid"
    *ngIf="
      deviceType == 'desktop' &&
      carousel_state.items.length <= carousel_state.max_item_count
    "
  >
    <ng-container *ngFor="let item of carousel_state.items">
      <div class="carousel_item__image">
        <ng-container *ngIf="item.image">
          <img
            [src]="item.image"
            alt="item.image_name"
            (click)="navigateToBrandsInner(item.id)"
          />
        </ng-container>

        <ng-container *ngIf="item.store_logo">
          <img
            [src]="item.store_logo"
            alt="item.id"
            (click)="navigateToBrandsInner(item.id)"
          />
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="brands_flex" *ngIf="deviceType == 'mobile'">
    <ng-container *ngFor="let item of carousel_state.items">
      <div class="carousel_item__image">
        <ng-container *ngIf="item.image">
          <img
            [src]="item.image"
            alt="item.image_name"
            (click)="navigateToBrandsInner(item.id)"
          />
        </ng-container>

        <ng-container *ngIf="item.store_logo">
          <img
            [src]="item.store_logo"
            alt="item.id"
            (click)="navigateToBrandsInner(item.id)"
          />
        </ng-container>
      </div>
    </ng-container>
  </div>
</section>
