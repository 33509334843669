<div class="container" *ngIf="state">
  <app-breadcrump [category]="breadcrump"></app-breadcrump>
  <page-heading [heading]="name" [subtitle]="total"></page-heading>
  <div class="category-carousel">
    <app-categories-slider [slides]="slides"></app-categories-slider>
  </div>

  <div class="category-list" *ngIf="children">
    <ng-container *ngFor="let child of children">
      <a
        *ngIf="child.image"
        class="category-item"
        [routerLink]="'/list'"
        [queryParams]="{ slug: child.slug, category_id: child.id }"
      >
        <img [src]="child.image" *ngIf="child.image" alt="{{ child.name }}" />
        <img
          src="https://via.placeholder.com/270x220"
          *ngIf="!child.image"
          alt="{{ child.name }}"
        />
        <p class="h6_med" [ngClass]="{ body_xs_med: deviceType == 'mobile' }">
          {{ child.name }}
        </p>
      </a>
    </ng-container>
  </div>

  <div class="brands_carousel_container" *ngIf="stores">
    <brands-carousel [items]="stores"></brands-carousel>
  </div>

  <div
    class="popular_container"
    *ngIf="cState.favorite_items && cState.favorite_items.items.length > 0"
  >
    <multiple-items-carousel
      [items]="cState.favorite_items.items"
      [max_item_count]="cState.favorite_items.max_item_count"
      [heading]="cState.favorite_items.heading"
      [show_all_url]="cState.favorite_items.show_all_url"
    >
    </multiple-items-carousel>
  </div>

  <div
    class="last_created_container"
    *ngIf="
      cState.last_created_items && cState.last_created_items.items.length > 0
    "
  >
    <multiple-items-carousel
      [items]="cState.last_created_items.items"
      [max_item_count]="cState.last_created_items.max_item_count"
      [heading]="cState.last_created_items.heading"
      [show_all_url]="cState.last_created_items.show_all_url"
    >
    </multiple-items-carousel>
  </div>

  <div
    class="birkart_container"
    *ngIf="cState.birkart_items && cState.birkart_items.items.length > 0"
  >
    <multiple-items-carousel
      [items]="cState.birkart_items.items"
      [max_item_count]="cState.birkart_items.max_item_count"
      [heading]="cState.birkart_items.heading"
      [show_all_url]="cState.birkart_items.show_all_url"
    >
    </multiple-items-carousel>
  </div>

  <div
    class="discount_container"
    *ngIf="cState.discount_items && cState.discount_items.items.length > 0"
  >
    <multiple-items-carousel
      [items]="cState.discount_items.items"
      [max_item_count]="cState.discount_items.max_item_count"
      [heading]="cState.discount_items.heading"
      [show_all_url]="cState.discount_items.show_all_url"
    >
    </multiple-items-carousel>
  </div>

  <div
    class="features_container container"
    *ngIf="cState.features_items.items.length > 0"
  >
    <features-cards></features-cards>
  </div>
</div>
