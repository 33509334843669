//======================================================================
import { Observable } from "rxjs/internal/Observable";
import { HttpEvent } from "@angular/common/http";
import { HttpHandler } from "@angular/common/http";
import { HttpInterceptor } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { variables } from 'src/environments/variables';
import { Language } from '../typings/Language.type';
import { initialLanguage } from '../data/languageOptions.data';
@Injectable()
//======================================================================
export class LanguageInterceptor implements HttpInterceptor {
  //======================================================================

  constructor() { }

  //======================================================================

  private getAcceptLanguage(): Language {
    if (localStorage.getItem(variables.local_storage.language) as Language) {
      return localStorage.getItem(variables.local_storage.language) as Language;
    } else {
      return initialLanguage;
    }
  }

  //======================================================================

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: { "Accept-Language": this.getAcceptLanguage() }
    });
    return next.handle(request);
  }

}//======================================================================

