import { Subscription } from "rxjs";
import { UserService } from "./../../../../../common/utils/user/user.service";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-profile-sidebar",
  templateUrl: "./profile-sidebar.component.html",
  styleUrls: ["./profile-sidebar.component.scss"],
})
export class ProfileSidebarComponent implements OnInit {
  public activeUser: any;

  constructor(public USER: UserService) {
    this.activeUser = this.USER.data;
    this.subscribe = this.USER.userDATA.subscribe((res) => {
      this.activeUser = res;
      // console.log(this.activeUser);
    });
  }

  @Input() public user: any;
  ngOnInit(): void {
    window.scroll(0, 0);
  }

  private subscribe: Subscription;

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscribe.unsubscribe();
  }
}
