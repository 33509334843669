import { Router } from "@angular/router";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "option-link",
  templateUrl: "./option-link.component.html",
  styleUrls: ["./option-link.component.scss"],
})
export class OptionLinkComponent implements OnInit {
  constructor(private ROUTER: Router) {}

  ngOnInit(): void {
    // window.scroll(0, 0);
    // this.detectColor();
    // this.detectActive();
  }

  @Input("option") public option;
  @Input("tooltipType") public tooltipType = 'data-tooltip-top';
  @Input("current") public current;
  @Output() public onFilterableSelect = new EventEmitter();
  public stateReady: boolean = false;

  detectColor() {
    if (this.option.value.includes("#") && this.option.value.length == 7) {
      this.option.is_color = true;
      this.option.color_code = this.option.value;
    } else {
      this.option.is_color = false;
      this.option.color_code = null;
    }
  }

  detectActive() {
    this.option.selected = false;
    this.current.map((each) => {
      if (each.value == this.option.id) {
        this.option.selected = true;
      }
    });

    this.stateReady = true;
  }

  public clickOpt() {
    this.onFilterableSelect.emit(this.option.slug);
    // this.ROUTER.navigate(["/product-details/any"], {
    //   queryParams: { path: this.option.slug },
    // });
  }
}
