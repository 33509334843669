import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/common/utils/user/profile.service";
import { UserService } from "src/app/common/utils/user/user.service";
import { DaysPassed } from "../../../../../common/utils/functions/daysPassed";

@Component({
  selector: "profile-security-details",
  templateUrl: "./profile-security-details.component.html",
  styleUrls: ["./profile-security-details.component.scss"],
})
export class ProfileSecurityDetailsComponent implements OnInit {
  constructor(
    public user: UserService,
    public profile: ProfileService,
    public ACTIVATED_ROUTER: ActivatedRoute
  ) {
    this.fetchSecurityDetails();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public sessions;
  public session;
  public sessionId = Number(this.ACTIVATED_ROUTER.snapshot.paramMap.get("id"));

  public fetchSecurityDetails() {
    this.profile.devicesById(this.sessionId).subscribe((RESPONSE) => {
      this.session = RESPONSE.body;
    });
  }

  public daysPassed = DaysPassed;
}
