import { Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { MallService } from "../../mall.service";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-list-slider",
  templateUrl: "./list-slider.component.html",
  styleUrls: ["./list-slider.component.scss"],
})
export class ListSliderComponent implements OnInit {
  public customOptions: OwlOptions = {};

  @Input() public slides: string[];

  constructor(private mallService: MallService, public router: Router) {}

  ngOnInit(): void {
    this.customOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      navText: ["", ""],
      nav: false,
      margin: 16,
      items: 1,
    };
  }

  public toProduct(id: number, path: any) {
    this.router.navigate([`/product-details/${id}`], {
      queryParams: { path: path },
    });
  }
}
