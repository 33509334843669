import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../../common/utils/user/user.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  constructor(private user: UserService, private router: Router) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.user.logout();
    this.router.navigate([""]);
  }
}
