<div class="container">
  <div class="orders" [ngClass]="{ not_main: !mainPage }">
    <div class="orders-grid-left" [ngClass]="{ opacity: !mainPage }">
      <div class="order-left">
        <app-profile-sidebar></app-profile-sidebar>
      </div>
    </div>
    <div class="orders-grid-right" [ngClass]="{ opacity: mainPage }">
      <div class="order-right">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
