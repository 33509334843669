<a
  class="index_category_banner"
  [style.background-image]="'url(' + item.image + ')'"
  [ngClass]="{ scaled: scaled == true }"
  *ngIf="state.ready"
  (click)="navByUrl()"
>
  <!-- <div class="left">
    <div class="category_heading">
      <small
        class="overline"
        [ngStyle]="{'color': state.foreground}"
        [ngClass]="{
          
          overline_2: deviceType == 'mobile',
          body_xs_demi: scaled == true
        }"
        >{{ state.category_name }}</small
      >
    </div>
    <div class="subcategory_heading">
      <h4
        class="h4_med"
        [ngStyle]="{ color: state.foreground }"
        [ngClass]="{
          body_m_med: deviceType == 'mobile' || scaled == true
        }"
      >
        {{ state.category_name }}
      </h4>
    </div>
    temp hided
    <div class="items_count">
      <p
        class="body_l_med"
        [ngStyle]="{ color: state.foreground }"
        [ngClass]="{
          body_xs_med: deviceType == 'mobile'
        }"
      >
        {{ state.products_count }} məhsul
      </p>
    </div>
  </div>

  <div class="right">
    <div class="image">
      <img [src]="state.banner_img" alt="" />
    </div>
  </div> -->
</a>

<!-- 
  [routerLink]="'/categories/' + state.slug"
  [queryParams]="{ category_id: state.category_id }" -->
