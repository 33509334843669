<!-- BREADCRUMP -->
<ul class="breadcrump-list">
  <li
    *ngFor="let cat of paths; index as i"
    [ngClass]="{ active: paths.length == i + 1 }"
  >
    <ng-container *ngIf="!cat.id && !cat.slug">
      <a [routerLink]="cat.path" *ngIf="paths.length != i + 1">{{
        cat.name | translate
      }}</a>
      <span *ngIf="paths.length == i + 1">{{ cat.name | translate }}</span>
    </ng-container>
    <ng-container *ngIf="cat.id && cat.slug">
      <ng-container *ngIf="paths.length != i + 1">
        <a
          *ngIf="withMainCategory && i != 1"
          [routerLink]="'/list'"
          [queryParams]="{ category_id: cat.id, slug: cat.slug }"
          >{{ cat.name | translate }}</a
        >
        <a
          *ngIf="withMainCategory && i == 1"
          [routerLink]="'/categories/' + cat.slug"
          [queryParams]="{ category_id: cat.id }"
          >{{ cat.name | translate }}</a
        >
      </ng-container>
      <span *ngIf="paths.length == i + 1">{{ cat.name | translate }}</span>
    </ng-container>
  </li>
  <!--    <li class="active">Seçilmişlər</li>-->
</ul>
<!-- ./BREADCRUMP -->
