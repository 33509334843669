<div class="filter_input">
  <input
    type="text"
    [placeholder]="placeholder"
    [value]="value"
    (input)="onInput($event.target.value)"
  />
  <div
    [ngClass]="{ active: value.length > 0 }"
    class="input_clearer fcc"
    (click)="clearInputVal()"
  >
    <i class="fas fa-times-circle"></i>
  </div>
</div>
