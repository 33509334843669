import { ClickOutsideModule } from "ng-click-outside";
import { AgmCoreModule } from "@agm/core";
import { NgxMaskModule } from "ngx-mask";
import { SignupComponent } from "./pages/signup/signup.component";
import { LoginComponent } from "./pages/login/login.component";
import { AuthComponent } from "./pages/auth.component";
import { HttpClientModule } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "src/app/app-routing.module";
import { FavoritesComponent } from "./pages/favorites/favorites.component";
import { MallModule } from "../mall/mall.module";

import { LogoutComponent } from "./pages/logout/logout.component";
import { ProfileInfoComponent } from "./pages/profile/profile-info/profile-info.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { ProfileSidebarComponent } from "./pages/profile/profile-sidebar/profile-sidebar.component";
import { ProfileLocationsComponent } from "./pages/profile/profile-locations/profile-locations.component";
import { ProfileHeadlineComponent } from "./pages/profile/profile-headline/profile-headline.component";
import { ProfileLocationsAddComponent } from "./pages/profile/profile-locations-add/profile-locations-add.component";
import { ProfileNotificationsComponent } from "./pages/profile/profile-notifications/profile-notifications.component";
import { ProfilePaymentsComponent } from "./pages/profile/profile-payments/profile-payments.component";
import { ProfileSecurityComponent } from "./pages/profile/profile-security/profile-security.component";
import { ProfileOrdersComponent } from "./pages/profile/profile-orders/profile-orders.component";
import { ProfileOrdersProductsComponent } from "./pages/profile/profile-orders-products/profile-orders-products.component";
import { ProfileOrdersFollowComponent } from "./pages/profile/profile-orders-follow/profile-orders-follow.component";
import { ProfileOrdersDetailsComponent } from "./pages/profile/profile-orders-details/profile-orders-details.component";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { environment } from "src/environments/environment";
import { ProfileSecurityDetailsComponent } from "./pages/profile/profile-security-details/profile-security-details.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DpDatePickerModule } from "ng2-date-picker";

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    SignupComponent,
    FavoritesComponent,
    LogoutComponent,
    ProfileInfoComponent,
    ProfileComponent,
    ProfileSidebarComponent,
    ProfileLocationsComponent,
    ProfileHeadlineComponent,
    ProfileLocationsAddComponent,
    ProfileNotificationsComponent,
    ProfilePaymentsComponent,
    ProfileSecurityComponent,
    ProfileOrdersComponent,
    ProfileOrdersProductsComponent,
    ProfileOrdersFollowComponent,
    ProfileOrdersDetailsComponent,
    ProfileSecurityDetailsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    AppRoutingModule,
    GooglePlaceModule,
    DpDatePickerModule,
    NgxMaskModule.forChild(),
    MallModule,
    FormsModule,
    SharedModule,
    ClickOutsideModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google.map_key,
      language: localStorage.getItem("lang"),
      region: "az",
    }),
  ],
})
export class AuthModule {}
