import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "tag-key",
  templateUrl: "./tag-key.component.html",
  styleUrls: ["./tag-key.component.scss"],
})
export class TagKeyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  @Input("remove") public remove: boolean = true;
  @Input("data") public data: {
    name: string;
    slug: string;
  };
}
