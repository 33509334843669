import { generateHeaderConfig } from "./../../common/utils/functions/generateHeaderConfig.function";
import { Level } from "./../shared/shared.model";
import { Observable } from "rxjs";
import { variables } from "src/environments/variables";
import {
  reqUserRegister,
  reqOneTimePass,
  resOneTimePass,
  reqUserLogin,
  resUserLogin,
  resRegisterData,
  UserData,
} from "./auth.models";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { NotifyService } from "../shared/services/notify.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private HTTP: HttpClient,
    private ROUTER: Router,
    private NOTIFY: NotifyService
  ) {}

  public getOneTimePass(body: reqOneTimePass): Observable<reqOneTimePass> {
    return this.HTTP.post<reqOneTimePass>("helpers/sms-code", body);
  }

  public registerAccount(
    body: reqUserRegister
  ): Observable<Level<resRegisterData>> {
    return this.HTTP.post<Level<resRegisterData>>("front/user/register", body);
  }

  public signInUser(body: reqUserLogin): Observable<Level<resUserLogin>> {
    return this.HTTP.post<Level<resUserLogin>>("front/user", body);
  }

  public getUserDetails(): Observable<Level<UserData>> {
    return this.HTTP.get<Level<UserData>>("front/user", {
      ...generateHeaderConfig(),
    });
  }

  public updateUserToken(): Observable<any> {
    return this.HTTP.patch<any>("front/user", null, {
      ...generateHeaderConfig(),
    });
  }

  public logOutUser(): Observable<any> {
    return this.HTTP.delete<any>("front/user", {
      ...generateHeaderConfig(),
    });
  }

  public ableToRenew = new EventEmitter();
  public updateToken() {
    if (localStorage.getItem("front_access_token")) {
      this.ableToRenew.emit(false);
      this.NOTIFY.startSpinner();
      this.updateUserToken().subscribe(
        (RESPONSE) => {
          variables.cookie.user_data.front_access_token =
            RESPONSE.body.access_token;
          variables.cookie.user_data.expires_at = RESPONSE.body.expires_at;
          localStorage.setItem(
            "front_access_token",
            RESPONSE.body.access_token
          );
          localStorage.setItem(
            "expires_at",
            RESPONSE.body.expires_at.toString()
          );

          this.getUserDetails().subscribe(
            (RESPONSE) => {
              variables.cookie.user_data.user = RESPONSE.body;
              localStorage.setItem("front_user", JSON.stringify(RESPONSE.body));
              this.ROUTER.navigate(["/"]);
              this.NOTIFY.stopSpinner();
              this.ableToRenew.emit(true);
            },
            (err) => {
              this.NOTIFY.stopSpinner();
              this.NOTIFY.error(err);
              localStorage.removeItem("UUID");
              localStorage.removeItem("front_user");
              localStorage.removeItem("front_access_token");
              this.ableToRenew.emit(true);
            }
          );
        },
        (err) => {
          this.NOTIFY.stopSpinner();
          this.NOTIFY.error(err);
          localStorage.removeItem("UUID");
          localStorage.removeItem("front_user");
          localStorage.removeItem("front_access_token");
          this.ableToRenew.emit(true);
        }
      );
    }
  }

  // public mergeCards(): Observable<any> {
  //   return this.HTTP.post<any>(
  //     "cart/api/v1/client/cart/merge",
  //     { uuid: localStorage.getItem("UUID") },
  //     { headers: { userToken: localStorage.getItem("front_access_token") } }
  //   );
  // }
}
