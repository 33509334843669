import { BlogDetailComponent } from "./pages/blog-detail/blog-detail.component";
import { BlogListComponent } from "./pages/blog-list/blog-list.component";
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BlogsComponent } from "./blogs.component";

const routes: Routes = [
  {
    path: "blogs",
    component: BlogsComponent,
    children: [
      {
        path: "",
        component: BlogListComponent,
      },
      {
        path: "detail/:id",
        component: BlogDetailComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [],
})
export class BlogsRoutingModule {}
