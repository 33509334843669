import { RenderStatusPipe } from "./../../common/pipes/status-pipe.pipe";
import { FormsModule } from "@angular/forms";
import { TextResizePipe } from "src/app/common/utils/pipes/text-resize.pipe";
import { ClickOutsideModule } from "ng-click-outside";
import { FullviewportLoadingComponent } from "./../../common/components/fullviewport-loading/fullviewport-loading.component";
import { NotificationPopupComponent } from "./../../common/components/notification-popup/notification-popup.component";
import { RouterModule } from "@angular/router";
import { ListTagComponent } from "./../../common/components/list-tag/list-tag.component";
import { OptionLinkComponent } from "./../../common/components/option-link/option-link.component";
import { ListFilterComponent } from "./../../common/components/list-filter/list-filter.component";
import { FilterInputComponent } from "./../../common/components/filter-input/filter-input.component";
import { PageHeadingComponent } from "./../../common/components/page-heading/page-heading.component";
import { SimpleSelectComponent } from "./../../common/components/simple-select/simple-select.component";
import { BasketSelectComponent } from "./../../common/components/basket-select/basket-select.component";
import { TranslateModule } from "@ngx-translate/core";
import { ProductCardComponent } from "../../common/components/product-card/product-card.component";
import { TagKeyComponent } from "./../../common/components/tag-key/tag-key.component";
import { ProductGridComponent } from "./../../common/components/product-grid/product-grid.component";
import { InputEventsDirective } from "./../../common/components/custom-input/inputEvents.directive";
import { CustomCheckboxComponent } from "./../../common/components/custom-checkbox/custom-checkbox.component";
import { CustomInputComponent } from "./../../common/components/custom-input/custom-input.component";

import { NgxMaskModule } from "ngx-mask";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { TogglerComponent } from "./components/toggler/toggler.component";
import { PaginationComponent } from "./components/pagination-component/pagination-component.component";
import { FeedbackModalComponent } from "./components/feedback-modal/feedback-modal.component";
import { CommentModalComponent } from "./components/comment-modal/comment-modal.component";
import { AddToCardModalComponent } from "./components/add-to-card-modal/add-to-card-modal.component";
import { FeaturesCardsComponent } from "../mall/components/features-cards/features-cards.component";

@NgModule({
  declarations: [
    BasketSelectComponent,
    CustomInputComponent,
    CustomCheckboxComponent,
    InputEventsDirective,
    ProductGridComponent,
    TagKeyComponent,
    ProductCardComponent,
    SimpleSelectComponent,
    PageHeadingComponent,
    FilterInputComponent,
    ListFilterComponent,
    OptionLinkComponent,
    ListTagComponent,
    NotificationPopupComponent,
    FullviewportLoadingComponent,
    NotFoundComponent,
    TogglerComponent,
    PaginationComponent,
    TextResizePipe,
    FeedbackModalComponent,
    CommentModalComponent,
    AddToCardModalComponent,
    RenderStatusPipe,
    FeaturesCardsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskModule.forChild(),
    TranslateModule,
    RouterModule,
    ClickOutsideModule,
  ],
  exports: [
    RouterModule,
    CustomInputComponent,
    BasketSelectComponent,
    CustomCheckboxComponent,
    InputEventsDirective,
    ProductGridComponent,
    TagKeyComponent,
    ProductCardComponent,
    SimpleSelectComponent,
    PageHeadingComponent,
    FilterInputComponent,
    ListFilterComponent,
    OptionLinkComponent,
    ListTagComponent,
    NotificationPopupComponent,
    FullviewportLoadingComponent,
    TogglerComponent,
    PaginationComponent,
    TextResizePipe,
    FeedbackModalComponent,
    CommentModalComponent,
    AddToCardModalComponent,
    RenderStatusPipe,
    FeaturesCardsComponent,
  ],
})
export class SharedModule {}
