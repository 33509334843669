<div class="csp_modal carousel-modal" *ngIf="isOpened">
  <span class="close_modal" (click)="onCloseClick()"
    ><i class="far fa-times"></i
  ></span>
  <div class="crsl_modal_top large_image_gallery">
    <owl-carousel-o [options]="customOptions" #carousel>
      <ng-template carouselSlide *ngFor="let image of images; index as i">
        <div class="carousel_item">
          <img [src]="image" />
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>

  <div class="crsl_modal_bottom">
    <div class="carousel_thumb_grid">
      <div
        class="carousel_thumb_item"
        *ngFor="let image of images; index as i"
        (click)="setSliderTo(i)"
      >
        <img [src]="image" />
      </div>
    </div>
  </div>
</div>
