import { Component, OnInit } from "@angular/core";
import { MallService } from "../../mall.service";
import { ActivatedRoute } from "@angular/router";
import { NotifyService } from "src/app/modules/shared/services/notify.service";

@Component({
  selector: "app-brand",
  templateUrl: "./brand.component.html",
  styleUrls: ["./brand.component.scss"],
})
export class BrandComponent implements OnInit {
  public brand: any = {};
  public items: [];
  public slides: [];
  public state = false;
  public brandId: number;
  public breadcrump = [];
  top_brands: any[] = [];

  constructor(
    private MALL: MallService,
    private NOTIFY: NotifyService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.activatedRoute.paramMap.subscribe((path) => {
      window.scroll(0, 0);
      this.brandId = Number(path.get("id"));
      this.products.popular.show_all_url.store_id = this.brandId;
      this.products.discounting.show_all_url.store_id = this.brandId;
      this.products.birkart.show_all_url.store_id = this.brandId;
      this.products.latest.show_all_url.store_id = this.brandId;
      this.fetchProducts();
      this.getData();
    });
  }

  public getData(): void {
    this.MALL.getBrandById(this.brandId).subscribe(
      (RESPONSE) => {
        this.brand = RESPONSE.body;

        this.brand?.categories?.map((category) => {
          if (category.image?.length > 0) {
            this.top_brands.push(category);
          }
        });

        this.top_brands = this.top_brands.sort((a, b) => {
          return b?.products_count - a?.products_count;
        });
        this.top_brands.splice(3);

        console.log(this.top_brands);
        this.state = true;
        this.breadcrump = [
          { name: "Brands", path: "/brands" },
          { name: this.brand.name, path: `` },
        ];
      },
      (err) => {
        this.NOTIFY.error(err);
      }
    );
  }

  public products = {
    popular: {
      items: [],
      heading: "Populyar məhsullar",
      show_all_url: {
        order_by: "view",
        store_id: null,
        order_dir: "desc",
      },
      max_item_count: 6,
    },
    discounting: {
      items: [],
      heading: "Endirimli məhsullar",
      show_all_url: {
        type: "discount",
        store_id: null,
      },
      max_item_count: 6,
    },
    birkart: {
      items: [],
      heading: "BirKart products",
      show_all_url: {
        type: "birkart",
        store_id: null,
      },
      max_item_count: 6,
    },
    latest: {
      items: [],
      heading: "Yeni məhsullar",
      show_all_url: {
        order_by: "date",
        order_dir: "desc",
        store_id: null,
      },
      max_item_count: 6,
    },
  };

  public fetchProducts() {
    // fetch populars
    this.MALL.getProductsList({
      per_page: 20,
      order_by: "view",
      order_dir: "desc",
      store_id: this.brandId,
    }).subscribe((RESPONSE) => {
      this.products.popular.items = RESPONSE.body.data;
    });

    // fetch discounting
    this.MALL.getProductsList({
      per_page: 20,
      type: "discount",
      store_id: this.brandId,
    }).subscribe((RESPONSE) => {
      this.products.discounting.items = RESPONSE.body.data;
    });

    // fetch birkart
    this.MALL.getProductsList({
      per_page: 20,
      type: "birkart",
      store_id: this.brandId,
    }).subscribe((RESPONSE) => {
      this.products.birkart.items = RESPONSE.body.data;
    });

    // fetch latest
    this.MALL.getProductsList({
      per_page: 20,
      order_by: "date",
      order_dir: "desc",
      store_id: this.brandId,
    }).subscribe((RESPONSE) => {
      this.products.latest.items = RESPONSE.body.data;
    });
  }
}
