<div class="product_card" *ngIf="item" [ngClass]="{ static: static == true }">
  <div
    class="remove-icon fcc"
    (click)="removeItem(item.slug)"
    *ngIf="page == 'favorites'"
  >
    <i class="fal fa-times"></i>
  </div>
  <a
    [routerLink]="['/product-details', item.id]"
    [queryParams]="{ path: item.slug }"
  >
    <div class="product_card__image">
      <img src="{{ item.image }}" alt="{{ item.image }}" />
      <div class="product_status_block">
        <div
          *ngIf="
            item.price.original != item.price.discounted &&
            item.price.discounted
          "
          class="discount_block body_xs_med"
        >
          {{ item.discount }}%
        </div>
        <div *ngIf="item.new" class="new_item_block body_xs_med">
          {{ "new_item" | translate }}
        </div>
      </div>

      <!-- <div class="installment_badge" *ngIf="item.installment == 1">
        <img src="assets/icons/birkart_badge.png" alt="" />
      </div> -->
    </div>
    <div class="product_card__content">
      <div class="price_block">
        <strong
          class="body_l_demi"
          [ngClass]="{
            body_s_demi: deviceType == 'mobile'
          }"
          >{{
            item.price.discounted != null
              ? item.price.discounted
              : item.price.original
          }}
          &#8380;</strong
        >
        <small
          class="old_price body_m_reg"
          [ngClass]="{
            body_xs_med: deviceType == 'mobile'
          }"
          *ngIf="item.price.discounted != null"
          >{{ item.price.original }} &#8380;</small
        >
      </div>
      <p class="body_s_med">{{ item.name }}</p>
    </div>
  </a>
  <div class="links_block">
    <a
      class="btn btn-sm btn-fill to_cart_btn"
      (click)="addToCart(item.slug)"
      [ngClass]="{ active: item.in_cart == true }"
      ><ng-container *ngIf="item?.in_cart == false">
        {{ "add_to_cart" | translate }}
      </ng-container>
      <ng-container *ngIf="item?.in_cart == true">
        {{ "already_in_cart" | translate }}
      </ng-container>
    </a>
    <a
      class="to_favorites"
      [ngClass]="{ active: item.in_favorite == true }"
      (click)="addToFavorites(item.slug)"
    >
      <i class="fal fa-heart"></i>
    </a>
    <button
      class="birkart_btn"
      *ngIf="item.installment == 1"
      (click)="showInstallment(item.slug)"
    >
      <img src="assets/images/small_birkart.png" alt="" />
      <span class="body_s_med">{{ "Buy with BirKart" | translate }}</span>
    </button>
  </div>
</div>
