import { ActivatedRoute } from "@angular/router";
import { variables } from "src/environments/variables";
import { LanguageService } from "./common/utils/language/services/language.service";
import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "./modules/shared/services/shared.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    private spinner: NgxSpinnerService,
    private LANGUAGE: LanguageService,
    private SHARED: SharedService,
    private ACTIVATED_ROUTE: ActivatedRoute
  ) {
    this.subUser();
  }

  ngOnInit() {
    this.subUser();
    this.SHARED.fetchStatics();
  }

  // Temporary

  public subUser() {
    // this.LANGUAGE.switchLang('az');
  }

  jiraStatus: boolean = false;
  hideJira() {
    this.jiraStatus = !this.jiraStatus;
    let tag = document.querySelector<HTMLElement>("jdiv");
    if (this.jiraStatus && tag) {
      // tag.style.visibility = "";
      tag.classList.add("show");
    } else {
      // tag.style.visibility = "hidden";
      tag.classList.remove("show");
    }
  }
}
