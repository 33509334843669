export const variables = {
  href: {
    privacy_policy: "",
    help: "",
  },
  local_storage: {
    language: "app_language",
    lang: "",
  },
  cookie: {
    user_data: {
      front_access_token: "",
      user: {},
      expires_at: null,
    },
  },
};
