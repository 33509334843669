import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "filter-input",
  templateUrl: "./filter-input.component.html",
  styleUrls: ["./filter-input.component.scss"],
})
export class FilterInputComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  @Input("name") public name: string;
  @Input("value") public value: string;
  @Input("placeholder") public placeholder: string;
  @Output("inputOutput") inputOutput = new EventEmitter<any>();

  public timeOut;
  public onInput(value: string) {
    this.value = value;
    clearTimeout(this.timeOut);

    this.timeOut = setTimeout(() => {
      this.inputOutput.emit(this.value);
    }, 400);
  }

  public clearInputVal() {
    this.value = "";
    this.inputOutput.emit(this.value);
  }
}
