import { BrandCollection } from "./pages/brand-collection/list.component";
import { TranslateModule } from "@ngx-translate/core";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MallComponent } from "./pages/mall.component";
import { IndexComponent } from "./pages/index/index/index.component";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MallHeaderComponent } from "./pages/mall-header/mall-header.component";
import { MallFooterComponent } from "./pages/mall-footer/mall-footer.component";
import { LanguageSelectorComponent } from "./pages/mall-header/language-selector/language-selector.component";
import { ClickOutsideModule } from "ng-click-outside";
import { GeolocationComponent } from "./pages/mall-header/geolocation/geolocation.component";
import { HotlineCallComponent } from "./pages/mall-header/hotline-call/hotline-call.component";
import { CategoryNavComponent } from "./pages/mall-header/category-nav/category-nav.component";
import { SearchBlockComponent } from "./pages/mall-header/search-block/search-block.component";
import { SigninBlockComponent } from "./pages/mall-header/signin-block/signin-block.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { FullwidthCarouselBannerComponent } from "./components/fullwidth-carousel-banner/fullwidth-carousel-banner.component";
import { MultipleItemsCarouselComponent } from "./components/multiple-items-carousel/multiple-items-carousel.component";
import { IndexCategoryBannerComponent } from "./components/index-category-banner/index-category-banner.component";
import { BrandsCarouselComponent } from "./components/brands-carousel/brands-carousel.component";
import { ListComponent } from "./pages/list/list.component";
import { ListSliderComponent } from "./components/list-slider/list-slider.component";
import { FormsModule } from "@angular/forms";
import { ProductDetailsComponent } from "./pages/product-details/product-details.component";
import { BreadcrumpComponent } from "./partials/breadcrump/breadcrump.component";
import { ProductsSliderComponent } from "./components/products-slider/products-slider.component";
import { PhotoGalleryComponent } from "./components/photo-gallery/photo-gallery.component";
import { StoreComponent } from "./pages/store/store.component";
import { BrandsComponent } from "./pages/brands/brands.component";
import { BrandComponent } from "./pages/brand/brand.component";
import { BrandSliderComponent } from "./components/brand-slider/brand-slider.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { CategoriesSliderComponent } from "./components/categories-slider/categories-slider.component";
import { ReviewsPopupComponent } from "./components/reviews-popup/reviews-popup.component";
import { SharedModule } from "../shared/shared.module";
import { MainImageCarouselComponent } from "./components/main-image-carousel/main-image-carousel.component";
import { ShareModule } from "ngx-sharebuttons";
import { NgxMaskModule } from "ngx-mask";
import { PrivacyPolicyComponent } from "./pages/static-pages/privacy-policy/privacy-policy.component";
import { FagPageComponent } from "./pages/static-pages/fag-page/fag-page.component";
import { ShowAllComponent } from "./pages/show-all/show-all.component";
import { AboutPageComponent } from "./pages/about-page/about-page.component";
import { InstallmentModalComponent } from "./components/installment-modal/installment-modal.component";
import { ProductDetailsCommentsComponent } from "./pages/product-details/components/product-details-comments/product-details-comments.component";
import { BlogsModule } from "./pages/blogs/blogs.module";
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';


@NgModule({
  declarations: [
    MallComponent,
    IndexComponent,
    MallHeaderComponent,
    MallFooterComponent,
    LanguageSelectorComponent,
    GeolocationComponent,
    HotlineCallComponent,
    CategoryNavComponent,
    SearchBlockComponent,
    SigninBlockComponent,
    FullwidthCarouselBannerComponent,
    MultipleItemsCarouselComponent,
    IndexCategoryBannerComponent,
    BrandsCarouselComponent,
    ListComponent,
    ListSliderComponent,
    ProductDetailsComponent,
    BreadcrumpComponent,
    ProductsSliderComponent,
    PhotoGalleryComponent,
    StoreComponent,
    BrandsComponent,
    BrandComponent,
    BrandSliderComponent,
    CategoriesComponent,
    CategoriesSliderComponent,
    ReviewsPopupComponent,
    MainImageCarouselComponent,
    BrandCollection,
    PrivacyPolicyComponent,
    FagPageComponent,
    ShowAllComponent,
    AboutPageComponent,
    InstallmentModalComponent,
    ProductDetailsCommentsComponent,
    SearchResultsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    ClickOutsideModule,
    CarouselModule,
    FormsModule,
    SharedModule,
    ShareModule,
    NgxMaskModule.forChild(),
    BlogsModule,
    NgxImageZoomModule,
  ],
  exports: [
    BreadcrumpComponent,
    ReviewsPopupComponent,
    MultipleItemsCarouselComponent,
  ],
})
export class MallModule {}
