<section class="page_not_found">
  <div class="left">
    <div class="app_logo">
      <img src="../../../../../assets/icons/app_logo.svg" alt="" />
    </div>
    <div class="not_found_img">
      <img src="../../../../../assets/images/not_found_page.svg" alt="" />
    </div>
  </div>
  <div class="right">
    <div class="content">
      <strong class="h6_demi">{{ "Page not found" | translate }}</strong>
      <p class="body_s_med">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. In, molestiae!
      </p>
      <a class="btn btn-fill btn-med btn-max" [routerLink]="['']">
        {{ "To main page" | translate }}
      </a>
    </div>
  </div>
</section>
