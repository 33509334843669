import { variables } from "src/environments/variables";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "./../../auth.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { stringify } from "@angular/compiler/src/util";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { SharedService } from "src/app/modules/shared/services/shared.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(
    private AUTH: AuthService,
    private ROUTER: Router,
    private NOTIFY: NotifyService,
    private SHARED: SharedService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public localState = {
    signin_phone: "",
    masked_signin_phone: "",
    signin_request_sent: false,
    code: "",
    privacy_status: false,
    countDown: "",
    resendCodeStatus: true,
  };

  onInputChange(target, inputValue: any) {
    this.localState[target.name] = inputValue;
  }

  showLocalState(event: any) {
    this.localState.signin_phone = this.localState.signin_phone
      .replace("+994(", "")
      .replace(")", "")
      .split(" ")
      .join("");
    this.localState.masked_signin_phone = this.localState.signin_phone
      .split("")
      .splice(0, 5)
      .join("");
    this.NOTIFY.startSpinner();
    this.getOTP();
  }

  getOTP() {
    this.localState.resendCodeStatus = true;
    let requestTime = new Date().getTime() + 180000;

    let timeInterval = setInterval(() => {
      let difference = requestTime - new Date().getTime();
      let mins, sec, tempmins, tempsecs;
      mins = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      sec = Math.floor((difference % (1000 * 60)) / 1000);

      if (mins < 10) {
        tempmins = `0${mins}`;
      }

      if (sec < 10) {
        tempsecs = `0${sec}`;
      } else {
        tempsecs = sec;
      }

      if (difference < 0) {
        clearInterval(timeInterval);
        tempmins = "00";
        tempsecs = "00";
        this.localState.resendCodeStatus = false;
      }

      this.localState.countDown = `${tempmins}:${tempsecs}`;
    }, 1000);

    this.AUTH.getOneTimePass({
      phone: this.localState.signin_phone,
    }).subscribe(
      (RESPONSE) => {
        this.NOTIFY.stopSpinner();
        this.localState.signin_request_sent = true;
        // console.log(RESPONSE);
      },
      (err) => {
        this.NOTIFY.stopSpinner();
        this.NOTIFY.error(err);
      }
    );
  }

  privacyChecked(data: boolean) {
    this.localState.privacy_status = data;
  }

  validateGetPassword() {
    if (this.localState.signin_phone?.length == 9) {
      return true;
    } else {
      return false;
    }
  }

  validatePassCode() {
    return this.localState.code?.length == 4 ? true : false;
  }

  finishLogin() {
    this.NOTIFY.startSpinner();
    this.AUTH.signInUser({
      code: this.localState.code,
      phone: this.localState.signin_phone,
    }).subscribe(
      (RESPONSE) => {
        variables.cookie.user_data.front_access_token =
          RESPONSE.body.access_token;
        variables.cookie.user_data.expires_at = RESPONSE.body.expires_at;
        localStorage.setItem("front_access_token", RESPONSE.body.access_token);
        localStorage.setItem("expires_at", RESPONSE.body.expires_at.toString());

        this.AUTH.getUserDetails().subscribe(
          (RESPONSE) => {
            variables.cookie.user_data.user = RESPONSE.body;
            localStorage.setItem("front_user", JSON.stringify(RESPONSE.body));
            this.SHARED.relocateBack();
            this.NOTIFY.stopSpinner();
          },
          (err) => {
            this.NOTIFY.error(err);
            this.ROUTER.navigate(["auth/logout"]);
            this.NOTIFY.stopSpinner();
          }
        );
      },
      (err) => {
        this.NOTIFY.stopSpinner();
        this.NOTIFY.error(err);
      }
    );
  }
}
