import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "blog-cart",
  templateUrl: "./blog-cart.component.html",
  styleUrls: ["./blog-cart.component.scss"],
})
export class BlogCartComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() blog: any;
  @Input("card_with_margin") public card_with_margin: boolean = false;
}
