<app-profile-headline
  *ngIf="state"
  [title]=""
  [description]="order.created_at"
  [page]="'orders-products'"
  [order_id]="order.order_id"
  [products_count]="order.products_count"
>
</app-profile-headline>

<div class="all-product-block" *ngIf="state">
  <div class="list-box">
    <div class="grid_data">
      <div class="text">
        <h3 class="body_xs_med">{{ "Delivery address" | translate }}</h3>
        <p class="body_s_med">
          {{ order.address }}
        </p>
        <!-- <h3 class="body_xs_med">{{ formatDate(order.created_at) }}</h3>
        <p class="body_s_med">Sifariş No #{{ order.id }}</p> -->
      </div>
      <!-- <div class="text">
        <h3 class="body_xs_med">{{ "Payment method" | translate }}</h3>
        <p class="body_s_med">
          {{ order.payment_method | translate }}
        </p>
      </div> -->
      <div class="text">
        <h3 class="body_xs_med">
          {{ "Order time" | translate }}
        </h3>
        <p class="body_s_med">
          {{ order.created_at }}
        </p>
        <h3 *ngIf="order.scheduled" class="body_xs_med" style="margin-top: 5px">
          {{ "Delivery time" | translate }}
        </h3>
        <p  *ngIf="order.scheduled" class="body_s_med">
          {{ order.scheduled_at }}
        </p>
      </div>
      <div class="text">
        <h3 class="body_xs_med">{{ "Status" | translate }}</h3>
        <div class="body_s_med icon_block">
          <div
            class="status_icon"
            [innerHTML]="order.status_id | renderStatus"
          ></div>
          {{ order.status | translate }}
        </div>
      </div>
      <div class="text">
        <h3 class="body_xs_med">{{ "Price information" | translate }}</h3>
        <p class="body_s_demi">{{ order.final }} &#8380;</p>
      </div>
    </div>
  </div>
  <div class="products">
    <div class="pro-box" *ngFor="let item of order.products" [ngClass]="{'canceled': item.canceled == 1}">
      <div class="image fcc">
        <img
          *ngIf="item.image?.length > 0"
          src="{{ item.image }}"
          alt="{{ item.name }}"
        />
        <i *ngIf="item.image?.length == 0" class="fal fa-microchip"></i>
      </div>
      <div class="data-box">
        <h3 class="body_m_med">{{ item.name }}</h3>
        <div class="body_xs_med">
          {{ "Status" | translate }}: <b style="color: #9e227b">{{ item.status }}</b>
        </div>
        <ng-container *ngFor="let detail of item.current_variation">
          <div class="body_xs_reg">
            {{ detail.attribute_name }}:
            <ng-container
              *ngIf="
                detail.value_name.length == 7 && detail.value_name.includes('#')
              "
            >
              <div
                class="filterable_color"
                [style.backgroundColor]="detail.value_name"
              ></div>
              <b class="pl-4px">{{ detail.value_title }}</b>
            </ng-container>
            <ng-container
              *ngIf="
                detail.value_name.length != 7 ||
                !detail.value_name.includes('#')
              "
            >
              <b>{{ detail.value_name }}</b>
            </ng-container>
          </div>
        </ng-container>
        <div class="body_xs_reg">
          {{ "Product count" | translate }}: <b>{{ item.count }}</b>
        </div>
        <h4 class="body_s_med">
          {{ item.discount > 0 ? item.price.discounted : item.price.original }}
          &#8380;
        </h4>
      </div>
      <a *ngIf="item.commented && item.canceled == 0" class="comment-icon"
        ><i class="fal fa-comment-alt-check check-icon"></i
      ></a>
      <a (click)="showModal(item)" class="comment-icon" *ngIf="!item.commented && item.canceled == 0"
        ><i class="fal fa-comment-alt-plus comment-plus"></i
      ></a>
    </div>
  </div>
</div>
