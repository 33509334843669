import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "toggler",
  templateUrl: "./toggler.component.html",
  styleUrls: ["./toggler.component.scss"],
})
export class TogglerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.status = this.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.status = this.value;
  }

  @Input() public value: boolean = false;
  @Input() public label: string = null;
  @Input() public star: boolean = false;
  @Input() public name: string = null;
  @Output() public outPutValue = new EventEmitter();

  public status: boolean = false;

  public toggle() {
    this.status = !this.status;
    if (this.name == null) {
      this.outPutValue.emit(this.status);
    } else {
      this.outPutValue.emit({ name: this.name, value: this.status });
    }
  }
}
