import { Routes } from "@angular/router";
import { NgxMaskModule } from "ngx-mask";
import { SharedModule } from "./modules/shared/shared.module";
import { AuthModule } from "./modules/auth/auth.module";
import { MallModule } from "./modules/mall/mall.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LanguageInterceptor } from "./common/utils/language/interceptors/language.interceptors";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { hash } from "../tools/cache-control/hash.json";
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpsRequestInterceptor } from "src/app/common/utils/requests/interceptors/http-requests.interceptors";
import { FilterPipe } from "./common/pipes/filter.pipe";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";


@NgModule({
  declarations: [AppComponent, FilterPipe],
  imports: [
    SharedModule,
    AuthModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    NgxSpinnerModule,
    MallModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LoadingBarRouterModule,

    NgxMaskModule.forRoot(),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    { provide: "LOCALSTORAGE", useFactory: getLocalStorage },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}

export function getLocalStorage() {
  return typeof window !== "undefined" ? window.localStorage : null;
}

export function HttpLoaderFactory(HTTP: HttpClient) {
  const hashcode: string = hash;
  const suffix: string = ".json?v=" + hashcode;
  const path: string = "assets/i18n";
  return new MultiTranslateHttpLoader(HTTP, [
    { prefix: path + "/auth/auth.", suffix },
    { prefix: path + "/common/common.", suffix },
    // { prefix: path + "/auth/auth.", suffix },
  ]);
}
