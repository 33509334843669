<div
  class="category_nav"
  (clickOutside)="onClickedOutside($event)"
  [clickOutsideEnabled]="true"
  *ngIf="deviceType == 'desktop'"
>
  <div
    class="category_toggler btn btn-med btn-fill"
    (click)="toggleCategory($event)"
  >
    <div class="icon_holder">
      <i class="far fa-bars" *ngIf="state.toggleStatus == false"></i>
      <i class="fal fa-times" *ngIf="state.toggleStatus == true"></i>
    </div>
    <span class="body_m_med">{{ "category_nav_title" | translate }}</span>
  </div>

  <ng-container *ngIf="categories_nav">
    <div
      class="category_nav__content container_100"
      *ngIf="state.toggleStatus == true"
    >
      <div class="categories_nav_wrapper">
        <aside class="side_nav">
          <div
            class="category_link body_s_med"
            *ngFor="let category of state.categories_nav; let i = index"
            (click)="navigateToCategories(category.slug, category.id)"
            (mouseover)="showSubcategories(i)"
          >
            {{ category.name ? category.name : "Empty_name" }}
          </div>
        </aside>
        <div class="main_content">
          <div #selectedCategoriesDiv class="selected_subcategories">
            <div
              class="each_subcategory"
              *ngFor="let subcategory of state.active_subcategories"
            >
              <span
                class="body_s_demi"
                (click)="navigateTo(subcategory.id, subcategory.slug)"
                >{{ subcategory.name }}</span
              >
              <div
                class="each_subcategory__link body_s_reg"
                *ngFor="let link of subcategory.children"
                (click)="navigateTo(link.id, link.slug)"
              >
                {{ link.name }}
              </div>
            </div>
          </div>
          <div class="selected_categoryImage">
            <div class="selected_category_imagecard">
              <img
                *ngIf="state.active_category_img"
                class="selected_category_imagecard__img"
                [src]="state.active_category_img"
                [alt]="'Category image'"
              />
              <div *ngIf="!state.active_category_img" class="icon_block">
                <i class="fal fa-phone-laptop"></i>
              </div>
              <span class="h6_med">{{ state.active_category_label }}</span>
            </div>
          </div>
          <div
            class="selected_category_brands"
            *ngIf="state.active_brands && state.active_brands.length > 0"
            [style.width.px]="selectedCategoriesDiv.clientWidth"
          >
            <brands-carousel
              [small_size]="true"
              [max_item_count]="6"
              [items]="state.active_brands"
              [show_all_url]="false"
            ></brands-carousel>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
