import {
  Notify,
  NotifyService,
} from "./../../../../shared/services/notify.service";
import { MallService } from "src/app/modules/mall/mall.service";
import { Router } from "@angular/router";
import { variables } from "src/environments/variables";
import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../../../common/utils/user/user.service";
import { ProfileService } from "../../../../../common/utils/user/profile.service";
import { ProfileUpdate } from "../profile.model";
import { AuthService } from "../../../auth.service";

@Component({
  selector: "app-profile-info",
  templateUrl: "./profile-info.component.html",
  styleUrls: ["./profile-info.component.scss"],
})
export class ProfileInfoComponent implements OnInit {
  constructor(
    public user: UserService,
    private profile: ProfileService,
    private AUTH: AuthService,
    private ROUTER: Router,
    private NOTIFY: NotifyService
  ) {
    this.detectDevice();
  }

  public info: ProfileUpdate = {
    phone: null,
    firstname: null,
    lastname: null,
    gender: null,
    born_at: null,
    email: null,
    email_verified_at: null,
    marital: null,
  };
  public isBtnActive: boolean;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.fetchUserData();
  }

  public fetchUserData() {
    this.info.phone = this.user.data.phone;
    this.info.firstname = this.user.data.firstname;
    this.info.lastname = this.user.data.lastname;
    this.info.gender = this.user.data.gender;
    this.info.marital = this.user.data.marital;
    this.info.born_at = this.user.data.born_at;
    this.info.email = this.user.data.email;
    this.info.email_verified_at = this.user.data.email_verified_at;
  }

  public update_profile(): void {
    this.isBtnActive = true;
    this.NOTIFY.startSpinner();
    this.profile.update_info(this.info).subscribe(
      (res) => {
        this.AUTH.getUserDetails().subscribe(
          (RESPONSE) => {
            variables.cookie.user_data.user = RESPONSE.body;
            localStorage.setItem("front_user", JSON.stringify(RESPONSE.body));
            this.user.updateValues();
            this.NOTIFY.stopSpinner();
          },
          (err) => {
            this.ROUTER.navigate(["auth/logout"]);
            this.NOTIFY.stopSpinner();
          }
        );

        // localStorage.setItem("front_user", JSON.stringify(res));
        // this.user.updateValues();

        setTimeout(() => (this.isBtnActive = false), 1200);
      },
      (err) => {
        this.NOTIFY.stopSpinner();
        this.NOTIFY.error(err);
      }
    );
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }

  public dpDayPickerconfig = {
    firstDayOfWeek: "mo",
    format: "YYYY-MM-DD",
    locale: "az",
  };
}
