import { Router } from "@angular/router";
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "blog-carousel",
  templateUrl: "./blog-carousel.component.html",
  styleUrls: ["./blog-carousel.component.scss"],
})
export class BlogCarouselComponent implements OnInit {
  constructor(private ROUTER: Router) {
    this.detectDevice();
  }

  @Input("items") public items;
  @Input("max_item_count") public max_item_count;
  @Input("heading") public heading;
  @Input("show_all_url") public show_all_url: boolean = false;

  ngOnInit() {
    this.carousel_state.items = this.items;
    if (this.max_item_count != null) {
      this.carousel_state.max_item_count = this.max_item_count;
    }

    if (this.carousel_state.items < this.carousel_state.max_item_count) {
    }

    // if (this.carousel_state.items[0].discount_rate !== null) {
    //   this.carousel_state.items.forEach((item) => {
    //     item["old_price"] = `${(
    //       item.price.trim().split("&#8380;")[0] /
    //       ((100 - item.discount_rate) / 100)
    //     ).toFixed(1)}&#8380;`;
    //   });
    // }

    this.customOptions = {
      // autoplay: true,
      // autoplayTimeout: 5000,
      // autoplayHoverPause: true,
      loop: true,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      lazyLoad: true,
      navSpeed: 400,
      navText: ["", ""],
      nav: true,
      margin: 16,
      autoWidth: true,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 3,
        },
        860: {
          items: 4,
        },
        940: {
          items: 5,
        },
        1200: {
          items: 5,
        },
        1328: {
          items: this.carousel_state.max_item_count,
        },
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.carousel_state.items = this.items;
    if (this.max_item_count != null) {
      this.carousel_state.max_item_count = this.max_item_count;
    }
  }

  public carousel_state = {
    items: null,
    max_item_count: 6,
  };

  public customOptions: OwlOptions = {};

  public toProductInner(event: any, path: any) {
    this.ROUTER.navigate(path);
  }

  public showAll() {
    this.ROUTER.navigate(["/blogs"]);
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
