import { Directive, HostBinding, HostListener } from "@angular/core";

@Directive({
  selector: "[inpEvnts]",
})
export class InputEventsDirective {
  @HostBinding("style.background-color") private background = "#ffffff";
  @HostBinding("style.border") private border = "1px solid #e1e4e8";

  @HostListener("input", ["$event"]) onInput() {
    this.border = "1px solid #9E227B";
  }

  @HostListener("click", ["$event"]) onClick() {
    this.border = "1px solid #9E227B";
  }

  @HostListener("blur", ["$event"]) onBlur() {
    this.border = "1px solid #E3E5E4";
  }
}
