<div
  class="search_block"
  [ngClass]="{ active_input: state.input_active == true }"
  (clickOutside)="onClickedOutside($event)"
  [clickOutsideEnabled]="true"
>
  <div class="category_filter" *ngIf="!mobile">
    <div
      class="category_toggler"
      (click)="toggleSearchCategory($event)"
      [ngClass]="{ open: state.categoryToggleStatus }"
    >
      <span class="body_s_med">
        {{
          state.selected_category !== null
            ? (state.selected_category | textResize: 20)
            : ("all_categories" | translate)
        }}
      </span>
      <i class="far fa-chevron-down"></i>
    </div>
  </div>

  <div class="category_search">
    <input
      type="text"
      placeholder="{{ 'search_in_caspimall' | translate }}"
      (input)="onCategorySearchInput($event.target.value)"
      (keyup.enter)="onSearchEnter()"
      [value]="searchParams.keyword"
    />
  </div>

  <div class="clear_and_photo_div">
    <div
      class="clear_search"
      [ngClass]="{ fadein: searchParams.keyword?.length > 0 }"
      (click)="clearCategorySearch()"
    >
      <i class="fal fa-times"></i>
    </div>

    <div class="photo_search" (click)="toggleSearchBlock()">
      <i class="fal fa-camera"></i>
    </div>
  </div>

  <div
    class="category_filters_container"
    [ngClass]="{ open: state.categoryToggleStatus }"
    *ngIf="!mobile"
  >
    <div
      class="all_categories_btn body_s_med"
      (click)="acceptCategoryFilter(null, null)"
    >
      {{ "all_categories" | translate }}
    </div>
    <div class="all_categories_grid" *ngIf="state.categories_nav">
      <div
        class="each_category_btn body_s_med"
        *ngFor="let category of state.categories_nav"
        (click)="acceptCategoryFilter(category.id, category.name)"
      >
        {{ category.name ? category.name : "Empty_name" }}
      </div>
    </div>
  </div>

  <div
    class="search_results_container"
    [ngClass]="{ open: state.searchToggleStatus }"
  >
    <div class="search_results_grid">
      <!-- tags ============================ -->
      <ng-container *ngFor="let tag of searchResults.hashtags">
        <div class="search_tag fcc" (click)="navigateToTags(tag.slug)">
          <div class="search_tag_icon">
            <i class="far fa-search"></i>
          </div>
          <div class="search_tag_text">
            <p class="body_s_reg">
              {{ tag.name }}
              <!-- <b>{{ tag.name.split(" ")[0] }}</b>
              {{ tag.name.split(" ")[1] }} -->
            </p>
          </div>
          <div class="search_tag_link_icon">
            <i class="far fa-angle-right"></i>
          </div>
        </div>
      </ng-container>
      <!-- tags ============================ -->
      <!-- categories ============================ -->
      <ng-container *ngFor="let category of searchResults.categories">
        <a class="catalog_link fcc" (click)="toCategory(category)">
          <div class="catalog_link_text">
            <p class="body_s_reg text_overflow_1">{{ category.name }}</p>
            <small class="body_xs_reg text_overflow_1">
              <ng-container
                *ngFor="
                  let each_breadcrumb of category.breadcrumb;
                  let i = index
                "
              >
                {{ each_breadcrumb.name }}
                <ng-container *ngIf="i != category.breadcrumb?.length - 1"
                  >/</ng-container
                >
              </ng-container>
            </small>
          </div>
          <div class="catalog_link_img">
            <img
              *ngIf="category.image"
              [src]="category.image"
              [alt]="category.name"
            />
          </div>
          <div class="catalog_link_link_icon">
            <i class="far fa-angle-right"></i>
          </div>
        </a>
      </ng-container>
      <!-- categories ============================ -->

      <!-- stores -->
      <ng-container *ngFor="let store of searchResults.stores">
        <a
          [routerLink]="['/brand/' + store.id]"
          class="store_link fcc"
          (click)="afterRedirect()"
        >
          <div class="store_link_text">
            <p class="body_s_reg text_overflow_1">{{ store.name }}</p>

            <div class="store_icon_block">
              <img [src]="store.image" alt="" />
            </div>
          </div>
          <div class="store_link_link_icon">
            <i class="far fa-angle-right"></i>
          </div>
        </a>
      </ng-container>
      <!-- stores -->
      <!-- product ============================ -->
      <ng-container *ngFor="let item of searchResults.products; let i = index">
        <a
          [routerLink]="['/product-details', item.id]"
          [queryParams]="{ path: item.slug }"
          class="search_result_link"
          (click)="afterRedirect()"
        >
          <div class="link_container">
            <div class="search_result_link__left">
              <strong class="body_s_reg text_overflow_1">
                {{ item.name }}
              </strong>
              <small class="body_xs_med text_overflow_1">
                {{
                  item.price.discounted
                    ? item.price.discounted
                    : item.price.original
                }}
                &#8380;
              </small>
            </div>
            <div class="search_result_link__right">
              <div class="search_result_link__img">
                <img *ngIf="item.image" [src]="item.image" [alt]="item.name" />
                <i *ngIf="!item.image" class="far fa-border-all"></i>
              </div>
              <div class="search_result_link__arrow">
                <i class="far fa-angle-right"></i>
              </div>
            </div>
          </div>
        </a>
      </ng-container>

      <!-- product ============================ -->
    </div>
  </div>

  <div class="photo_search_container" [ngClass]="{ open: searchBlockStatus }">
    <div class="file_load_block" (click)="hiddenSearchFile.click()">
      <input
        type="file"
        name="searchFile"
        #hiddenSearchFile
        (change)="FileInputChange($event.target)"
      />
      <button class="btn btn-fill btn-xs">
        <i class="fal fa-paperclip"></i>
        <span class="body_s_med ml-8px">{{ "Load file" | translate }}</span>
      </button>
      <p class="body_s_reg">
        {{ "Accepted size" | translate }}: <b class="body_s_med">5Mb.</b>
      </p>
      <p class="body_s_reg">
        {{ "Accepted format" | translate }}:
        <b class="body_s_med">JPG, JPEG, PNG</b>
      </p>
    </div>
  </div>

  <div class="submit_search" *ngIf="!mobile">
    <i class="far fa-search"></i>
  </div>
</div>
