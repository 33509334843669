<div class="container">
  <app-breadcrump [category]="breadcrump" *ngIf="state"></app-breadcrump>
  <page-heading
    [heading]="category.name"
    [subtitle]="this.pagination.total"
  ></page-heading>

  <div class="search_result_cntnr">
    <div class="search_bar_cntnr">
      <ng-container *ngIf="category.first_level_children != null">
        <div class="filters_wrppr">
          <div class="filters_category">
            <ul>
              <li *ngFor="let subCategory of category.first_level_children">
                <a
                  [routerLink]="'/list' + subCategory.url_path"
                  *ngIf="category.level < 3"
                  >{{ subCategory.name }}</a
                >
              </li>
            </ul>
            <!--                    <div><a class="btn btn-show-more" href="">Daha çox göstər</a></div>-->
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let filter of filters">
        <list-filter [filter]="filter"></list-filter>
      </ng-container>

      <div class="price_range_div" *ngIf="filters">
        <strong class="body_s_demi"
          >{{ "Price range" | translate }} &#8380;</strong
        >
        <div class="inputs_container">
          <div class="each_inp_div">
            <span class="label body_xs_med">{{ "min" | translate }}</span>
            <input
              type="text"
              inputmode="numeric"
              mask="000000000"
              [value]="price_range.min"
              name="price_min"
              (input)="
                onPriceRangeChange($event.target.name, $event.target.value)
              "
            />
          </div>
          <div class="each_inp_div right">
            <span class="label body_xs_med"
              >{{ "max" | translate }} &#8380;</span
            >
            <input
              type="text"
              inputmode="numeric"
              mask="000000000"
              [value]="price_range.max"
              name="price_max"
              (input)="
                onPriceRangeChange($event.target.name, $event.target.value)
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="result_cntnr" *ngIf="state">
      <div class="product-carousel" *ngIf="category.id">
        <app-list-slider [slides]="null"></app-list-slider>
      </div>
      <div class="product_grid_div">
        <product-grid
          *ngIf="state"
          [products]="products"
          [tags]="tags"
          [page]="'list_page'"
          [sorting_options]="sort"
          [selected_sorting]="selected_sorting"
          (onFilterChange)="onSortingFilterChange($event)"
        >
        </product-grid>
        <h3 *ngIf="products.length == 0">No products</h3>
      </div>
    </div>
  </div>
</div>
