import { Level } from "./../../../modules/shared/shared.model";

import {
  createLocation,
  Location,
  ProfileUpdate,
} from "./../../../modules/auth/pages/profile/profile.model";
import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { generateHeaderConfig } from "../functions/generateHeaderConfig.function";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private user: UserService, private http: HttpClient) {}

  public update_info(data: ProfileUpdate): Observable<any> {
    return this.http.put<any>(`front/user`, data, {
      ...generateHeaderConfig(),
    });
  }

  public addresses(): Observable<Level<Location[]>> {
    return this.http.get<Level<Location[]>>(`front/user/address`, {
      ...generateHeaderConfig(),
    });
  }

  public currentAddress(id): Observable<Level<Location>> {
    return this.http.get<Level<Location>>(`front/user/address/${id}`, {
      ...generateHeaderConfig(),
    });
  }

  public updateAddress(id, data): Observable<any> {
    return this.http.put<any>(`front/user/address/${id}`, data, {
      ...generateHeaderConfig(),
    });
  }

  public setDefaultAddress(id): Observable<any> {
    return this.http.patch<any>(`front/user/address/${id}`, null, {
      ...generateHeaderConfig(),
    });
  }
  public removeAddress(id): Observable<any> {
    return this.http.delete<any>(`front/user/address/${id}`, {
      ...generateHeaderConfig(),
    });
  }

  public createAddress(data: createLocation): Observable<any> {
    return this.http.post<any>(`front/user/address`, data, {
      ...generateHeaderConfig(),
    });
  }

  public devices(): Observable<any> {
    return this.http.get<any>(`front/sessions`, {
      ...generateHeaderConfig(),
    });
  }

  public devicesById(id: number): Observable<any> {
    return this.http.get<any>(`front/sessions/${id}`, {
      ...generateHeaderConfig(),
    });
  }

  public orders(page: any = 1): Observable<any> {
    return this.http.get<any>(`front/orders`, {
      params: {
        page: page,
      },
      ...generateHeaderConfig(),
    });
  }

  public order(id): Observable<Level<any>> {
    return this.http.get<Level<any>>(`front/order/${id}/summary`, {
      ...generateHeaderConfig(),
    });
  }

  public orderDetails(id): Observable<Level<any>> {
    return this.http.get<Level<any>>(`front/order/${id}/details`, {
      ...generateHeaderConfig(),
    });
  }

  public checkTransaction(transaction_id: string): Observable<Level<any>> {
    return this.http.patch<Level<any>>(`order/check/${transaction_id}`, null, {
      ...generateHeaderConfig(),
    });
  }

  public getOrderTracking(id): Observable<Level<any>> {
    return this.http.get<Level<any>>(`front/order/${id}/tracking`, {
      ...generateHeaderConfig(),
    });
  }

  public notifications(page: any = 1): Observable<any> {
    return this.http.get<any>(`front/notifications`, {
      ...generateHeaderConfig(),
      params: {
        page: page,
      },
    });
  }

  public readNotification(id: string): Observable<any> {
    return this.http.patch<any>(`front/notifications/${id}`, null, {
      ...generateHeaderConfig(),
    });
  }
}
