import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { MallService } from "../../mall.service";
import { Location } from "@angular/common";
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { NavigationService } from "ngx-owl-carousel-o/lib/services/navigation.service";
import { generateTitle } from "../../../../common/utils/functions/generateTitle.function";

@Component({
  selector: "brand-collection",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class BrandCollection implements OnInit {
  public paths: [];
  public filters: [];
  public products: [];
  public filtered = [];
  public selected_sorting: {};
  public sort: any = {};
  public breadcrump = [];
  public activeRoute: string;
  public locationPath: string;
  public pricing: any = {};
  public category: any = {};
  public pagination: any = {};
  public state: boolean;
  public tags = [];
  public initialListParams = {
    per_page: 25,
    page: 1,
    url_path: "/collection",
    sort_item: "sort_weight",
    sort_dir: "desc",
    store_id: null,
    "collection_id[]": null,
  };

  constructor(
    private locationCore: Location,
    private activatedRoute: ActivatedRoute,
    private mallService: MallService,
    private router: Router,
    private Title: Title,
    private mall: MallService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.getUrlParams();
      }
    });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    // this.Title.setTitle(
    //   generateTitle(
    //     this.router.url.split("/brandcollection")[1].split("?")[0].split("/")[2]
    //   )
    // );
    let qty = 0;
    this.activatedRoute.queryParams.subscribe((parameters) => {
      if (qty === 0) {
        qty++;
        return;
      }
    });
  }

  public listParams = {
    per_page: null,
    page: null,
    sort_item: null,
    url_path: "/collection",
    sort_dir: null,
    store_id: null,
    "collection_id[]": null,
  };

  public getUrlParams() {
    let tempArray = {};
    this.listParams = null;
    tempArray = this.activatedRoute.snapshot.queryParamMap["params"];
    let obj = Object.assign({}, tempArray, this.initialListParams);
    this.listParams = obj;
    this.listParams.store_id = this.router.url
      .split("/collection")[1]
      .split("-")[0]
      .split("/")[1];
    this.listParams["collection_id[]"] = this.router.url
      .split("/collection")[1]
      .split("-")[1];

    const paramList = decodeURI(
      this.router.url.split("/collection")[1].split("?")[1]
    );

    let obj2 = [];
    paramList.split("&").map((param) => {
      obj2.push({ code: param.split("=")[0], value: param.split("=")[1] });
    });

    setTimeout(() => {
      this.updatePage(obj2);
    }, 100);
  }

  private updatePage(obj = null): void {
    this.mallService.getProductsList(this.listParams).subscribe((res) => {
      this.state = false;
      this.filters = res.filters;
      this.products = res.products;
      this.category = res.category;
      this.price_range.min = res.min_price;
      this.price_range.max = res.max_price;

      // if (this.category.first_level_children.length == 0) {
      //   this.category.first_level_children = null;
      // }

      this.pricing = { max: res.max_price, min: res.min_price };
      this.pagination = {
        total: res.total,
        per_page: res.per_page,
        page: res.page,
      };
      this.sort = res.sort;
      this.getSorting(this.listParams.sort_item);
      this.state = true;

      this.fillTags(obj);

      this.getBreadcrump();
    });
  }

  public getBreadcrump(): void {
    this.breadcrump = [];
    this.breadcrump.push({ name: "Brands", path: "/brands" });
    this.breadcrump.push({ name: "" });
    // this.breadcrump.push({name: })
  }

  public onFilterChanges(event, attribute) {
    // this.listParams[`${attribute}[]`] = event;
  }

  public price_range = {
    min: "",
    max: "",
    value: null,
  };

  public inputTimeOut;
  public onPriceRangeChange(name: string, value: string) {
    clearTimeout(this.inputTimeOut);
    this.inputTimeOut = setTimeout(() => {
      if (name == "price_min") {
        this.price_range.min = value;
      } else if (name == "price_max") {
        this.price_range.max = value;
      }
      this.checkRangeValues();
    }, 400);
  }

  public checkRangeValues() {
    this.price_range.value = `${this.price_range.min}-${this.price_range.max}`;

    if (this.price_range.min != "" || this.price_range.max != "") {
      this.listParams["price_range"] = this.price_range.value;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: "merge",
        queryParams: { price_range: this.price_range.value },
        replaceUrl: true,
      });
    }
    if (this.price_range.min == "" && this.price_range.max == "") {
      if (this.listParams["price_range"]) {
        delete this.listParams["price_range"];

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: "merge",
          queryParams: this.listParams,
          replaceUrl: true,
        });
      }
    }
  }

  public setPriceRangeFromUrl(value: string) {
    if (value !== null) {
      this.price_range.value = value;
      this.price_range.min = value.split("-")[0];
      this.price_range.max = value.split("-")[1];
    }
  }

  // get sorting

  public getSorting(value: string) {
    this.sort.map((item) => {
      if (item.value == value) {
        this.selected_sorting = item;
      }
    });
  }

  public onSortingFilterChange(event) {
    if (event) {
      if (this.listParams.sort_item != event.value) {
        this.listParams.sort_item = event.value;
        const selected = {
          sort_item: event.value,
        };
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: "merge",
          queryParams: selected,
          replaceUrl: true,
        });
      }
    }
  }

  public fillTags(obj) {
    this.tags = [];
    if (obj) {
      obj.map((item) => {
        this.filters.map((filter: {}) => {
          if (item.code.split("[]")[0] == filter["attribute_code"]) {
            filter["options"].map((opt) => {
              if (item.value == opt.value) {
                item["label"] = opt.label;
                this.tags.push(item);
              }
            });
          }
        });
      });
    }
  }

  // public coollectionData = {
  //   brandId: null,
  //   brandName: null,
  //   collection_id: null,
  //   collection_name: null,
  // };
}
