<div class="outer_container">
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide *ngFor="let slide of slides">
      <div class="item" style="background-image: url('{{ slide }}');">
        <!-- <div class="item" (click)="toProduct(slide.id, slide.path)"> -->
        <!-- <div class="content_side">
          <h5 class="h5_demi">{{ slide.title.label }}</h5>
          <p class="body_s_reg">{{ slide.sub_title.label }}</p>
          <strong class="h6_demi">{{ slide.price.label }}&#8380;</strong>
        </div>
        <div class="image_block">
          <img src="{{ slide.image_url }}" />
        </div> -->
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
