import { Router } from "@angular/router";
import { NotifyService } from "./../../../shared/services/notify.service";
import { ProductDetail } from "./../../mall.models";
import { MallService } from "src/app/modules/mall/mall.service";
import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  selector: "installment-modal",
  templateUrl: "./installment-modal.component.html",
  styleUrls: ["./installment-modal.component.scss"],
})
export class InstallmentModalComponent implements OnInit {
  constructor(
    public MALL: MallService,
    private NOTIFY: NotifyService,
    private ROUTER: Router
  ) {
    this.detectDevice();
    this.MALL.installmentModal.subscribe((RESPONSE) => {
      this.slug = RESPONSE;
      if (this.slug != null) {
        this.NOTIFY.startSpinner();
        this.fetchProduct();
      }
    });
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }

  public slug: string = null;
  public product: ProductDetail = null;
  public count: number = 1;

  ngOnInit(): void {}

  public closeModal() {
    this.MALL.installmentModal.emit(null);
    this.product = null;
  }

  public fetchProduct() {
    this.MALL.getProductDetails(this.slug).subscribe(
      (res) => {
        this.product = res.body;
        this.installmentMonthOptions = res.body?.installment_months;
        if (this.product?.installment == 0) {
          this.NOTIFY.set({
            text: "Unable to buy this product by installment",
            status: "warning",
            seconds: 4,
          });
        }
        this.NOTIFY.stopSpinner();
        
      },
      (err) => {
        this.NOTIFY.error(err);
        this.NOTIFY.stopSpinner();
      }
    );
  }

  public changeSelectedCount(event) {
    this.count = Number(event);
  }

  public changeProduct(event: string) {
    let slug = event;
    this.NOTIFY.startSpinner();
    this.MALL.getProductDetails(slug).subscribe(
      (res) => {
        this.product = res.body;
        this.slug = this.product.slug;
        this.installmentMonthOptions = res.body?.installment_months;
        this.count = 1;
        this.NOTIFY.stopSpinner();

        this.installmentMonthOptions = res.body?.installment_months;
        if (this.product?.installment == 0) {
          this.NOTIFY.set({
            text: "Unable to buy this product by installment",
            status: "warning",
            seconds: 4,
          });
        }
      },
      (err) => {
        this.NOTIFY.stopSpinner();
        this.NOTIFY.error(err);
      }
    );
  }

  public installmentMonthOptions = [];

  public selectedMonth: number = this.installmentMonthOptions[0];
  public selectMonth(numb: number) {
    this.selectedMonth = numb;
  }

  public navigateToCheckout() {
    this.ROUTER.navigate(["/basket/checkout"], {
      queryParams: {
        product_count: this.count,
        product_id: this.product.id,
        installment_month: this.selectedMonth,
      },
    });

    this.MALL.installmentModal.emit(null);
    this.slug = null;
    this.product = null;
  }

  @HostListener("document:keydown.escape", ["$event"])
  public onKeydownHandler(event: KeyboardEvent) {
    this.closeModal();
  }
}
