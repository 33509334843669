<section class="feedback_modal" [ngClass]="{ active: modalStatus }">
  <div class="white_block">
    <div class="feedback_title d-f ai-c jc-sb">
      <div class="body_m_med t_black_900 pr-32px">
        {{ "feedback" | translate }}
      </div>
      <button
        class="close_feedback_btn fcc"
        (click)="NOTIFY.hideFeedBackModal()"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
    <div class="feedback_form">
      <div class="info_block">
        <div class="icon">
          <i class="fas fa-exclamation-circle"></i>
        </div>
        <p class="body_s_reg">
          {{
            "We recommend using a chatbot to contact us directly" | translate
          }}
        </p>
      </div>

      <custom-input
        [labelHead]="'fullname' | translate"
        [labelFor]="'formData.fullname'"
        class="mb-24px"
      >
        <input
          #customInput
          type="text"
          name="fullname"
          autocomplete="off"
          [(ngModel)]="formData.fullname"
          [disabled]="disableFullname ? true : false"
        />
      </custom-input>
      <custom-input
        [labelHead]="'phone_number' | translate"
        [labelFor]="'phone'"
        class="mb-24px"
      >
        <input
          #customInput
          type="text"
          name="phone"
          inputmode="numeric"
          prefix="+994"
          mask="(00) 000 00 00"
          [(ngModel)]="formData.phone"
          autocomplete="off"
          [disabled]="disableFullname ? true : false"
        />
      </custom-input>
      <div class="form-group mb-24px">
        <label for="date" class="body_s_med">{{
          "Feedback type" | translate
        }}</label>
        <simple-select
          [options]="types"
          (selectedValue)="onSelectChange($event)"
        >
        </simple-select>
      </div>
      <custom-input
        [labelHead]="'Comment' | translate"
        [labelFor]="'formData.feedback'"
        class="mb-24px"
      >
        <textarea
          #customInput
          inpEvnts
          type="text"
          name="feedback"
          autocomplete="off"
          [(ngModel)]="formData.feedback"
        ></textarea>
      </custom-input>
      <button class="btn btn-max btn-fill btn-med" (click)="sendFeedback()">
        {{ "btn_send" | translate }}
      </button>
    </div>
  </div>
</section>
