import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../../../common/utils/user/user.service";
import { ProfileService } from "../../../../../common/utils/user/profile.service";
import { Location } from "../profile.model";
import { NotifyService } from "src/app/modules/shared/services/notify.service";

@Component({
  selector: "app-profile-locations",
  templateUrl: "./profile-locations.component.html",
  styleUrls: ["./profile-locations.component.scss"],
})
export class ProfileLocationsComponent implements OnInit {
  constructor(
    public user: UserService,
    public router: Router,
    public NOTIFY: NotifyService,
    private profile: ProfileService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.fetchAddresses();
  }

  public fetchAddresses() {
    this.profile.addresses().subscribe((res) => {
      this.addresses = res.body;

      this.addresses.map((address) => (address.status = false));
      // console.log(res);
    });
  }

  public addresses: Location[];

  public showDropdown(i: number) {
    this.addresses[i].status = true;
  }

  public onClickedOutside(i: number) {
    this.addresses[i].status = false;
  }

  public removeAddress(id: number) {
    this.profile.removeAddress(id).subscribe(
      (res) => {
        this.NOTIFY.success(res);
        this.fetchAddresses();
      },
      (err) => {
        this.NOTIFY.error(err);
      }
    );
  }
  public setDefAdd(id: number) {
    this.profile.setDefaultAddress(id).subscribe(
      (res) => {
        this.NOTIFY.success(res);
        this.fetchAddresses();
      },
      (err) => {
        this.NOTIFY.error(err);
      }
    );
  }
}
