import {
  Notify,
  NotifyService,
} from "./../../../modules/shared/services/notify.service";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UserService } from "./user.service";
import { Router } from "@angular/router";
import { MallService } from "src/app/modules/mall/mall.service";
import { generateHeaderConfig } from "../functions/generateHeaderConfig.function";
import { RES } from "src/app/modules/shared/shared.model";
import { ListProduct } from "src/app/modules/mall/mall.models";
import { SharedService } from "src/app/modules/shared/services/shared.service";

@Injectable({
  providedIn: "root",
})
export class FavoritesService {
  constructor(
    private user: UserService,
    private http: HttpClient,
    private router: Router,
    private MALL: MallService,
    private NOTIFY: NotifyService,
    private SHARED: SharedService
  ) {}

  public get(): Observable<RES<ListProduct[]>> {
    return this.http.get<RES<ListProduct[]>>("favorite", {
      ...generateHeaderConfig(),
    });
  }

  public edit(slug): Observable<any> {
    return this.http.patch<any>(`favorite/${slug}`, null, {
      ...generateHeaderConfig(),
    });
  }

  private animateButton(event): void {
    const element = event.currentTarget.classList.add("active");
    element.classList.add("fas");
  }

  public click(slug: string): void {
    // this.animateButton(event);
    if (!this.user.isAuthenticated) {
      this.SHARED.setPreviousPage.emit("favorite");
      this.router.navigate(["/auth/login"]);
    } else {
      this.NOTIFY.startSpinner();
      this.edit(slug).subscribe(
        (RESPONSE) => {
          this.NOTIFY.stopSpinner();
          this.NOTIFY.success(RESPONSE);
          this.MALL.setCounters();
        },
        (err) => {
          this.NOTIFY.stopSpinner();
          this.NOTIFY.error(err);
        }
      );
    }
  }
}
