import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "page-heading",
  templateUrl: "./page-heading.component.html",
  styleUrls: ["./page-heading.component.scss"],
})
export class PageHeadingComponent implements OnInit {
  constructor() {
    this.detectDevice();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  @Input("heading") public heading: string;
  @Input("subtitle") public subtitle: string;

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
