<app-profile-headline
  [title]="'Notifications'"
  [description]="
    'Keep track of notifications to keep track of your purchases and updates.'
  "
  [page]="'notifications'"
>
</app-profile-headline>

<div class="profile-data">
  <ng-container *ngFor="let item of notificationsList">
    <a
      [routerLink]="['/profile/orders/products/' + item?.order_id]"
      class="location-check"
      *ngIf="item?.order_id"
      (click)="readNotification(item)"
      [ngClass]="{ readed: item.read, unread: !item.read }"
    >
      <div class="check-title">
        <div class="icon"><i class="fal fa-th-large"></i></div>
        <div class="text">
          <h3 class="body_m_med">
            {{ item?.message }}, {{ "Order" | translate }}#
            {{ item?.order_id }}
          </h3>
          <p class="body_xs_med">
            {{ item.created_at }}
            <!-- {{
              item?.updated_at.replace(" ", "T") | date: "dd LLLL yyyy HH:mm"
            }} -->
          </p>
        </div>
      </div>
    </a>
    <div
      class="location-check"
      *ngIf="!item?.order_id"
      (click)="readNotification(item)"
      [ngClass]="{ readed: item.read, unread: !item.read }"
    >
      <div class="check-title">
        <div class="icon"><i class="fal fa-bell"></i></div>
        <div class="text">
          <h3 class="body_m_med">
            {{ item?.message }}
          </h3>
          <p class="body_xs_med">
            {{ item.created_at }}
            <!-- {{
              item?.updated_at.replace(" ", "T") | date: "dd LLLL yyyy HH:mm"
            }} -->
          </p>
        </div>
      </div>
    </div>
  </ng-container>

  <pagination-component
    *ngIf="notificationsList"
    [position]="'left'"
    [links]="links"
  ></pagination-component>

  <div class="empty_notifications" *ngIf="!notificationsList.length">
    <div class="icon_block fcc">
      <i class="fal fa-bell-slash"></i>
    </div>

    <strong class="h6_med">
      {{ "Empty notifications list" | translate }}
    </strong>
  </div>
</div>
