export const environment = {
  production: true,
  urls: {
    server: "https://api.caspimall.com/",
    domain: "https://caspimall.com/",
  },
  google: {
    map_key: "",
  },
  oneSignal: {
    appId: "",
  },
  pusher: {
    key: "4e79f836b9cac917b317",
    cluster: "ap2",
  },
};
