<div class="features_grid">
  <div class="feature_card" *ngFor="let item of items">
    <div class="feature_card__left">
      <i [class]="item.icon"></i>
    </div>
    <div class="feature_card__right">
      <strong
        class="body_l_med"
        [ngClass]="{
          body_m_med: deviceType == 'mobile'
        }"
        [innerHTML]="item.title"
      ></strong>
      <p
        class="body_s_reg"
        [ngClass]="{
          body_xs_reg: deviceType == 'mobile'
        }"
        [innerHTML]="item.description"
      ></p>
    </div>
  </div>
</div>
