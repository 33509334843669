<owl-carousel-o [options]="customOptions">
  <ng-template carouselSlide *ngFor="let slide of slides">
    <div class="item">
      <!-- <div> -->
      <!-- <h5 class="h5_demi">{{ slide.title }}</h5>
        <p class="body_s_reg">{{ slide.description }}</p> -->
      <!--                <strong>{{ slide.sub_title }}</strong> -->
      <!-- </div> -->
      <div>
        <img src="{{ slide }}" />
      </div>
    </div>
  </ng-template>
</owl-carousel-o>
