import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-blogs",
  template: "<router-outlet></router-outlet>",
})
export class BlogsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
