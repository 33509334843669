<div
  class="notifications_grid"
  [ngClass]="{ active: notifications.length > 0 }"
>
  <ng-container *ngFor="let notify of notifications">
    <div [className]="'notification_popup ' + notify.status">
      <div class="notification_text">
        <div class="icon_block fcc" [ngSwitch]="notify.status">
          <i *ngSwitchCase="'success'">
            <i class="fas fa-check-circle"></i>
          </i>
          <i *ngSwitchCase="'danger'">
            <i class="fas fa-times-circle"></i>
          </i>
          <i *ngSwitchCase="'warning'">
            <i class="fas fa-exclamation-circle"></i>
          </i>
          <i *ngSwitchCase="'info'">
            <i class="fas fa-info-circle"></i>
          </i>
        </div>
        <p class="body_s_reg">{{ notify.text | translate }}</p>
      </div>
      <div class="notification_close">
        <button class="icon_block fcc">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>
