import { Component, Input, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-products-slider",
  templateUrl: "./products-slider.component.html",
  styleUrls: ["./products-slider.component.scss"],
})
export class ProductsSliderComponent implements OnInit {
  public customOptions: OwlOptions = {};

  @Input() public items: [];

  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      navSpeed: 700,
      navText: ["", ""],
      nav: false,
      margin: 16,
      responsive: {
        0: {
          items: 2,
        },
        400: {
          items: 3,
        },
        740: {
          items: 4,
        },
        940: {
          items: 5,
        },
        1328: {
          items: 6,
        },
      },
    };
  }
}
