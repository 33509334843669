import { NgxSpinnerService } from "ngx-spinner";
import { MallService } from "./../../../mall.service";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  SimpleChanges,
  HostListener,
} from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { NotifyService } from "src/app/modules/shared/services/notify.service";

@Component({
  selector: "search-block",
  templateUrl: "./search-block.component.html",
  styleUrls: ["./search-block.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SearchBlockComponent implements OnInit {
  constructor(
    private MALL: MallService,
    private ROUTER: Router,
    private NOTIFY: NotifyService,
    private spinner: NgxSpinnerService
  ) {
    this.checkIfMobile();
    ROUTER.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.state.categoryToggleStatus = false;
        this.state.searchToggleStatus = false;
      }
    });
  }

  @Input("categories_nav") public categories_nav;
  @Input("mobile") public mobile;

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.state.categories_nav = this.categories_nav;
  }

  private checkIfMobile() {
    if (!this.mobile) {
      this.mobile = false;
    }
  }

  public state = {
    categoryToggleStatus: false,
    searchToggleStatus: false,
    categories_nav: null,
    selected_category: null,
    input_active: false,
  };

  toggleSearchCategory(event) {
    this.state.searchToggleStatus = false;
    this.state.categoryToggleStatus = !this.state.categoryToggleStatus;
  }

  onClickedOutside(event) {
    this.state.categoryToggleStatus = false;
    this.state.searchToggleStatus = false;
    this.searchBlockStatus = false;
  }

  acceptCategoryFilter(id: number, name: string) {
    this.state.selected_category = name;
    this.state.categoryToggleStatus = false;
    this.state.searchToggleStatus = false;
    this.searchParams.category_id = id;
    // this.searchParams.category_path = path;

    if (this.currentSearchValue?.length > 0) {
      this.onCategorySearchInput(this.currentSearchValue);
    }
  }

  public searchInputDelay;
  public searchParams = {
    keyword: null,
    category_id: null,
  };
  // public searchParams = {
  //   per_page: 6,
  //   page: 1,
  //   sort_item: "sort_weight",
  //   text: "",
  //   category_id: "",
  //   category_path: "",
  // };

  public searchResults = {
    products: [],
    hashtags: [],
    categories: [],
    stores: [],
  };

  public currentSearchValue: string;
  onCategorySearchInput(value: string) {
    this.currentSearchValue = value;
    this.state.input_active = true;
    this.state.categoryToggleStatus = false;
    this.searchBlockStatus = false;
    this.searchParams.keyword = value;
    clearTimeout(this.searchInputDelay);
    this.searchInputDelay = setTimeout(() => {
      this.MALL.getSearchResults(this.searchParams).subscribe((res) => {
        this.searchResults.products = res.body.products;
        this.searchResults.hashtags = res.body.hashtags;
        this.searchResults.categories = res.body.categories;
        this.searchResults.stores = res.body.stores;
      });
    }, 200);

    if (value.length == 0) {
      this.clearCategorySearch();
    } else {
      this.state.searchToggleStatus = true;
    }
  }

  onSearchEnter() {
    if (this.searchResults.hashtags?.length > 0) {
      this.navigateToTags(this.searchResults.hashtags[0].slug);
    } else if (this.searchResults.categories?.length > 0) {
      this.toCategory(this.searchResults.categories[0]);
    } else if (this.searchResults.stores?.length > 0) {
      this.ROUTER.navigate(["/brand/", this.searchResults.stores[0].id]);
    } else if (this.searchResults.products?.length > 0) {
      this.ROUTER.navigate(
        ["/product-details", this.searchResults.products[0].id],
        { queryParams: { path: this.searchResults.products[0].slug } }
      );
    }
  }

  public navigateToTags(tagname: string) {
    this.ROUTER.navigate(["/list"], { queryParams: { hashtag: tagname } });
  }

  clearCategorySearch() {
    this.searchParams.keyword = "";
    this.searchResults.products = [];
    this.searchResults.hashtags = [];
    this.searchResults.categories = [];
    this.searchResults.stores = [];
    this.state.searchToggleStatus = false;
    this.state.input_active = false;
  }

  public afterRedirect(): void {
    setTimeout(() => {
      this.clearCategorySearch();
    }, 200);
  }

  public toCategory(category: any): void {
    if (category?.breadcrumb.length == 1) {
      this.ROUTER.navigate(["/categories", category.breadcrumb[0].slug], {
        queryParams: { category_id: category.category_id },
      });
    }
    if (category?.breadcrumb.length > 1) {
      this.ROUTER.navigate(["/list"], {
        queryParams: {
          category_id: category.category_id,
          slug: category.breadcrumb[category.breadcrumb.length - 1].slug,
        },
      });
    }

    setTimeout(() => {
      this.clearCategorySearch();
    }, 200);
  }

  searchBlockStatus: boolean = false;

  toggleSearchBlock() {
    this.searchBlockStatus = !this.searchBlockStatus;
    this.state.categoryToggleStatus = false;
  }

  public FileInputChange(event) {
    this.NOTIFY.startSpinner();

    let form = new FormData();
    form.append("image", event.files[0]);
    this.MALL.getPhotoSearch(form).subscribe(
      (RESPONSE) => {
        this.MALL.searchResultsBody.emit(RESPONSE.body);
        console.log("otpravleno");
        this.searchBlockStatus = false;
        this.ROUTER.navigate(["/search"]);
        this.NOTIFY.stopSpinner();
      },
      (err) => {
        this.NOTIFY.stopSpinner();
        this.NOTIFY.set({
          text: err.error.message,
          status: err.error.level,
          seconds: 4,
        });
      }
    );

    // this.outPutValue.emit({ name: this.name, value: event.files[0] });
  }

  // arrowkeyLocation = 0;

  // @HostListener("document:keydown", ["$event"])
  // keyDown(event: KeyboardEvent) {
  //   switch (event.keyCode) {
  //     case 38:
  //       if (this.arrowkeyLocation > 0) {
  //         this.arrowkeyLocation--;
  //       }
  //       break;
  //     case 40:
  //       this.arrowkeyLocation++;
  //       break;
  //   }
  // }
}
