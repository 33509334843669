<footer>
  <div class="top container">
    <div class="logo_container">
      <div class="logo">
        <a [routerLink]="['']">
          <img src="../../../../../assets/icons/app_dark_logo.svg" alt="" />
        </a>
      </div>
      <div class="paragraph">
        <p class="body_s_reg" [innerHTML]="socialUrls.left_text"></p>
      </div>
      <div class="socials">
        <a
          [href]="socialUrls.facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-facebook"></i>
        </a>
        <a
          [href]="socialUrls.instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-instagram"></i>
        </a>
        <a
          [href]="socialUrls.whatsapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-whatsapp"></i>
        </a>
        <a
          [href]="socialUrls.telegram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-telegram"></i>
        </a>
        <a
          [href]="socialUrls.twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-twitter"></i>
        </a>
        <a
          [href]="socialUrls.youtube"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>
    <nav class="navigations_container">
      <div class="each_nav" *ngIf="deviceType == 'mobile'">
        <language-selector></language-selector>
      </div>
      <div class="each_nav" *ngIf="deviceType == 'mobile'">
        <geolocation></geolocation>
      </div>
      <div class="each_nav">
        <strong class="each_nav__header body_s_med">{{
          "caspi_mall" | translate
        }}</strong>
        <a [routerLink]="['/about-page']" class="body_s_reg">{{
          "about_us" | translate
        }}</a>
        <a [routerLink]="['/blogs']" class="body_s_reg">{{
          "Blogs" | translate
        }}</a>
        <a
          *ngIf="deviceType == 'mobile'"
          [routerLink]="['/brands']"
          class="body_s_reg"
          >{{ "Brands" | translate }}</a
        >
        <a [routerLink]="['static/user-terms']" class="body_s_reg">{{
          "end_customer_agreement" | translate
        }}</a>
        <a [routerLink]="['/stores']" class="body_s_reg">{{
          "Became a seller" | translate
        }}</a>
      </div>
      <div class="each_nav">
        <strong class="each_nav__header body_s_med">{{
          "Help" | translate
        }}</strong>
        <a [routerLink]="['static/payment']" class="body_s_reg">{{
          "Payment" | translate
        }}</a>
        <a [routerLink]="['static/return']" class="body_s_reg">{{
          "Return" | translate
        }}</a>
        <a [routerLink]="['static/warranty']" class="body_s_reg">{{
          "Warranty" | translate
        }}</a>
        <a [routerLink]="['static/delivery']" class="body_s_reg">{{
          "Delivery" | translate
        }}</a>
        <a [routerLink]="['static/security']" class="body_s_reg">{{
          "Security" | translate
        }}</a>
      </div>
      <div class="each_nav">
        <strong class="each_nav__header body_s_med">{{
          "Legal" | translate
        }}</strong>
        <a [routerLink]="['static/privacy-policy']" class="body_s_reg">{{
          "Privacy policy" | translate
        }}</a>
        <a [routerLink]="['static/cookies']" class="body_s_reg">{{
          "Cookies" | translate
        }}</a>
        <!-- <a [routerLink]="['static/site-map']" class="body_s_reg">{{
          "Site map" | translate
        }}</a> -->
      </div>
      <div class="each_nav">
        <strong class="each_nav__header body_s_med">{{
          "other" | translate
        }}</strong>
        <div class="modal_btn body_s_reg" (click)="NOTIFY.showFeedBackModal()">
          {{ "feedback" | translate }}
        </div>
      </div>
      <!-- <div class="each_nav">
        <strong class="each_nav__header body_s_med">{{
          "help" | translate
        }}</strong>
        <a [routerLink]="['/about']" class="body_s_reg">{{
          "about_us" | translate
        }}</a>
        <a [routerLink]="['/about']" class="body_s_reg">{{
          "end_customer_agreement" | translate
        }}</a>
      </div>
      <div class="each_nav">
        <strong class="each_nav__header body_s_med">{{
          "legal" | translate
        }}</strong>
        <a [routerLink]="['/about']" class="body_s_reg">{{
          "about_us" | translate
        }}</a>
        <a [routerLink]="['/about']" class="body_s_reg">{{
          "end_customer_agreement" | translate
        }}</a>
      </div> -->
    </nav>

    <div class="mobile_apps">
      <strong class="body_s_med">{{ "mobile_apps" | translate }}</strong>
      <div class="links_container">
        <a href="http://apple.com" target="_blank" rel="noopener noreferrer">
          <img src="../../../../../assets/icons/app_store.svg" alt="" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.app.caspi_mall_android"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../../../../../assets/icons/play_store.svg" alt="" />
        </a>
      </div>
    </div>
  </div>
  <div class="bottom container">
    <div class="copyright">
      <p class="body_s_reg" [innerHTML]="socialUrls.copyright"></p>
    </div>

    <div class="payment_methods">
      <div class="image_block">
        <img src="assets/images/master_card.png" alt="" />
      </div>
      <div class="image_block ml-12px">
        <img src="assets/images/visa_card.png" alt="" />
      </div>
    </div>

    <div class="developer">
      <p class="body_s_reg">
        {{ "site_by_1" | translate }}
        <a
          class="body_s_med"
          href="http://safaroff.com"
          target="_blank"
          rel="noopener noreferrer"
          >{{ "Safaroff Agency" | translate }}</a
        >
        {{ "site_by_2" | translate }}
      </p>
    </div>
  </div>
</footer>
