import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-fag-page",
  templateUrl: "./fag-page.component.html",
  styleUrls: ["./fag-page.component.scss"],
})
export class FagPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
