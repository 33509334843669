import { ActivatedRoute } from "@angular/router";
import { ProfileService } from "./../../../../../common/utils/user/profile.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-profile-orders-details",
  templateUrl: "./profile-orders-details.component.html",
  styleUrls: ["./profile-orders-details.component.scss"],
})
export class ProfileOrdersDetailsComponent implements OnInit {
  constructor(
    private profile: ProfileService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.paramMap.subscribe((path) => {
      this.id = Number(path.get("id"));
      this.updateOrder();
    });
  }

  public id: number;
  public order: any;
  public state: boolean;

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  private updateOrder(): void {
    this.profile.orderDetails(this.id).subscribe((res) => {
      this.state = true;
      this.order = res.body;
    });
  }

  generateDeliveryType(number: number) {
    switch (number) {
      case 0:
        return "Urgent delivery (0.5-3 hours)";
        break;
      case 1:
        return "During the day";
        break;
      case 2:
        return "During the next day";
        break;
      case 3:
        return "At the scheduled time";
        break;
      default:
        break;
    }
  }
}
