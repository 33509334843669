import { MallService } from "src/app/modules/mall/mall.service";
import { Level } from "./../../../modules/shared/shared.model";
import { NotifyService } from "./../../../modules/shared/services/notify.service";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { UserService } from "./user.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { BasketService } from "src/app/modules/auth/pages/basket/basket.service";
import { generateHeaderConfig } from "../functions/generateHeaderConfig.function";

@Injectable({
  providedIn: "root",
})
export class CartService {
  @Output() basketCounterEmitter = new EventEmitter();

  constructor(
    private user: UserService,
    private http: HttpClient,
    private mall: MallService,
    private router: Router,
    private notify: NotifyService,
    private basket: BasketService
  ) {}

  public get() {
    return this.http.get<Level<any>>("cart", {
      ...generateHeaderConfig(),
    });
  }

  public getCartCount() {
    return this.http.get<any>("cart/count", {
      ...generateHeaderConfig(),
    });
  }

  public onChange(): any {
    return this.basketCounterEmitter;
  }

  private animateButton(event): void {
    const element = event.currentTarget;
    element.classList.add("btn-bordered");
  }

  public updateCount(slug: string, count: number): Observable<any> {
    return this.http.post<any>(
      `cart/${slug}`,
      { count: count },
      {
        ...generateHeaderConfig(),
      }
    );
  }

  public edit(slug): Observable<any> {
    return this.http.put<any>(`cart/${slug}`, null, {
      ...generateHeaderConfig(),
    });
  }

  public deleteProduct(slug): Observable<any> {
    return this.http.delete<any>(`cart/${slug}`, {
      ...generateHeaderConfig(),
    });
  }

  public delete(slug: string) {
    this.edit(slug).subscribe(
      (RESPONSE) => {
        this.notify.success(RESPONSE);
        this.mall.setCounters();
      },
      (err) => {
        this.notify.error(err);
      }
    );
  }

  // NEWS APIS~~~~~~~~~~~~~~~~~~~~~~~~

  public addToCart(slug: string) {
    this.edit(slug).subscribe(
      (RESPONSE) => {
        this.notify.success(RESPONSE);
        this.mall.setCounters();
      },
      (err) => {
        this.notify.error(err);
      }
    );
  }

  // NEWS APIS~~~~~~~~~~~~~~~~~~~~~~~~
}
