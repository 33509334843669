import { Component, OnInit } from "@angular/core";

@Component({
  selector: "geolocation",
  templateUrl: "./geolocation.component.html",
  styleUrls: ["./geolocation.component.scss"],
})
export class GeolocationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public state = {
    location_country: "Azərbaycan",
  };
}
