<div
  class="signin_block"
  (clickOutside)="onClickedOutside($event)"
  [clickOutsideEnabled]="true"
>
  <div
    class="signin_toggler"
    [ngClass]="{ open: state.toggleStatus == true }"
    (click)="redirectProfile($event)"
  >
    <i class="fal fa-user"></i>
    <span class="body_xs_med" *ngIf="!user.isAuthenticated">{{
      state.username | translate
    }}</span>
    <span class="body_xs_med" *ngIf="user.isAuthenticated"
      >{{ state.firstname }} {{ state.lastname.charAt(0) }}.</span
    >
    <div
      class="notification_block"
      *ngIf="user.isAuthenticated && state?.notification_count > 0"
    >
      <span class="caption">{{
        state.notification_count
          ? state.notification_count > 99
            ? "99+"
            : state.notification_count
          : 0
      }}</span>
    </div>
  </div>

  <div class="signin_content" [ngClass]="{ open: state.toggleStatus == true }">
    <a
      *ngIf="!user.isAuthenticated"
      class="btn btn-sm btn-fill"
      [routerLink]="['auth']"
      >{{ "signup" | translate }}</a
    >

    <a
      *ngIf="!user.isAuthenticated"
      class="btn btn-sm btn-bordered"
      [routerLink]="['auth/login']"
      >{{ "login" | translate }}</a
    >

    <a
      *ngIf="user.isAuthenticated"
      class="btn btn-sm btn-bordered"
      (click)="goProfile()"
      >{{ "Profile" | translate }}</a
    >
    <a
      *ngIf="user.isAuthenticated"
      class="btn btn-sm btn-fill"
      [routerLink]="['/auth/logout']"
      >{{ "Logout" | translate }}</a
    >
  </div>
</div>
