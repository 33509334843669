<ng-container *ngIf="localLinks?.length > 3">
  <div
    class="pagination mt-16px d-f ai-c"
    [ngClass]="{
      'jc-fs': position == 'left',
      'jc-c': position == 'center',
      'jc-fe': position == 'right'
    }"
  >
    <ng-container *ngFor="let link of localLinks">
      <div
        *ngIf="link.url || link.label == '...'"
        class="each_link body_s_reg mX-4px"
        [ngClass]="{ active: link.active }"
        (click)="onPageChange(link.url)"
        [innerHTML]="link.label"
      ></div>
    </ng-container>
  </div>
</ng-container>
