<app-profile-headline
  [title]=""
  [description]="order?.created_at"
  [page]="'orders-products-details'"
  [order_id]="order?.order_id"
  [products_count]="order?.products_count"
>
</app-profile-headline>

<div class="delivery-block">
  <div class="type-of-delivery-block">
    <div class="delivery-box">
      <h3 class="body_l_demi">
        {{ "Products" | translate }} ({{ order?.products_count }})
      </h3>
    </div>
    <ng-container *ngFor="let product of order?.products">
      <div class="delivery-box">
        <h6 class="body_s_reg">{{ product.name }}</h6>
        <p class="body_s_med">{{ product.final }} &#8380;</p>
      </div>
    </ng-container>

    <div class="delivery-box">
      <h5 class="body_s_med">{{ "final_price" | translate }}</h5>
      <p class="body_s_demi">{{ order?.final }} &#8380;</p>
    </div>
  </div>
  <div class="type-of-delivery-block">
    <div class="delivery-box">
      <h3 class="body_l_demi">{{ "Delivery" | translate }}</h3>
    </div>
    <div class="delivery-box">
      <h6 class="body_s_reg">{{ "Delivery type" | translate }}</h6>
      <p class="body_s_med">
        {{ generateDeliveryType(order?.shipment_type) | translate }}
      </p>
    </div>
    <div class="delivery-box">
      <h6 class="body_s_reg">{{ "Delivery address" | translate }}</h6>
      <p class="body_s_med">{{ order?.address }}</p>
    </div>
    <div class="delivery-box">
      <h6 class="body_s_reg">{{ "address_title" | translate }}</h6>
      <p class="body_s_med">{{ order?.address_type }}</p>
    </div>
  </div>
  <!-- <div class="type-of-delivery-block">
    <div class="delivery-box">
      <h3 class="body_l_demi">Çatdırılma zamanı</h3>
    </div>
    <div class="delivery-box">
      <h6 class="body_s_reg">Çatdırılma zamanı üsulu</h6>
      <p class="body_s_med">Təcili çatdırılma (2-4 saat)</p>
    </div>
    <div class="delivery-box">
      <h6 class="body_s_reg">Təqribi çatma vaxt</h6>
      <p class="body_s_med">27 iyul 2020 - 13:00</p>
    </div>
    <div class="delivery-box">
      <h6 class="body_s_reg">Çatdırılma vaxt</h6>
      <p class="body_s_med">27 iyul 2020 - 23:42</p>
    </div>
  </div> -->
  <div class="type-of-delivery-block">
    <div class="delivery-box">
      <h3 class="body_l_demi">{{ "Payment information" | translate }}</h3>
    </div>
    <div class="delivery-box">
      <h6 class="body_s_reg">{{ "Authorization code" | translate }}</h6>
      <p class="body_s_med">{{ order?.two_id ? order.two_id : "-" }}</p>
    </div>
    <!-- <div class="delivery-box">
      <h6 class="body_s_reg">Kartla ödəniş</h6>
      <p class="body_s_med">981238******2345</p>
    </div>
    <div class="delivery-box">
      <h6 class="body_s_reg">Servis</h6>
      <p class="body_s_med">support@caspimall.com</p>
    </div> -->
  </div>
  <div class="other-block" *ngIf="order?.stores?.length">
    <h3 class="body_l_demi">{{ "Brands" | translate }}</h3>
    <ng-container *ngFor="let brand of order?.stores">
      <a [routerLink]="['/brand/' + brand.id]" class="body_s_med">{{
        brand.name
      }}</a>
    </ng-container>
  </div>
</div>
