import { UserService } from "src/app/common/utils/user/user.service";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { generateTitle } from "src/app/common/utils/functions/generateTitle.function";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  constructor(
    private Title: Title,
    private router: Router,
    private ACTIVATED_ROUTE: ActivatedRoute
  ) {
    this.detectDevice();
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.detectPage();
      }
    });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.Title.setTitle(generateTitle("Profil məlumatları"));
  }

  public currentUrl: string;

  public mainPage: boolean = true;
  public detectPage() {
    this.currentUrl = this.ACTIVATED_ROUTE.snapshot["_routerState"].url;

    if (this.currentUrl.split("/").length > 2) {
      this.mainPage = false;
    } else {
      this.mainPage = true;
    }
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
