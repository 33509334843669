<section class="page_heading">
  <h5
    class="h5_demi product-title"
    [ngClass]="{
      h6_demi: deviceType == 'mobile'
    }"
  >
    {{ heading | translate }}
    <span class="title_desc">{{ subtitle }}</span>
  </h5>
</section>
