<section class="blog_detail">
  <h5 class="h5_demi blog_title">{{ blog?.title }}</h5>
  <small class="body_s_med blog_date">{{ blog?.created_at }}</small>
  <div class="cover_img">
    <img [src]="blog?.cover" alt="" />
  </div>

  <article class="blog_content" [innerHTML]="blog?.text"></article>

  <div class="gallery" *ngIf="blog?.gallery?.length > 0">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let item of blog?.gallery">
        <div class="gallery_img">
          <img [src]="item.original" alt="" />
        </div>
      </ng-template>
    </owl-carousel-o>

    <div class="gallery_imgs_grid">
      <div
        class="carousel_thumb_item"
        *ngFor="let image of blog?.gallery; index as i"
        (click)="setSliderTo(i)"
      >
        <img [src]="image.sm" />
      </div>
    </div>
  </div>
</section>

<section class="related_blogs container">
  <blog-carousel
    [items]="blog?.related"
    [max_item_count]="4"
    [heading]="'Blogs'"
  ></blog-carousel>
</section>
