import { Level } from "./../../../shared/shared.model";
import { generateHeaderConfig } from "src/app/common/utils/functions/generateHeaderConfig.function";

import { RES } from "src/app/modules/shared/shared.model";
import { Observable } from "rxjs";

import { HttpClient, HttpClientModule } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BlogsService {
  constructor(private HTTP: HttpClient) {}

  public getBlogsList(page: any = 1): Observable<RES<any>> {
    return this.HTTP.get<RES<any>>(`blog`, {
      params: {
        page: page,
      },
      ...generateHeaderConfig(),
    });
  }

  public getBlogsDetails(id: number): Observable<Level<any>> {
    return this.HTTP.get<Level<any>>(`blog/${id}`, {
      ...generateHeaderConfig(),
    });
  }
}
