import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-breadcrump",
  templateUrl: "./breadcrump.component.html",
  styleUrls: ["./breadcrump.component.scss"],
})
export class BreadcrumpComponent implements OnInit {
  public paths = [];

  @Input() public category: any;
  @Input() public withMainCategory: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.paths = this.category;
    if (this.paths) {
      this.paths.unshift({ name: "Home", path: "" });
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
