<div class="container" *ngIf="state">
  <app-breadcrump [category]="breadcrump"></app-breadcrump>
  <page-heading
    [heading]="'Favorites'"
    [subtitle]="products.length"
  ></page-heading>

  <div class="product_grid_container">
    <product-grid
      [products]="products"
      [page]="'favorites'"
      [sorting_options]="sorting_options"
      [selected_sorting]="selected_sorting"
      [hidden_list]="true"
      (onDeleteUpdateGrid)="onDeleteUpdate($event)"
    >
    </product-grid>
  </div>
</div>
