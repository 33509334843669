import { environment } from "./../../../../../environments/environment";
//Added these lines
//const httpRequest = new HttpRequest(<any>req.method, 'abcd.api.com/search');
//req = Object.assign(req, httpRequest);

import {
  HttpRequest,
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { AuthService } from "src/app/modules/auth/auth.service";

//======================================================================
@Injectable()
//======================================================================
export class HttpsRequestInterceptor implements HttpInterceptor {
  //======================================================================
  constructor(private NOTIFY: NotifyService, private AUTH: AuthService) {
    this.AUTH.ableToRenew.subscribe((RESPONSE) => {
      this.readyForRenewToken = RESPONSE;
    });
  }

  private readyForRenewToken: boolean = true;
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let reqUrl: string = environment.urls.server + req.url;
    if (req.url.includes("assets")) {
      reqUrl = req.url;
    } else if (req.url.includes(environment.urls.server)) {
      reqUrl = req.url;
    } else if (req.url.includes("http://") || req.url.includes("https://")) {
      reqUrl = req.url;
    }

    const dupReq = req.clone({
      url: reqUrl,
    });

    // return next.handle(dupReq);

    return next.handle(dupReq).pipe(
      // tap((event: HttpEvent<any>) => {
      //   if (event instanceof HttpResponse && event.status === 401) {
      //     this.AUTH.updateUserToken().subscribe((RESPONSE) => {
      //       this.AUTH.updateToken();
      //     });
      //   }
      // })
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (localStorage.getItem("front_access_token")) {
            if (this.readyForRenewToken) {
              this.AUTH.updateToken();
            }
          } else {
            localStorage.removeItem("UUID");
            localStorage.removeItem("front_user");
            localStorage.removeItem("front_access_token");
          }
        }
        return throwError(error);
      })
    );
  }
} //======================================================================
