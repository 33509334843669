import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { NotifyService } from "../../services/notify.service";
import { PaginationLink } from "../../shared.model";

@Component({
  selector: "pagination-component",
  templateUrl: "./pagination-component.component.html",
  styleUrls: ["./pagination-component.component.scss"],
})
export class PaginationComponent implements OnInit {
  constructor(
    public NOTIFY: NotifyService,
    public ROUTER: Router,
    public ACTIVATED_ROUTE: ActivatedRoute
  ) {}

  ngOnInit(): void {}
  @Input() public links: PaginationLink[];
  @Input() public position: "left" | "right" | "center" = "center";

  public localLinks: PaginationLink[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.localLinks = this.links;
  }

  public onPageChange(link: string) {
    let number = link.split("?page=")[1];
    this.ROUTER.navigate([], {
      relativeTo: this.ACTIVATED_ROUTE,
      queryParamsHandling: "merge",
      queryParams: { page: number },
      replaceUrl: true,
    });
    this.NOTIFY.startSpinner();
  }
}
