import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { BlogsService } from "./../../blogs.service";
import { Component, OnInit } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-blog-detail",
  templateUrl: "./blog-detail.component.html",
  styleUrls: ["./blog-detail.component.scss"],
})
export class BlogDetailComponent implements OnInit {
  constructor(
    private ROUTER: Router,
    private ACTIVATED_ROUTE: ActivatedRoute,
    private BLOGS: BlogsService,
    private NOTIFY: NotifyService
  ) {
    this.subscription = ROUTER.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.fetchBlog();
      }
    });
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  customOptions: OwlOptions = {};
  ngOnInit(): void {
    window.scroll(0, 0);

    this.customOptions = {
      // autoplay: true,
      // autoplayTimeout: 5000,
      // autoplayHoverPause: true,
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      lazyLoad: true,
      navSpeed: 400,
      navText: [
        "<i class='fal fa-arrow-circle-left'></i>",
        "<i class='fal fa-arrow-circle-right'></i>",
      ],
      nav: true,
      margin: 16,
      items: 1,
      autoWidth: true,
    };
  }

  public blog: any = null;
  public links: any = null;
  public blogId: any = 1;

  public fetchBlog() {
    this.NOTIFY.startSpinner();
    this.blogId = this.ACTIVATED_ROUTE.snapshot.paramMap.get("id");
    this.BLOGS.getBlogsDetails(this.blogId).subscribe(
      (res) => {
        this.blog = res.body;
        this.NOTIFY.stopSpinner();
        window.scroll(0, 0);
      },
      (err) => {
        this.NOTIFY.error(err);
        this.NOTIFY.stopSpinner();
        window.scroll(0, 0);
      }
    );
  }

  private simulateClick(elem): void {
    const evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    const canceled = !elem.dispatchEvent(evt);
  }

  public setSliderTo(index): void {
    const position = index + 1;
    const selector = document.querySelector(
      ".owl-dots .owl-dot:nth-child(" + position + "n)"
    );
    this.simulateClick(selector);
  }
}
