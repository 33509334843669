import { MallService } from "src/app/modules/mall/mall.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-show-all",
  templateUrl: "./show-all.component.html",
  styleUrls: ["./show-all.component.scss"],
})
export class ShowAllComponent implements OnInit {
  constructor(
    public ACTIVATED_ROUTE: ActivatedRoute,
    public MALL: MallService,
    private ROUTER: Router
  ) {
    ROUTER.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.getType();
      }
    });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public getType() {
    this.items = [];
    let type = this.ACTIVATED_ROUTE.snapshot["_routerState"].url
      .split("/show-all/")[1]
      .split("/")[0];
    let brandId = this.ACTIVATED_ROUTE.snapshot["_routerState"].url
      .split("/show-all/")[1]
      .split("/")[1];
    let tagText = "";
    if (type == "taglist") {
      tagText = decodeURI(
        this.ACTIVATED_ROUTE.snapshot["_routerState"].url
          .split("/show-all/")[1]
          .split("/")[1]
      );
    }
    switch (type) {
      case "index_favorites":
        this.fillIndexFavorites();
        break;
      case "index_discounts":
        this.fillIndexDiscount();
        break;
      case "index_last_created":
        this.fillIndexLC();
        break;
      case "index_installment":
        this.fillIndexInstallment();
        break;
      case "brand_popular":
        this.brandPopular(brandId);
        break;
      case "brand_last_created":
        this.brandLC(brandId);
        break;
      case "taglist":
        this.tagList(tagText);
        break;
      default:
        break;
    }
  }

  public items = [];

  public fillIndexFavorites() {
    this.MALL.getIndexSliders("popular").subscribe((RESPONSE) => {
      this.items = RESPONSE.products;
    });
  }

  public fillIndexDiscount() {
    this.MALL.getIndexSliders("discount").subscribe((RESPONSE) => {
      this.items = RESPONSE.products;
    });
  }

  public fillIndexLC() {
    this.MALL.getIndexSliders("last_created").subscribe((RESPONSE) => {
      this.items = RESPONSE.products;
    });
  }

  public fillIndexInstallment() {
    this.MALL.getIndexSliders("can_installment").subscribe((RESPONSE) => {
      this.items = RESPONSE.products;
    });
  }

  public brandPopular(id) {
    const params = {
      per_page: 1000000,
      page: 1,
      store_id: id,
    };
    this.MALL.getBrandSlidersById("popular", params).subscribe((res) => {
      this.items = res.data.products;
    });
  }

  public brandLC(id) {
    const params = {
      per_page: 1000000,
      page: 1,
      store_id: id,
    };
    this.MALL.getBrandSlidersById("last_created", params).subscribe((res) => {
      this.items = res.data.products;
    });
  }

  public tagSearchParams = {
    per_page: 1000000,
    page: 1,
    sort_item: "sort_weight",
    text: "",
    category_name: "",
    category_path: "",
  };

  public tagList(name: string) {
    this.tagSearchParams.text = name;

    this.MALL.getSearchResults(this.tagSearchParams).subscribe((res) => {
      this.items = res.products;
    });
  }
}
