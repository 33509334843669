import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/modules/shared/services/shared.service";

@Component({
  selector: "features-cards",
  templateUrl: "./features-cards.component.html",
  styleUrls: ["./features-cards.component.scss"],
})
export class FeaturesCardsComponent implements OnInit {
  constructor(private SHARED: SharedService) {
    this.detectDevice();
    this.subscription = this.SHARED.staticData.subscribe((res) => {
      if (res) {
        this.res = res;
        this.generateCardData();
      }
    });
    if (this.SHARED.staticKeys) {
      this.res = this.SHARED.staticKeys;
    }
    this.generateCardData();
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  private res: any = null;
  generateCardData() {
    this.items = [];
    for (let i = 1; i < 9; i++) {
      let obj = {
        title: "",
        icon: "",
        description: "",
      };
      obj.title = this.res?.[`services_${i}_title`];
      obj.description = this.res?.[`services_${i}_description`];
      obj.icon = this.SHARED.sharedFeatures[i - 1].icon;
      this.items.push(obj);
    }
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }

  public items: any = [];
}
