<ngx-loading-bar
  [height]="4"
  color="#9E227B"
  [includeSpinner]="false"
></ngx-loading-bar>
<ngx-spinner
  bdColor="rgba(255,255,255,0.4)"
  color="#9E227B"
  type="ball-scale-multiple"
  size="large"
></ngx-spinner>
<notification-popup></notification-popup>
<fullviewport-loading></fullviewport-loading>
<router-outlet></router-outlet>

<!-- <div class="jira_hider" (click)="hideJira()">Hide jira</div> -->
