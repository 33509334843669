<section class="blogs_list container">
  <a [routerLink]="['/blogs/detail/' + blogsList[0]?.id]" class="first_blog">
    <div class="blog_img">
      <img [src]="blogsList[0]?.cover" alt="" />
    </div>
    <div class="blog_content">
      <small
        class="blog_date body_l_reg"
        [innerHTML]="blogsList[0]?.created_at"
      ></small>
      <strong
        class="blog_title h5_demi"
        [innerHTML]="blogsList[0]?.title"
      ></strong>
      <p class="blog_text body_m_reg" [innerHTML]="blogsList[0]?.desc"></p>
    </div>
  </a>

  <div class="blogs_grid">
    <ng-container *ngFor="let blog of blogsList; let i = index">
      <blog-cart *ngIf="i != 0" [blog]="blog"></blog-cart>
    </ng-container>
  </div>

  <pagination-component
    *ngIf="blogsList"
    [position]="'center'"
    [links]="links"
  ></pagination-component>
</section>

<div class="features_container container">
  <features-cards></features-cards>
</div>
