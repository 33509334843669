import {  AfterViewInit, Component, ElementRef, OnInit, ViewChildren } from "@angular/core";
import { MallService } from "../../mall.service";
import { Location } from "@angular/common";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { FavoritesService } from "../../../../common/utils/user/favorites.service";
import { CartService } from "../../../../common/utils/user/cart.service";
import { UserService } from "../../../../common/utils/user/user.service";
import { Meta } from "@angular/platform-browser";
import { Hashtag } from "../../mall.models";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit, AfterViewInit {
  public path: string;
  public state: boolean;
  public breadcrump = [];
  public variations = [];
  public filterable = [];
  public current_variation: any[] = [];
  public product: any = {};
  public category: any = {};
  public store_other: any = {};
  public product_similars: any = {};
  public recent_viewed: any = {};
  public isGalleryOpened = false;
  public top_hastags: Hashtag[] = [];
  public product_id: number = null;
  public store_id: number = null;
  public specifications: any = { max: 4, loadMore: true, items: [] };
  public mainImg: any = null
  public showFixedBasket: boolean = false
  public tooltipBottom = 'data-tooltip-bottom'

  constructor(
    private mallService: MallService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public favorites: FavoritesService,
    public NOTIFY: NotifyService,
    public router: Router,
    public cart: CartService,
    public user: UserService,
    private meta: Meta
  ) {
    this.detectDevice();
    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.getProdComments();
      }
    });
  }


  ngOnInit(): void {
    window.scroll(0, 0);
    this.activatedRoute.paramMap.subscribe((path) => {
      window.scroll(0, 0);
      this.getItems();
    });


    document.addEventListener('scroll', function(e) {
      let lastKnownScrollPosition = window.scrollY;
    })
  }

  ngAfterViewInit(){
      const intervalScroll = setInterval(() => {
        const natEl = document.querySelectorAll('.product-small-view')[0];
        const scrolledEl = document.querySelectorAll('.product-nav-list')[0];

        if ( natEl && scrolledEl ){
          const elTop = natEl.getBoundingClientRect().top;
          const scrolledTop = scrolledEl.getBoundingClientRect().top;

          document.addEventListener('scroll', (e) => {
            const lastKnownScrollPosition = window.scrollY;

            if ( lastKnownScrollPosition > (elTop + scrolledTop)) {
              natEl.className = 'product-small-view display-show-basket';
            } else {
              natEl.className = 'product-small-view';

            }

            clearInterval(intervalScroll);
          });
        }
      }, 1000);
  }




  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getItems(): void {
    this.path = this.getPath();
    this.mallService.getProductDetails(this.path).subscribe((res) => {
      this.getProdComments();
      this.top_hastags = res.body.hashtags;
      this.product_id = res.body.id;
      this.store_id = res.body.store.id;
      this.product = res.body;
      this.filterable = res.body.filterable;
      this.current_variation = res.body.current_variation;
      this.variations = res.body.variations;
      // this.category = res.category;
      this.specifications.items = res.body.attributes;

      this.breadcrump = [{ name: this.product.name, path: null }];
      this.state = true;

      this.fetchStoreOthers();
      this.fetchSimilars();
      this.fetchRecent();

      this.mainImg = this.product.images.opened[0];

      this.meta.updateTag(
        { content: this.product.images.opened[0] },
        `name='fb_photo'`
      );
      this.meta.updateTag({ content: this.product.name }, `name='fb_title'`);
      this.meta.updateTag(
        { content: this.product.description },
        `name='fb_description'`
      );
    });
  }

  public comments: any = null;
  public comments_page: number = 1;
  public getProdComments() {
    this.comments_page = Number(
      this.activatedRoute.snapshot.queryParamMap.get("page")
    );
    this.comments = null;
    if (this.path) {
      this.mallService
        .getProductComments(this.path, this.comments_page)
        .subscribe((res) => {
          this.NOTIFY.stopSpinner();
          this.comments = res.body;
        });
    }
  }

  // Fetch storeOthers by ID
  private fetchStoreOthers() {
    this.store_other.items = this.product.other_from_store;
    this.store_other.loaded = true;
  }
  // Fetch storeOthers by ID

  // Fetch similar items by ID
  private fetchSimilars() {
    this.product_similars.items = this.product.related;
    this.product_similars.loaded = true;
  }
  // Fetch similar items by ID

  // Fetch recent items by ID
  private fetchRecent() {
    this.recent_viewed.items = this.product.recent_viewed;
    this.recent_viewed.loaded = true;
  }
  // Fetch recent items by ID

  private getPath(): string {
    return this.activatedRoute.snapshot.queryParamMap.get("path");
  }

  public showAllSpecs(): void {
    this.specifications.max = this.specifications.items.length;
    this.specifications.loadMore = false;
  }

  activeNavlink: number = 1;
  public scrollTo(className, id): void {
    const element = document.querySelector(className);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    this.activeNavlink = id;
  }

  selectedImageIndex: number = 0;
  public onGalleryClick(src): void {
    this.mainImg = src
  }

  public scrollImgDown(): void {
    const element = document.querySelector('.product-photos');
    console.log(element)
    element.scrollTo({
      behavior: "smooth",
      top: 200
    });
  }

  public scrollImgUp(): void {
    const element = document.querySelector('.product-photos');
    console.log(element)
    element.scrollTo({
      behavior: "smooth",
      top: -200
    });
  }

  public checkModalStatus(ev) {
    this.isGalleryOpened = ev;
  }

  public shareToggler: boolean = false;
  public toggleShareBlock() {
    this.shareToggler = !this.shareToggler;
  }

  public hideShareBlock() {
    this.shareToggler = false;
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }

  public clearProduct() {
    this.state = false;
    this.path = null;
    this.product_id = null;
    this.store_id = null;
    this.product = null;
    this.filterable = [];
    this.current_variation = [];
    this.variations = [];
    // this.category = res.category;
    this.specifications.items = [];

    this.breadcrump = [];
  }

  public addToFavorites(slug: string) {
    this.product.in_favorite = !this.product.in_favorite;
    this.favorites.click(slug);
  }

  public changeProduct(event: string) {
    let slug = event;
    this.clearProduct();
    this.router.navigate(["/product-details/any"], {
      queryParams: {
        path: slug,
      },
    });
    setTimeout(() => {
      this.getItems();
    }, 100);
  }

  public showIntallment(slug: string) {
    this.mallService.installmentModal.emit(slug);
  }

  public addToCart(slug: string) {
    if (this.product.in_cart == false && this.product.stock != 0) {
      this.product.in_cart = true;
      this.cart.addToCart(slug);
    }
    else if (this.product.stock == 0) {
      this.cart.addToCart(slug);
    }
  }

  public addToReminder(id: number) {
    if (!this.user.isAuthenticated) {
      this.router.navigate(["/auth/login"]);
    }else{
      this.NOTIFY.startSpinner();
      this.user.addToReminder(id).subscribe(
        (RESPONSE) => {
          this.NOTIFY.stopSpinner();
          this.NOTIFY.success(RESPONSE);
          this.mallService.setCounters();
        },
        (err) => {
          this.NOTIFY.stopSpinner();
          this.NOTIFY.error(err);
        }
      );;
    }
  }
}
