<ng-container>
  <div class="left-top">
    <div class="cube_logo fcc">
      <span class="cube_logo_name body_l_med">{{
        activeUser?.firstname ? activeUser?.firstname.charAt(0) : "S"
      }}</span>
      <span class="cube_logo_surname body_l_med">{{
        activeUser?.lastname ? activeUser?.lastname.charAt(0) : "S"
      }}</span>
    </div>
    <div class="name">
      <h2 class="body_m_med">
        {{ activeUser?.firstname }} {{ activeUser?.lastname }}
      </h2>
      <div class="d-f ai-c bonus_block">
        <div class="icon">
          <i class="fal fa-gift"></i>
        </div>
        <p class="body_xs_reg">
          {{ activeUser?.cashback ? activeUser?.cashback : "0" }} &#8380;
        </p>
      </div>
    </div>
  </div>

  <div class="click-tab">
    <a
      class="body_s_med"
      [routerLink]="['/profile/info']"
      [routerLinkActive]="'active'"
    >
      <div class="icon_block fcc">
        <i class="fal fa-user"></i>
      </div>
      {{ "Profile information" | translate }}</a
    >
  </div>
  <div class="click-tab">
    <a
      class="body_s_med"
      [routerLink]="['/profile/orders']"
      [routerLinkActive]="'active'"
    >
      <div class="icon_block fcc">
        <i class="fal fa-box-alt de-active"></i>
      </div>
      {{ "Orders" | translate }}</a
    >
  </div>
  <div class="click-tab">
    <a
      class="body_s_med"
      [routerLink]="['/profile/locations']"
      [routerLinkActive]="'active'"
    >
      <div class="icon_block fcc">
        <i class="fal fa-map-marker-alt"></i>
      </div>
      {{ "Your addresses" | translate }}</a
    >
  </div>
  <!-- <div class="click-tab">
  <a
    class="body_s_med"
    [routerLink]="['/profile/payments']"
    [routerLinkActive]="'active'"
  >
    <div class="icon_block fcc">
      <i class="fal fa-credit-card"></i>
    </div>
    {{ "Your payments" | translate }}</a
  >
</div> -->
  <div class="click-tab">
    <a
      class="body_s_med"
      [routerLink]="['/profile/notifications']"
      [routerLinkActive]="'active'"
    >
      <div class="icon_block fcc">
        <i class="fal fa-bell"></i>
      </div>

      {{ "Notifications" | translate }}

      <div
        class="notification_badge body_s_med"
        *ngIf="activeUser.notification_count"
      >
        {{ activeUser.notification_count ? activeUser.notification_count : 0 }}
      </div>
    </a>
  </div>
  <div class="click-tab">
    <a
      class="body_s_med"
      [routerLink]="['/profile/security']"
      [routerLinkActive]="'active'"
    >
      <div class="icon_block fcc">
        <i class="fal fa-shield-check"></i>
      </div>

      {{ "Profile security" | translate }}</a
    >
  </div>
  <div class="click-tab">
    <a class="body_s_med" [routerLink]="['/auth/logout']">
      <div class="icon_block fcc">
        <i class="fal fa-sign-out-alt"></i>
      </div>

      {{ "Sign out" | translate }}</a
    >
  </div>
</ng-container>
