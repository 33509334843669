<a
  [routerLink]="['/profile']"
  class="to_profile_nav"
  *ngIf="deviceType == 'mobile'"
>
  <div class="icon_block fcc">
    <i class="fal fa-chevron-left"></i>
  </div>
  <span class="body_m_reg">{{ "To profile" | translate }}</span>
</a>

<div class="right-top">
  <a
    *ngIf="page == 'locations-add'"
    [routerLink]="['/profile/locations']"
    class="arrow-left"
    ><i class="fal fa-arrow-left"></i
  ></a>
  <a
    *ngIf="page == 'orders-products'"
    [routerLink]="['/profile/orders']"
    class="arrow-left"
    ><i class="fal fa-arrow-left"></i
  ></a>
  <a
    *ngIf="page == 'orders-products-follow'"
    [routerLink]="['/profile/orders/']"
    class="arrow-left"
    ><i class="fal fa-arrow-left"></i
  ></a>
  <a
    *ngIf="page == 'orders-products-details'"
    [routerLink]="['/profile/orders/']"
    class="arrow-left"
    ><i class="fal fa-arrow-left"></i
  ></a>
  <a
    *ngIf="page == 'security-details'"
    [routerLink]="['/profile/security/']"
    class="arrow-left"
    ><i class="fal fa-arrow-left"></i
  ></a>
  <div class="title">
    <h2
      class="body_l_demi"
      *ngIf="
        page != 'orders-products' &&
        page != 'orders-products-follow' &&
        page != 'orders-products-details'
      "
    >
      {{ title | translate }}
    </h2>
    <h2
      class="body_l_demi"
      *ngIf="
        page == 'orders-products' ||
        page == 'orders-products-follow' ||
        page == 'orders-products-details'
      "
    >
      {{ "Order" | translate }}# {{ order_id }} ({{ products_count }}
      {{ "product" | translate }})
    </h2>
    <p class="body_s_reg">{{ description | translate }}</p>
  </div>

  <div class="button-block" *ngIf="page == 'orders-products'">
    <a
      [routerLink]="['/profile/orders/products' + '/' + order_id + '/follow']"
      class="btn btn-bordered order-button body_m_med"
      >{{ "Track order" | translate }}</a
    >
    <button
      class="menu-btn"
      [routerLink]="['/profile/orders/products' + '/' + order_id + '/details']"
    >
      <i class="far fa-ellipsis-v"></i>
    </button>
  </div>

  <div class="button-block" *ngIf="page == 'orders-products-follow'">
    <!-- <a href="" class="btn btn-bordered order-button body_m_med cancel-btn"
      >Sifarişi ləğv et</a
    > -->
  </div>

  <!-- <div class="button-block" *ngIf="page == 'orders-products-details'">
    <a href="" class="btn btn-bordered order-button body_m_med">Çap et</a>
  </div> -->
</div>
