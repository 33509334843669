<div class="signin_wrapper">
  <nav class="auth_navigation">
    <a
      [routerLink]="['/auth/login']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      >{{ "login" | translate }}</a
    >
    <a
      [routerLink]="['/auth']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      >{{ "signup" | translate }}</a
    >
  </nav>
  <div class="signin_reg" *ngIf="localState.signin_request_sent == false">
    <div class="inputs_container">
      <custom-input
        [labelHead]="'phone_number' | translate"
        [labelFor]="'signin_phone'"
      >
        <input
          #customInput
          type="text"
          name="signin_phone"
          inputmode="numeric"
          prefix="+994"
          mask="(00) 000 00 00"
          [(ngModel)]="localState.signin_phone"
          autocomplete="off"
        />
      </custom-input>
    </div>

    <div class="signin_code_submit">
      <button
        class="btn btn-lg btn-fill w-100"
        [ngClass]="{ disabled: !validateGetPassword() }"
        (click)="showLocalState($event)"
      >
        {{ "login" | translate }}
      </button>
    </div>
  </div>

  <div class="signin_code" *ngIf="localState.signin_request_sent == true">
    <h6 class="h6_demi">{{ "phone_number_approval" | translate }}</h6>
    <p class="approve_number_p body_s_reg">
      {{ "approve_number_p_1" | translate }} +994 ({{
        localState.masked_signin_phone.slice(0, 2)
      }}) {{ localState.masked_signin_phone.slice(2, 5) }} ** **
      {{ "approve_number_p_2" | translate }}
    </p>
    <div class="solo_input">
      <custom-input [labelHead]="'code' | translate" [labelFor]="'code'">
        <input
          #customInput
          type="text"
          name="code"
          inputmode="numeric"
          mask="000000"
          pattern="[0-9]*"
          [(ngModel)]="localState.code"
          autocomplete="off"
        />
        <div class="countdown" #customInput>
          <span class="body_s_med">{{ localState.countDown }}</span>
        </div>
      </custom-input>
    </div>
    <button
      class="btn btn-lg btn-fill w-100"
      [ngClass]="{ disabled: !validatePassCode() }"
      (click)="finishLogin()"
    >
      {{ "accept_signup" | translate }}
    </button>
    <div class="signin_login">
      <p class="body_s_reg">
        {{ "code_lost" | translate }}?
        <button
          class="body_s_med resend_code_btn"
          [ngClass]="{ disabled: localState.resendCodeStatus == true }"
          (click)="getOTP()"
        >
          {{ "resend_code" | translate }}
        </button>
      </p>
    </div>
  </div>
</div>
