import { OwlOptions } from "ngx-owl-carousel-o";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "main-image-carousel",
  templateUrl: "./main-image-carousel.component.html",
  styleUrls: ["./main-image-carousel.component.scss"],
})
export class MainImageCarouselComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.customOptions = {
      // autoplay: true,
      // autoplayTimeout: 5000,
      // autoplayHoverPause: true,
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      lazyLoad: true,
      navSpeed: 400,
      navText: ["", ""],
      nav: false,
      margin: 16,
      items: 1,
    };
  }

  public customOptions: OwlOptions = {};

  @Input("items") public items;
  @Output("selected_image") selected_image = new EventEmitter<number>();

  public onImageClick(index) {}

  public log(event) {}
}
