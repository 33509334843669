import { NgxSpinnerService } from "ngx-spinner";
import { MallService } from "./../../../mall.service";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "category-nav",
  templateUrl: "./category-nav.component.html",
  styleUrls: ["./category-nav.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CategoryNavComponent implements OnInit {
  constructor(
    private MALL: MallService,
    private ROUTER: Router,
    private spinner: NgxSpinnerService
  ) {
    this.detectDevice();
  }

  @Input("categories_nav") public categories_nav;

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  ngAfterViewInit(): void {}

  public state = {
    toggleStatus: false,
    categories_nav: null,
    active_subcategories: null,
    active_category_img: "",
    active_category_label: "",
    active_brands: [],
  };

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.state.categories_nav = this.categories_nav;
    if (this.state.categories_nav != null) {
      this.showSubcategories(0);
    }
  }

  onCategoryMenuChange() {
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;

    const element = document.querySelector("body");
    if (this.state.toggleStatus) {
      element.style.height = "100%";
      // element.style.overflow = 'hidden';
    } else {
      // element.style.height = 'auto';
      element.style.overflow = "unset";
    }
  }

  onClickedOutside(event) {
    this.state.toggleStatus = false;
    this.MALL.navigationToggled.emit(this.state.toggleStatus);
    // this.onCategoryMenuChange();
  }

  toggleCategory(event) {
    if (this.state.categories_nav) {
      // const body = document.body;
      // if (this.state.toggleStatus == false) {
      //   body.style.overflowY = "hidden";
      // } else {
      //   body.style.overflowY = "auto";
      // }
      this.state.toggleStatus = !this.state.toggleStatus;
      this.MALL.navigationToggled.emit(this.state.toggleStatus);
      // this.onCategoryMenuChange();
    }
  }

  navigateTo(id: number, slug: string) {
    this.state.toggleStatus = false;
    this.ROUTER.navigate([`/list`], {
      queryParams: { category_id: id, slug: slug },
    });
  }

  navigateToCategories(slug: string, id: number) {
    this.state.toggleStatus = false;
    this.ROUTER.navigate([`/categories/${slug}`], {
      queryParams: { category_id: id },
    });
  }

  showSubcategories(i: number) {
    this.state.active_subcategories = this.state.categories_nav[i].children;
    this.state.active_category_img = this.state.categories_nav[i].image;
    this.state.active_category_label = this.state.categories_nav[i].name;
    this.state.active_brands = this.state.categories_nav[i].stores;
  }

  public deviceType: string;
  detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
