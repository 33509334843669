<app-profile-headline
  [title]="'Profile data'"
  [description]="'You can update your personal information here.'"
  [page]="'profile-info'"
>
</app-profile-headline>
<div class="profile-data">
  <div class="form-list">
    <form action="">
      <div class="form-group">
        <label for="firstname" class="body_s_med">{{
          "firstname" | translate
        }}</label>
        <input
          type="text"
          [(ngModel)]="info.firstname"
          name="firstname"
          id="firstname"
          class="input-style"
        />
      </div>
      <div class="form-group">
        <label for="lastname" class="body_s_med">{{
          "lastname" | translate
        }}</label>
        <input
          type="text"
          [(ngModel)]="info.lastname"
          name="lastname"
          id="lastname"
          class="input-style"
        />
      </div>
      <div class="form-group">
        <label for="email" class="body_s_med">{{ "Email" | translate }}</label>
        <input
          type="text"
          [(ngModel)]="info.email"
          name="email"
          id="email"
          [ngClass]="{ 'check-icon': info.email_verified_at }"
        />
      </div>
      <div class="form-group">
        <label for="phone" class="body_s_med">{{
          "Phone number" | translate
        }}</label>
        <input type="text" disabled [value]="info.phone" class="check-icon" />
      </div>
      <div class="form-group">
        <label for="date" class="body_s_med">{{
          "Birth date" | translate
        }}</label>
        <ng-container *ngIf="deviceType == 'mobile'">
          <input
            type="date"
            [(ngModel)]="info.born_at"
            name="born_at"
            id="date"
          />
        </ng-container>
        <ng-container *ngIf="deviceType == 'desktop'">
          <input
            [dpDayPicker]="dpDayPickerconfig"
            placeholder="YYYY-MM-DD"
            type="text"
            [(ngModel)]="info.born_at"
            name="born_at"
            id="date"
          />
        </ng-container>
      </div>
    </form>
    <div class="gender-family-block">
      <div class="left">
        <h3 class="body_s_med">{{ "Sex" | translate }}</h3>
        <div class="form-group radio-group">
          <input
            [(ngModel)]="info.gender"
            [checked]="info.gender == 'male'"
            value="male"
            class="radio-input"
            name="sex"
            type="radio"
            id="goods_1"
          />
          <label for="goods_1" class="radio-label">
            {{ "Man" | translate }}
          </label>
        </div>
        <div class="form-group radio-group">
          <input
            [(ngModel)]="info.gender"
            [checked]="info.gender == 'female'"
            value="female"
            class="radio-input"
            name="sex"
            type="radio"
            id="goods_2"
          />
          <label for="goods_2" class="radio-label">
            {{ "Woman" | translate }}
          </label>
        </div>
      </div>
      <div class="right">
        <h3 class="body_s_med">{{ "Marital status" | translate }}</h3>
        <div class="form-group radio-group">
          <input
            [checked]="info.marital == 'married'"
            [(ngModel)]="info.marital"
            value="married"
            class="radio-input"
            name="married"
            type="radio"
            id="goods_3"
          />
          <label for="goods_3" class="radio-label">
            {{ "Married" | translate }}
          </label>
        </div>
        <div class="form-group radio-group">
          <input
            [checked]="info.marital == 'single'"
            [(ngModel)]="info.marital"
            value="single"
            class="radio-input"
            name="married"
            type="radio"
            id="goods_4"
          />
          <label for="goods_4" class="radio-label">
            {{ "Single" | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="submit-block">
  <!--    <button class="btn btn-border btn-med ">Ləğv etmək</button>-->
  <button class="btn btn-fill hover btn-med" (click)="update_profile()">
    {{ "Save" | translate }}
    <i class="far fa-check-circle" *ngIf="isBtnActive"></i>
  </button>
</div>
