<section
  class="multiple_items_carousel container"
  *ngIf="carousel_state.items !== null"
>
  <div class="heading_block">
    <div class="left_side">
      <div
        [ngClass]="{
          h5_demi: deviceType == 'desktop',
          body_l_demi: deviceType == 'mobile'
        }"
      >
        <ng-container *ngIf="heading.toLowerCase().includes('birkart')">
          <img
            class="heading_logo"
            src="../../../../../assets/icons/birkart_logo.png"
            alt="BirKart Logo"
          />
        </ng-container>

        {{ heading | translate }}
      </div>
    </div>
    <div class="right_side">
      <ng-container *ngIf="show_all_url">
        <div
          class="body_m_med"
          (click)="showAll()"
          *ngIf="
            deviceType == 'desktop' &&
            carousel_state.items.length > carousel_state.max_item_count
          "
        >
          {{ "See all" | translate }}
        </div>
      </ng-container>
      <div
        class="body_xs_med"
        (click)="showAll()"
        *ngIf="deviceType == 'mobile' && carousel_state.items.length > 4"
      >
        {{ "See all" | translate }}
      </div>
      <div class="carousel_nav_side"></div>
    </div>
  </div>

  <owl-carousel-o
    [options]="customOptions"
    *ngIf="
      deviceType == 'desktop' &&
      carousel_state.items.length > carousel_state.max_item_count
    "
  >
    <ng-template carouselSlide *ngFor="let item of carousel_state.items">
      <product-card [item]="item"></product-card>
    </ng-template>
  </owl-carousel-o>

  <div
    class="desktop_grid"
    *ngIf="
      deviceType == 'desktop' &&
      carousel_state.items.length <= carousel_state.max_item_count
    "
  >
    <ng-container *ngFor="let item of carousel_state.items">
      <product-card [item]="item"></product-card>
    </ng-container>
  </div>

  <div class="mobile_grid" *ngIf="deviceType == 'mobile'">
    <ng-container *ngFor="let item of carousel_state.items; let i = index">
      <product-card [item]="item" *ngIf="i < 4"></product-card>
    </ng-container>
  </div>
</section>
