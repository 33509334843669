<section class="installment_modal" *ngIf="slug != null && product != null">
  <div class="white_box">
    <div class="box_head">
      <strong class="body_m_demi">{{
        "Pay in installments with BirKart" | translate
      }}</strong>
      <div class="close_modal fcc" (click)="closeModal()">
        <i class="fas fa-times-circle"></i>
      </div>
    </div>
    <div class="padding_box">
      <div class="product_details">
        <div class="product_details__left">
          <div class="image fcc">
            <img [src]="product.images.carousel[0]" alt="" />
          </div>
          <div class="content">
            <h6 class="h6_med">{{ product.name }}</h6>
            <div
              class="body_xs_reg each_opt"
              [ngClass]="{ caption: deviceType == 'mobile' }"
              *ngFor="let conf of product?.current_variation"
            >
              {{ conf.attribute_name }}:
              <ng-container
                *ngIf="
                  conf.value_name.length == 7 && conf.value_name.includes('#')
                "
              >
                <div
                  class="filterable_color"
                  [style.backgroundColor]="conf.value_name"
                ></div>
              </ng-container>
              <ng-container
                *ngIf="
                  conf.value_name.length != 7 || !conf.value_name.includes('#')
                "
              >
                <b>{{ conf.value_name }}</b>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="product_details__right">
          <div class="price_box">
            <div class="old-price" *ngIf="product.discount > 0">
              {{ product.price.original }} &#8380;
            </div>
            <h6 class="h6_demi">
              {{
                product.discount > 0
                  ? product.price.discounted
                  : product.price.original
              }}
              &#8380;
            </h6>
          </div>
          <div class="select_container">
            <basket-select
              [value]="count"
              [limit]="product.stock"
              (selectedCount)="changeSelectedCount($event)"
            ></basket-select>
          </div>
        </div>
      </div>

      <div class="variations_block">
        <div
          class="product-other-desc"
          *ngFor="let item of product?.filterable"
        >
          <div class="feature-title">{{ item.title }}</div>
          <div class="feature-desc">
            <ng-container *ngFor="let opt of item.values">
              <option-link
                *ngIf="opt.slug"
                [option]="opt"
                [current]="product?.current_variation"
                (onFilterableSelect)="changeProduct($event)"
              >
              </option-link>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="table_div" *ngIf="product?.installment == 1">
        <table class="installment_table">
          <thead>
            <tr>
              <th></th>
              <th>{{ "PERIOD" | translate }}</th>
              <th>{{ "INITIAL PAYMENT" | translate }}</th>
              <th>{{ "AMOUNT" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let opt of installmentMonthOptions">
              <td>
                <div
                  class="radio_btn"
                  [ngClass]="{ active: selectedMonth == opt }"
                  (click)="selectMonth(opt)"
                ></div>
              </td>
              <td>{{ opt }} ay</td>
              <td>0 ₼</td>
              <td>
                {{
                  product.discount > 0
                    ? (product.price.discounted / opt).toFixed(2)
                    : (product.price.original / opt).toFixed(2)
                }}
                &#8380;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button
        *ngIf="product?.installment == 1"
        class="btn btn-fill btn-med m-l-auto submit_btn"
        (click)="navigateToCheckout()"
      >
        {{ "continue" | translate }}
      </button>
    </div>
  </div>
</section>
