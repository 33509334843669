<a
  *ngIf="data"
  [routerLink]="['/list']"
  [queryParams]="{ hashtag: data.slug }"
  class="tag_key"
  [ngClass]="{ simple: remove == false }"
>
  <span class="title body_s_med">{{ data.name }}</span>
  <div class="icon_block fcc" *ngIf="remove == true">
    <i class="fas fa-times-circle"></i>
  </div>
</a>
