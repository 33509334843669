<div class="product_grid">
  <div class="product_grid_top">
    <div class="tags_container" *ngIf="deviceType == 'desktop'">
      <list-tag *ngFor="let tag of tags" [tag]="tag"></list-tag>
      <div
        class="remove_all_tags body_s_med"
        (click)="removeAllTags()"
        *ngIf="tags && tags.length > 1"
      >
        {{ "remove all" | translate }}
      </div>
    </div>
    <div
      class="filter_container"
      *ngIf="deviceType == 'mobile' && page != 'favorites' && page != 'search'"
    >
      <button
        class="mobile_filter_toggler body_s_reg"
        (click)="outputFilterToggler()"
      >
        <div class="icon_block fcc">
          <i class="fal fa-filter"></i>
        </div>
        {{ "Filters" | translate }}
      </button>
    </div>
    <div class="sort_side">
      <div class="sorting_component">
        <simple-select
          *ngIf="selected_sorting != null"
          [selected]="selected_sorting"
          [options]="sorting_options"
          (selectedValue)="onGridSortingChanged($event)"
        >
        </simple-select>
      </div>
      <div class="listing_side" *ngIf="hide_list == false">
        <div
          class="btns list_trigger fcc"
          (click)="changeView()"
          [ngClass]="{ active: default_view }"
        >
          <i class="fal fa-list"></i>
        </div>
        <div
          class="btns grid_trigger fcc"
          (click)="changeView()"
          [ngClass]="{ active: !default_view }"
        >
          <i class="fas fa-th-large"></i>
        </div>
      </div>
    </div>
  </div>
  <div
    class="product_grid_content"
    [ngClass]="{
      grid: default_view == false,
      list: default_view == true
    }"
  >
    <ng-container *ngFor="let product of products">
      <product-card
        [item]="product"
        [page]="page"
        (deleteEvent)="onDeleteUpdate($event)"
      ></product-card>
    </ng-container>
  </div>
</div>
