import { Router } from "@angular/router";
import { ChangeDetectionStrategy, SimpleChanges } from "@angular/core";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "index-category-banner",
  templateUrl: "./index-category-banner.component.html",
  styleUrls: ["./index-category-banner.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class IndexCategoryBannerComponent implements OnInit {
  constructor(private ROUTER: Router) {
    this.detectDevice();
  }

  @Input("item") public item;
  @Input("scaled") public scaled;

  ngOnInit(): void {
    this.state.banner_img = this.item?.image;
    this.state.category_name = this.item?.name;
    this.state.banner_bg = this.item?.background;
    this.state.foreground = this.item?.foreground;
    this.state.products_count = this.item?.products_count;
    this.state.category_id = this.item?.id;
    this.state.slug = this.item?.slug;
    this.state.url = this.item?.url;
    this.state.ready = true;
    // this.checkColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.item) {
      this.state.banner_img = this.item?.image;
      this.state.category_name = this.item?.name;
      this.state.banner_bg = this.item?.background;
      this.state.foreground = this.item?.foreground;
      this.state.products_count = this.item?.products_count;
      this.state.category_id = this.item?.id;
      this.state.slug = this.item?.slug;
      this.state.url = this.item?.url;
      this.state.ready = true;
    }
  }

  public state = {
    category_id: "",
    category_name: "",
    products_count: 0,
    banner_img: "",
    banner_bg: "reg",
    foreground: "#000000",
    slug: "",
    ready: false,
    url: "",
  };

  public checkColor() {
    const hex = this.state.banner_bg.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

    // if (brightness > 200) {
    //   this.state.text_white = false;
    // }
  }

  toSubcategory(event: any, path: any) {
    this.ROUTER.navigate(path);
  }

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }

  navByUrl() {
    let modifyUrl;
    if (this.state.url.includes(".com")) {
      modifyUrl = this.state.url.split(".com")[1];
    }
    if (this.state.url.includes(".az")) {
      modifyUrl = this.state.url.split(".az")[1];
    }

    this.ROUTER.navigateByUrl(modifyUrl);
  }
}
