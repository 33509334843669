<a
  [routerLink]="['/blogs/detail/' + blog.id]"
  class="blog_card"
  [ngClass]="{ card_margin: card_with_margin }"
>
  <div class="blog_card__img">
    <img [src]="blog.cover" alt="" />
  </div>
  <div class="blog_card__text">
    <small class="body_xs_med">{{ blog.created_at }}</small>
    <strong class="body_s_med">{{ blog.title }}</strong>
  </div>
</a>
