import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { MallService } from "../../mall.service";
import { Location } from "@angular/common";
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { NavigationService } from "ngx-owl-carousel-o/lib/services/navigation.service";
import { generateTitle } from "../../../../common/utils/functions/generateTitle.function";
import { Subscription } from "rxjs";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
  public firstLoad: boolean = true;
  public paths: [];
  public filters: [];
  public products: [];
  public filtered = [];
  public selected_sorting: {};
  public sort: any = [
    { label: "Sort", value: "default" },
    { label: "Most popular", value: "popular" },
    { label: "Price: Low to High", value: "cheap" },
    { label: "Price: High to Low", value: "expensive" },
    { label: "Newest to Oldest", value: "latest" },
    { label: "Best Selling", value: "bestseller" },
    { label: "Best Rating", value: "bestrated" },
    { label: "By discount", value: "discount" },
    { label: "By installment", value: "birkart" },
  ];
  public default_list_type: string;
  public activeRoute: string;
  public locationPath: string;
  public pricing: any = {};
  public category: any = {};
  public pagination: any = {};
  public pageType: string = null;
  public state: boolean;
  public tags = [];
  public initialListParams = {
    per_page: 40,
    page: 1,
  };

  constructor(
    private locationCore: Location,
    private activatedRoute: ActivatedRoute,
    private mallService: MallService,
    private router: Router,
    private NOTIFY: NotifyService,
    private Title: Title
  ) {
    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.getUrlParams();
      }
    });

    this.detectDevice();
  }

  public subscription: Subscription = null;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.fillDefaultSorting();
  }

  fillDefaultSorting() {
    this.default_list_type =
      this.activatedRoute.snapshot.queryParamMap.get("type");
    let order_by = this.activatedRoute.snapshot.queryParamMap.get("order_by");
    let order_dir = this.activatedRoute.snapshot.queryParamMap.get("order_dir");

    if (order_by && order_dir) {
      switch (order_by) {
        case "view":
          this.selected_sorting = this.sort[1];
          break;
        case "date":
          this.selected_sorting = this.sort[4];
          break;
        case "price":
          if (order_dir == "desc") {
            this.selected_sorting = this.sort[3];
          } else {
            this.selected_sorting = this.sort[2];
          }
          break;
        case "sell":
          this.selected_sorting = this.sort[5];
          break;
        case "rating":
          this.selected_sorting = this.sort[6];
          break;
        default:
          break;
      }
    } else {
      if (this.default_list_type == "birkart") {
        this.selected_sorting = this.sort[8];
      }
      if (this.default_list_type == "discount") {
        this.selected_sorting = this.sort[7];
      }
    }
  }

  public listParams: any = {
    per_page: null,
    page: null,
  };

  public getUrlParams() {
    this.category = null;
    this.category_id = Number(
      this.activatedRoute.snapshot.queryParamMap.get("category_id")
    );
    this.slug = this.activatedRoute.snapshot.queryParamMap.get("slug");
    this.min_price =
      this.activatedRoute.snapshot.queryParamMap.get("price_from");
    this.max_price = this.activatedRoute.snapshot.queryParamMap.get("price_to");
    this.retail_toggler =
      this.activatedRoute.snapshot.queryParamMap.get("retail") == "true"
        ? true
        : false;

    if (this.slug) {
      this.pageType = "list";
      this.getCategoryData();
    } else {
      this.Title.setTitle(generateTitle("Hamısı"));
    }

    let tempArray = {};
    this.listParams = null;
    tempArray = this.activatedRoute.snapshot.queryParamMap["params"];

    let changedTempArray = {};
    Object.keys(tempArray).map((key) => {
      if (key.includes("[]")) {
        changedTempArray[`selected[${key.split("[]")[0]}][]`] = tempArray[key];
      } else {
        changedTempArray[key] = tempArray[key];
      }
    });

    let obj = Object.assign({}, this.initialListParams, changedTempArray);
    this.listParams = obj;

    Object.keys(this.listParams).map((key) => {
      if (this.listParams[key] == "0" || this.listParams[key] == "") {
        delete this.listParams[key];
      }

      if (this.listParams?.retail && this.listParams?.retail == "false") {
        delete this.listParams.retail;
      }
    });

    const paramList = decodeURI(
      this.router.url.split("/list")[1].split("?")[1]
    );

    let obj2 = [];
    paramList.split("&").map((param) => {
      obj2.push({ code: param.split("=")[0], value: param.split("=")[1] });
    });

    setTimeout(() => {
      this.updatePage(obj2);
    }, 100);
  }

  public getCategoryData() {
    this.mallService.getCategoryDetails(this.slug).subscribe(
      (RESPONSE) => {
        this.category = RESPONSE.body;
        this.Title.setTitle(generateTitle(this.category.name));
      },
      (err) => {
        this.NOTIFY.error(err);
      }
    );
  }

  public filtered_count: number = null;
  public category_id: number = null;
  public slug: string = null;
  public links: any = null;
  private updatePage(obj = null): void {
    if (this.firstLoad == false) {
      this.NOTIFY.startSpinner();
    }
    this.mallService.getProductsList(this.listParams).subscribe(
      (res) => {
        this.state = false;
        this.filters = res.body.filters;
        this.products = res.body.data;
        if (res?.body?.category && !this.slug) {
          this.category = res.body.category;
        }
        this.filtered_count = res.body.filtered_products_count;
        this.pagination = {
          total: res.total,
          per_page: res.per_page,
          page: res.page,
        };
        this.links = res.body.meta.links;
        this.state = true;
        this.firstLoad = false;
        this.NOTIFY.stopSpinner();
        this.fillTags(obj);
      },
      (err) => {
        this.NOTIFY.stopSpinner();
        this.NOTIFY.error(err);
      }
    );
  }

  public min_price: string = null;
  public max_price: string = null;
  public inputTimeOut;
  public onPriceRangeChange(name: string) {
    clearTimeout(this.inputTimeOut);
    this.inputTimeOut = setTimeout(() => {
      this.checkRangeValues(name);
    }, 600);
  }

  public checkRangeValues(name: string) {
    if (name == "min_price") {
      if (this.min_price != null) {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: "merge",
          queryParams: { price_from: this.min_price },
          replaceUrl: true,
        });
      } else {
        let params = this.activatedRoute.snapshot.queryParams;
        delete params.price_from;
        this.router.navigate([], {
          queryParams: params,
        });
      }
    } else if (name == "max_price") {
      if (this.max_price != null) {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: "merge",
          queryParams: { price_to: this.max_price },
          replaceUrl: true,
        });
      } else {
        let params = this.activatedRoute.snapshot.queryParams;
        delete params.price_from;
        this.router.navigate([], {
          queryParams: params,
        });
      }
    }
  }

  // public setPriceRangeFromUrl(value: string) {
  //   if (value !== null) {
  //     this.price_range.value = value;
  //     this.price_range.min = value.split("-")[0];
  //     this.price_range.max = value.split("-")[1];
  //   }
  // }

  public onSortingFilterChange(event) {
    if (event) {
      let params: any;
      switch (event.value) {
        case "default":
          params = {
            order_by: null,
            order_dir: null,
            type: this.default_list_type,
          };
          break;
        case "popular":
          params = {
            order_by: "view",
            order_dir: "desc",
            type: this.default_list_type,
          };
          break;
        case "birkart":
          params = {
            order_by: null,
            order_dir: null,
            type: "birkart",
          };
          break;
        case "discount":
          params = {
            order_by: null,
            order_dir: null,
            type: "discount",
          };
          break;
        case "latest":
          params = {
            order_by: "date",
            order_dir: "desc",
            type: this.default_list_type,
          };
          break;
        case "cheap":
          params = {
            order_by: "price",
            order_dir: "asc",
            type: this.default_list_type,
          };
          break;
        case "expensive":
          params = {
            order_by: "price",
            order_dir: "desc",
            type: this.default_list_type,
          };
          break;
        case "bestseller":
          params = {
            order_by: "sell",
            order_dir: "desc",
            type: this.default_list_type,
          };
          break;
        case "bestrated":
          params = {
            order_by: "rating",
            order_dir: "desc",
            type: this.default_list_type,
          };
          break;
        default:
          break;
      }
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: "merge",
        queryParams: params,
        replaceUrl: true,
      });
    }
  }

  // retail option
  public retail_toggler: boolean = false;
  public onRetailChange(event) {
    let params: any = {
      retail: event.value,
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: "merge",
      queryParams: params,
      replaceUrl: true,
    });
  }

  // retail option

  public fillTags(obj) {
    this.tags = [];
    if (obj) {
      obj.map((item) => {
        this.filters.map((filter: {}) => {
          if (item.code.split("[]")[0] == filter["attribute_code"]) {
            filter["options"].map((opt) => {
              if (item.value == opt.value) {
                item["label"] = opt.label;
                this.tags.push(item);
              }
            });
          }
        });
      });
    }
  }

  public onMobileFilterToggler(event) {
    this.mobile_filter = event;
  }

  public closeMobileFilters() {
    this.mobile_filter = false;
  }

  public mobile_filter: boolean = false;

  public deviceType: string;
  public detectDevice() {
    if (window.innerWidth < 1048) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "desktop";
    }
  }
}
