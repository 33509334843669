export function createParams<P>(params: P): Partial<P> {
  if (params === null) {
    return {};
  }
  const mappedParams = {};
  Object.entries(params)
    .filter(([, value]) => value !== null && value !== undefined)
    .forEach(([key, value]) => (mappedParams[key] = value));
  return mappedParams;
}
