<div
  class="custom_select_container"
  (clickOutside)="onClickedOutside($event)"
  [clickOutsideEnabled]="true"
  [ngStyle]="{ backgroundColor: background }"
  [ngClass]="{ large: size == 'large' }"
>
  <div
    class="inputless_trigger"
    [ngClass]="{ active: state.trigger }"
    (click)="triggerSelect($event)"
  >
    <div class="text_side">
      <p class="body_m_med">
        {{ state.value.label != null ? (state.value.label | translate) : "" }}
      </p>
    </div>
    <div class="trigger_btn fcc">
      <i class="far fa-angle-down"></i>
    </div>
  </div>

  <div class="options_container" [ngClass]="{ active: state.trigger == true }">
    <div
      *ngFor="let opt of state.options"
      class="each_option body_m_med"
      [ngClass]="{ selected: opt == state.value }"
      (click)="setOptionValue(opt.value)"
    >
      {{ opt.label | translate }}
    </div>
  </div>
</div>
