import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "basket-select",
  templateUrl: "./basket-select.component.html",
  styleUrls: ["./basket-select.component.scss"],
})
export class BasketSelectComponent implements OnInit {
  constructor() {}

  @Output() selectedCount = new EventEmitter();
  @Input("value") public value: number;
  @Input("limit") public limit: number;

  ngOnInit(): void {
    // window.scroll(0, 0);
    this.state.value = this.value;

    this.fillLimit();

    if (this.value >= 10) {
      this.state.input = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.value) {
      this.state.value = this.value;
    }
    if (this.limit) {
      this.fillLimit();
    }
  }

  public state = {
    trigger: false,
    input: false,
    value: null,
    limit: [],
  };

  fillLimit() {
    if (this.limit < 10) {
      this.state.limit.length = 0;
      for (let i = 1; i < this.limit + 1; i++) {
        if (this.state.limit !== null) {
          this.state.limit.push(i);
        }
      }
    } else {
      for (let i = 1; i < 10; i++) {
        if (this.state.limit !== null) {
          this.state.limit.push(i);
        }
      }
      this.state.limit.push("10+");
    }
  }

  public changeInputValue(value: string) {
    this.state.value = value;
  }

  public onInputBlur(value) {
    if (parseInt(value) == 0 || value == "") {
      this.state.value = 1;
    }

    if (parseInt(value) < 11 && parseInt(value) > 0) {
      this.state.value = value;
      this.state.input = false;
    }

    if (parseInt(value) > this.limit) {
      this.state.value = this.limit;
      this.state.input = true;
    }

    this.selectedCount.emit(this.state.value);
  }

  public triggerSelect(event: any) {
    this.state.trigger = !this.state.trigger;
  }

  public setOptionValue(value: any) {
    if (this.checkType(value) == true && value.indexOf("+") > -1) {
      this.state.value = parseInt(value);
      this.state.input = true;
      this.state.trigger = false;
    } else {
      this.state.value = parseInt(value);
      this.state.trigger = false;
    }
    this.selectedCount.emit(this.state.value);
  }

  public onClickedOutside(event: any) {
    this.state.trigger = false;
  }

  public checkType(value) {
    if (typeof value == "string") {
      return true;
    }
  }
}
