<app-profile-headline
  [title]="'Orders'"
  [description]="'You can track and monitor your orders here.'"
  [page]="'orders'"
>
</app-profile-headline>

<div class="all-product-block">
  <!--    <div class="select-block">-->
  <!--        <select name="" class="select" id="">-->
  <!--            <option value=""><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">All products</font></font></option>-->
  <!--            <option value=""><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">All products</font></font></option>-->
  <!--            <option value=""><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">All products</font></font></option>-->
  <!--        </select>-->
  <!--    </div>-->
  <div class="list-box" *ngFor="let order of orders">
    <a [routerLink]="['/profile/orders/products/' + order.id]">
      <div class="text">
        <h3 class="body_xs_med">
          {{ order.created_at }}
        </h3>
        <p class="body_s_med">{{ "Order" | translate }}# {{ order.id }}</p>
      </div>
      <div class="text" *ngIf="deviceType == 'desktop'">
        <h3 class="body_xs_med">{{ "Order summary" | translate }}</h3>
        <p class="body_s_med">
          {{ order.products_count }}
          {{
            order.products_count > 1
              ? ("products" | translate)
              : ("product" | translate)
          }}
        </p>
      </div>
      <div class="text" *ngIf="deviceType == 'desktop'">
        <h3 class="body_xs_med">{{ "Delivery address" | translate }}</h3>
        <p class="body_s_med">
          {{ order.address }}
        </p>
      </div>
      <div class="text">
        <h3 class="body_xs_med">{{ "Status" | translate }}</h3>
        <div class="body_s_med icon_block">
          <div class="icon" [innerHTML]="order.status_id | renderStatus"></div>
          {{ order.status | translate }}
        </div>
      </div>
      <div class="text" *ngIf="deviceType == 'desktop'">
        <h3 class="body_xs_med">{{ "Price information" | translate }}</h3>
        <p class="body_s_demi">{{ order.final }} &#8380;</p>
      </div>
      <span class="icon"><i class="fal fa-chevron-right"></i></span>
    </a>
  </div>
  <pagination-component
    *ngIf="orders"
    [position]="'left'"
    [links]="links"
  ></pagination-component>
</div>

<section class="order_result_modal" *ngIf="paymentInfo.modal">
  <div class="white_box">
    <ng-container *ngIf="!paymentInfo.status">
      <div class="external_block fcc error">
        <div class="internal_block">
          <i class="fas fa-times"></i>
        </div>
      </div>

      <strong class="h6_demi">{{ "failed_payment" | translate }}</strong>
      <p class="body_m_reg">{{ paymentInfo?.text }}</p>

      <a [routerLink]="['/basket']" class="btn btn-fill btn-med btn-full">{{
        "try_again" | translate
      }}</a>
    </ng-container>

    <ng-container *ngIf="paymentInfo.status">
      <div class="external_block fcc success">
        <div class="internal_block">
          <i class="fas fa-check-circle"></i>
        </div>
      </div>

      <strong class="h6_demi">{{ "success_payment" | translate }}</strong>
      <p class="body_m_reg">{{ paymentInfo?.text }}</p>

      <button
        class="close_modal"
        (click)="closeModal()"
        class="btn btn-fill btn-med btn-full"
      >
        {{ "Close" | translate }}
      </button>
    </ng-container>
  </div>
</section>
