import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "fullwidth-carousel-banner",
  templateUrl: "./fullwidth-carousel-banner.component.html",
  styleUrls: ["./fullwidth-carousel-banner.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FullwidthCarouselBannerComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.carousel_state.items = this.items;
    this.carousel_state.mobile_items = this.mobile_items;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.items) {
      this.carousel_state.items = this.items;
    }

    if (this.mobile_items) {
      this.carousel_state.mobile_items = this.mobile_items;
    }
  }

  @Input("items") public items;
  @Input("mobile_items") public mobile_items;

  public customOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    lazyLoad: true,
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ["", ""],
    nav: false,
    items: 1,
    margin: 1,
  };

  public carousel_state = {
    items: null,
    mobile_items: null
  };

  // public onBannerClick(meta_data) {
  //   // console.log(meta_data);
  //   switch (meta_data.type) {
  //     case "product":
  //       this.router.navigate(["product-details", meta_data.id], {
  //         queryParams: { path: meta_data.path },
  //       });
  //       break;
  //     case "brand":
  //       this.router.navigate([`/brand/${meta_data.id}`]);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  public onBannerClick(url: string) {
    let modifyUrl;
    if (url.includes(".com")) {
      modifyUrl = url.split(".com")[1];
    }
    if (url.includes(".az")) {
      modifyUrl = url.split(".az")[1];
    }

    this.router.navigateByUrl(modifyUrl);
  }
}
