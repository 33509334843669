import { ActivatedRoute } from "@angular/router";
import { Notify, NotifyService } from "./../../shared/services/notify.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MallService } from "./../mall.service";
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { UserService } from "src/app/common/utils/user/user.service";
import { CartService } from "src/app/common/utils/user/cart.service";
import { FavoritesService } from "src/app/common/utils/user/favorites.service";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-mall",
  templateUrl: "./mall.component.html",
  styleUrls: ["./mall.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MallComponent implements OnInit {
  constructor(
    private MALL: MallService,
    private spinner: NgxUiLoaderService,
    private Notify: NotifyService,
    private USER: UserService,
    private ACTIVATED_ROUTE: ActivatedRoute
  ) {
    this.MALL.navigationToggled.subscribe((res) => {
      this.hideAll = res;
    });
  }

  ngOnInit() {
    this.getCategoriesNav();
    this.MALL.setCounters();
    this.detectInMobile();
  }

  public hideAll: boolean = false;
  in_mobile: boolean = false;

  getCategoriesNav() {
    if (localStorage.getItem("category")) {
      this.MALL.categoryTree.emit(JSON.parse(localStorage.getItem("category")));
    }

    this.MALL.getCategoriesNav().subscribe((RESPONSE) => {
      if (RESPONSE.body) {
        this.MALL.categoryTree.emit(RESPONSE.body);
        localStorage.setItem("category", JSON.stringify(RESPONSE.body));
      }
    });
  }

  private clearTm;

  detectInMobile() {
    let mob = this.ACTIVATED_ROUTE.snapshot.queryParamMap.get("in_mobile");
    if (mob == "true") {
      this.in_mobile = true;
      this.clearTm = setInterval(() => {
        if (document.querySelector("jdiv")) {
          document.querySelector("jdiv").classList.add("in_mobile");
          clearTimeout(this.clearTm);
        }
      }, 1000);
    }
  }
}
