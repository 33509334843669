<section class="atc_modal" [ngClass]="{ active: modalStatus }">
  <div class="white_block">
    <ng-container *ngIf="product">
      <div class="comment_title d-f ai-c jc-sb">
        <div class="body_m_med t_black_900">
          {{ "add_to_cart" | translate }}
        </div>
        <button class="close_comment_btn fcc" (click)="clearData()">
          <i class="fas fa-times-circle"></i>
        </button>
      </div>
      <div class="pro-box">
        <div class="image fcc">
          <img
            src="{{ product?.images.carousel[0] }}"
            alt="{{ product?.name }}"
          />
        </div>
        <div class="data-box">
          <h6 class="h6_med">{{ product?.name }}</h6>
          <ng-container *ngFor="let detail of product?.current_variation">
            <div class="body_xs_reg">
              {{ detail.attribute_name }}:
              <ng-container
                *ngIf="
                  detail.value_name.length == 7 &&
                  detail.value_name.includes('#')
                "
              >
                <div
                  class="filterable_color"
                  [style.backgroundColor]="detail.value_name"
                ></div>
              </ng-container>
              <ng-container
                *ngIf="
                  detail.value_name.length != 7 ||
                  !detail.value_name.includes('#')
                "
              >
                <b>{{ detail.value_name }}</b>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="price_count_block">
          <div class="old-price body_m_reg" *ngIf="product?.price.discounted">
            {{ product?.price.original }}&#8380;
          </div>
          <h6 class="h6_demi">
            {{
              product?.discount > 0
                ? product?.price.discounted
                : product?.price.original
            }}&#8380;
          </h6>
          <basket-select
            [value]="product_count"
            [limit]="product?.stock"
            (selectedCount)="changeSelectedCount($event)"
          ></basket-select>
        </div>
      </div>

      <div class="variations_block">
        <div
          class="product-other-desc"
          *ngFor="let item of product?.filterable"
        >
          <div class="feature-title">{{ item.title }}</div>
          <div class="feature-desc">
            <ng-container *ngFor="let opt of item.values">
              <option-link
                *ngIf="opt.slug"
                [option]="opt"
                [current]="product?.current_variation"
                (onFilterableSelect)="changeProduct($event)"
              >
              </option-link>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="modal_actions">
        <button class="btn btn-grey btn-med" (click)="clearData()">
          {{ "Close" | translate }}
        </button>
        <a
          *ngIf="product.stock != 0"
          class="btn btn-med btn-fill to_cart_btn ml-12px"
          (click)="addToCart()"
          [ngClass]="{ active: product.in_cart == true }"
          ><ng-container *ngIf="product?.in_cart == false && product?.stock != 0">
            {{ "add_to_cart" | translate }}
          </ng-container>
          <ng-container *ngIf="product?.in_cart == true">
            {{ "already_in_cart" | translate }}
          </ng-container>
        </a>

        <a
          *ngIf="product.stock == 0"
          class="btn btn-med btn-fill to_cart_btn ml-12px"
          (click)="addToReminder(product.id)"
          [ngClass]="{ active: product.in_reminder == true }"
        >
          <ng-container>
            {{ "let_us" | translate }}
          </ng-container>
        </a>
      </div>
    </ng-container>
  </div>
</section>
