import { MallService } from "./../../../mall/mall.service";
import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../../common/utils/user/user.service";
import { FavoritesService } from "../../../../common/utils/user/favorites.service";
import { NotifyService } from "src/app/modules/shared/services/notify.service";
import { generateTitle } from "src/app/common/utils/functions/generateTitle.function";
import { Title } from "@angular/platform-browser";
import { ListProduct } from "src/app/modules/mall/mall.models";

@Component({
  selector: "app-favorites",
  templateUrl: "./favorites.component.html",
  styleUrls: ["./favorites.component.scss"],
})
export class FavoritesComponent implements OnInit {
  public products: ListProduct[] = [];
  public state = false;
  public breadcrump = [];

  constructor(
    private user: UserService,
    private mall: MallService,
    private favorites: FavoritesService,
    private notify: NotifyService,
    private Title: Title
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getFavorites();

    this.Title.setTitle(generateTitle("Favoritlər"));
  }

  public getFavorites(): void {
    this.favorites.get().subscribe(
      (res) => {
        this.products = res.body.data;
        this.breadcrump = [{ name: "Favorites", path: `/favorites` }];
        this.state = true;
        this.notify.stopSpinner();
        // if (res.sort) {
        //   this.sorting_options = res.sort;
        // } else {
        //   this.sorting_options = null;
        //   this.selected_sorting = null;
        // }
      },
      (err) => {
        this.notify.error(err);
      }
    );
  }

  public onDeleteUpdate(slug: string): void {
    this.notify.startSpinner();
    this.favorites.edit(slug).subscribe(
      (RESPONSE) => {
        this.notify.success(RESPONSE);
        this.mall.setCounters();
        this.getFavorites();
      },
      (err) => {
        this.notify.error(err);
      }
    );
  }

  public sorting_options;

  public selected_sorting;
}
