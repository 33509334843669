import { DOCUMENT } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { LanguageService } from "src/app/common/utils/language/services/language.service";
import { Language } from "src/app/common/utils/language/typings/Language.type";

@Component({
  selector: "language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LanguageSelectorComponent implements OnInit {
  constructor(public LANG: LanguageService) {
    setTimeout(() => {
      this.state.first_load = false;
    }, 2000);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.selectCurrentLanguage();
  }

  public state = {
    open_status: false,
    selected_lang: null,
    languages: ["Azərbaycan dili", "Русский язык", "English"],
    lang_data: ["az", "ru", "en"],
    first_load: true,
  };

  toggleLangSelector(e) {
    this.state.open_status = !this.state.open_status;
  }

  onClickedOutside(e) {
    this.state.open_status = false;
  }

  changeSelectedLanguage(event: Language, index: number) {
    this.state.selected_lang = this.state.languages[index];
    this.LANG.switchLang(event);
    if (this.state.first_load == false) {
      window.location.reload();
    }
  }

  selectCurrentLanguage() {
    if (localStorage.getItem("lang")) {
      let selected_lang = localStorage.getItem("lang");

      switch (selected_lang) {
        case this.state.lang_data[0]:
          this.changeSelectedLanguage("az", 0);
          break;
        case this.state.lang_data[1]:
          this.changeSelectedLanguage("ru", 1);
          break;
        case this.state.lang_data[2]:
          this.changeSelectedLanguage("en", 2);
          break;
        default:
          this.changeSelectedLanguage("az", 0);
          break;
      }
    }
  }
}
