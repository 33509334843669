declare const Pusher: any;
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PusherService {
  pusher: any;
  channel: any;
  output: any;
  public user = JSON.parse(localStorage.getItem("front_user"));
  constructor(private http: HttpClient) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true,
    });
    // this.channel = this.pusher.subscribe("events-channel");
    this.channel = this.pusher.subscribe(
      `actionNotification-base_user-${this.user["id"]}`
    );
    this.channel.bind(`event-base_user-${this.user["id"]}`, (res) => {
      let data = res;
      this.output = data;
    });
  }

  public get() {
    return this.output;
  }
}
